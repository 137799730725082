import React from 'react'
import styles from './Settings.module.css'
import {Button } from '@material-ui/core'
import {useSetting} from '../../Context/SettingContext'
import Form from './Form/Form'

import { useHistory } from 'react-router-dom'
import Vat from "./Vat"
const Settings = () => {

    const history = useHistory()
    const user = JSON.parse(localStorage.getItem('profile'))
    const {handleSettingDialogOpen}=useSetting();
    //(user)

    if(!user) {
        history.push('/login')
      }
    

    return (
        <div className={styles.pageContainer}>
           
      
        <section className={styles.stat}>
        
            <Form user={user} />
        </section>
        <section className={styles.stat}>
           
        <Vat/>
        </section>
    </div>
    )
}

export default Settings
