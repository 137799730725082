import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSetting } from "../../Context/SettingContext";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "react-simple-snackbar";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  DialogActions,
  TextField,
  Card,
  CardContent,
  CardActions,
  CardHeader
} from "@material-ui/core";

import {
  getProfilesByUser,
  updateProfile,
  getProfile,
} from "../../actions/profile";
import { updateItems, getAllItems } from "../../actions/itemActions";

const useStyles = makeStyles((theme) => ({
  vatContainer: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    border: "1px solid #ccc",
    borderRadius: theme.shape.borderRadius,
  },
  dialogTitle: {
    backgroundColor: "#ef7f1a", // Replace 'primary.main' with your desired blue color
    color: "#fff", // White text color
  },
  card: {
    width: "300px",
    padding: "16px",
    fontWeight: "normal",
    fontSize: "16px",
    margin: "0.5rem",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  button: {
    marginTop: "16px",
  },
  section: {
    marginBottom: '8px',
  },
  sectionTitle: {
    fontWeight: 'bold',
    marginRight: '8px',
  },
  sectionValue: {
    color: '#333', // Adjust the color as needed
  }
}));

const Vat = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.items);

  const { profiles } = useSelector((state) => state.profiles);
  const user = JSON.parse(localStorage.getItem("profile"));
  const {
    openSettingDialog,
    setOpenSettingDialog,
    vatOption,
    setVatOption,
    vatType,
    setVatType,
    handleSettingDialogOpen,
    handleSettingDialogClose,
    handleVatChange,
    handleVatTypeChange,
    handleCommonVatRateChange,
    commonVatRate,
  } = useSetting();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  useEffect(() => {
    // dispatch(getProfilesByUser({ search: user?.result?._id }))
    dispatch(getProfile(user?.userProfile?._id));
  }, [dispatch]);
  useEffect(() => {
    dispatch(getAllItems());
  }, [dispatch]);

  localStorage.setItem(
    "profileDetail",
    JSON.stringify({
      ...profiles,
      vatType: vatType,
      vatOptions: vatOption,
      commonVatRate: commonVatRate,
    })
  );
  const updateItemsCommonVatRate = () => {
    const updatedCartItems = items.map((item) => {
      const vat = commonVatRate;

      if (!isNaN(vat)) {
        const updatedItem = {
          ...item,
          vat: vat,
        };
        return updatedItem;
      }
    });

    dispatch(updateItems(updatedCartItems, openSnackbar));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateItemsCommonVatRate();
    dispatch(
      updateProfile(
        profiles?._id,
        {
          ...profiles,
          vatType: vatType,
          vatOptions: vatOption,
          commonVatRate: commonVatRate,
        },
        openSnackbar
      )
    );

    setTimeout(() => {
      handleSettingDialogClose();

      window.location.reload();
    }, 500);
  };

  return (
    <div > 
      <Card className={classes.card} elevation={3}>
      <CardHeader
        title={<Typography variant="h6">Vat Settings</Typography>}
      />
        <CardContent className={classes.cardContent}>
   
          <div className={classes.section}>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Vat Option:
          </Typography>
          <Typography variant="body1" className={classes.sectionValue}>
          {vatOption}
          </Typography>
        </div>
        <div className={classes.section}>
          <Typography variant="subtitle1" className={classes.sectionTitle}>
            Vat Type:
          </Typography>
          <Typography variant="body1" className={classes.sectionValue}>
           {vatType}
          </Typography>
        </div>
        </CardContent>

        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSettingDialogOpen}
            className={classes.button}
          >
            Edit
          </Button>
        </CardActions>
      </Card>

      <Dialog open={openSettingDialog} onClose={handleSettingDialogClose}>
        <DialogTitle className={classes.dialogTitle}>
          <Typography variant="h6">Vat</Typography>
        </DialogTitle>
        <DialogContent>
          <div className={classes.vatContainer}>
            <Typography variant="subtitle1">VAT Options</Typography>
            <RadioGroup
              aria-label="vat-options"
              name="vat-options"
              value={vatOption}
              onChange={handleVatChange}
            >
              <FormControlLabel
                value="itemBased"
                control={<Radio style={{ color: "#ef7f1a" }} />}
                label="Item Based"
              />
              <FormControlLabel
                value="common"
                control={<Radio style={{ color: "#ef7f1a" }} />}
                label="Common "
              />
              {/* Add more radio buttons as needed */}
            </RadioGroup>
            {vatOption === "common" && ( // Conditionally render TextField for 'Common' VAT type
              <div className={classes.vatContainer}>
                <TextField
                  type="number"
                  label="VAT Rate"
                  value={commonVatRate} // Set value based on profiles or adjust as needed
                  onChange={handleCommonVatRateChange}
                />
              </div>
            )}
          </div>

          <div className={classes.vatContainer}>
            <Typography variant="subtitle1">VAT Type</Typography>
            <RadioGroup
              aria-label="vat-type-options"
              name="vat-type-options"
              value={vatType}
              onChange={handleVatTypeChange}
            >
              <FormControlLabel
                value="exclusive"
                control={<Radio style={{ color: "#ef7f1a" }} />}
                label="Exclusive"
              />
              <FormControlLabel
                value="inclusive"
                control={<Radio style={{ color: "#ef7f1a" }} />}
                label="Inclusive"
              />
              {/* Add more radio buttons as needed */}
            </RadioGroup>
          </div>

          {/* Add more settings components within DialogContent */}
        </DialogContent>
        <DialogActions style={{ display: "flex" }}>
          <Button
            type="submit"
            variant="contained"
            // color="primary"
            style={{ backgroundColor: "#ef7f1a", color: "white" }}
            onClick={handleSubmit}
          >
            Save
          </Button>
          {/* <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "#ef7f1a" }}
            onClick={handleCancel}
          >
            Cancel
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Vat;
