import React from "react";
import { createTheme, ThemeProvider } from "@material-ui/core";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import reducers from "./reducers/";
import { SettingProvider } from "./Context/SettingContext";
import { AppProvider } from "./Context/AppContext";
import { EditProvider } from "./Context/EditContext";
import { ItemProvider } from "./Context/ItemContext";
import { StatsProvider } from "./Context/StatsContext";
import { CurrencyProvider } from "./Context/CurrencyContext";
import { CartProvider } from "./Context/CartContext";
import { PayNowProvider } from "./Context/PayNowContext";
import { DiscountProvider } from "./Context/DiscountContext";
import { StockProvider } from "./Context/StockContext";
import { StaffCommissionDetailProvider } from "./Context/StaffCommissionDetailsContext";
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  palette: {
    primary: {
      main: "#ef7f1a",
    },
  },
});

const store = createStore(reducers, compose(applyMiddleware(thunk)));

const AppProviders = ({ children }) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppProvider>
          <SettingProvider>
            <EditProvider>
              <StockProvider>
              <ItemProvider>
                <StatsProvider>
                  <CurrencyProvider>
                    <DiscountProvider>
                      <CartProvider>
                        <PayNowProvider>
                          <StaffCommissionDetailProvider>
                          {children}
                          </StaffCommissionDetailProvider>
                          </PayNowProvider>
                      </CartProvider>
                    </DiscountProvider>
                  </CurrencyProvider>
                </StatsProvider>
              </ItemProvider>
              </StockProvider>
            </EditProvider>
          </SettingProvider>
        </AppProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default AppProviders;
