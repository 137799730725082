import * as api from "../api/index";

import {
  ADD_NEW_STAFFCOMMISSIONDETAIL,
  UPDATE_STAFFCOMMISSIONDETAIL,
  UPDATE_STAFFCOMMISSIONDETAILS,
  DELETE_STAFFCOMMISSIONDETAIL,
  GET_STAFFCOMMISSIONDETAIL,
  FETCH_STAFFCOMMISSIONDETAIL_BY_USER,
  FETCH_ALL_STAFFCOMMISSIONDETAIL,
  START_LOADING,
  END_LOADING,
} from "./constants";

export const getStaffCommissionDetails = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchStaffCommissionDetails();
    console.log(data);
    dispatch({ type: FETCH_ALL_STAFFCOMMISSIONDETAIL, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getStaffCommissionDetailsByUser = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data },
    } = await api.fetchStaffCommissionDetailsByUser(searchQuery);
    dispatch({ type: FETCH_STAFFCOMMISSIONDETAIL_BY_USER, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.response);
  }
};

export const getStaffCommissionDetail = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"));

  try {
    const { data } = await api.fetchStaffCommissionDetail(id);


    console.log("staffCommissionDetail Data",data)
    dispatch({ type: GET_STAFFCOMMISSIONDETAIL, payload: data });
  } catch (error) {
    console.log(error.response);
  }
};

export const createStaffCommissionDetail = (staffCommissionDetail, history) => async (dispatch) => {
  try {
     console.log("Create StaffCommissionDetail Called");
     console.log(staffCommissionDetail);
    // dispatch({ type: START_LOADING });
    const { data } = await api.addStaffCommissionDetail(staffCommissionDetail);
    console.log("Created StaffCommissionDetail Data", data);
    dispatch({ type: ADD_NEW_STAFFCOMMISSIONDETAIL, payload: data });
    // history.push(`/staffCommissionDetail/${data._id}`);
    // dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};
export const updateStaffCommissionDetails = (staffCommissionDetails) => async (dispatch) => {
  try {
    const { message }  = await api.updateStaffCommissionDetails(staffCommissionDetails);
    console.log(message);
    // dispatch({ type: UPDATE_STAFFCOMMISSIONDETAILS, payload: data });
  } catch (error) {
    console.log(error);
  }
};
export const updateStaffCommissionDetail = (id, staffCommissionDetail) => async (dispatch) => {
  try {
    const { data } = await api.updateStaffCommissionDetail(id, staffCommissionDetail);
    dispatch({ type: UPDATE_STAFFCOMMISSIONDETAIL, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const deleteStaffCommissionDetail = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deleteStaffCommissionDetail(id);

    dispatch({ type: DELETE_STAFFCOMMISSIONDETAIL, payload: id });
    openSnackbar("Detail deleted successfully");
  } catch (error) {
    console.log(error.response);
  }
};
