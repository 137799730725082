
const user = JSON.parse(localStorage.getItem('profile'))

export const initialStateStockAdjustment = {
    items: [],
    total: 0,
    adjustType:"Add",
    // notes: user?.userProfile?.paymentDetails,
    // rates: '',
    // vat: 0,
    // currency: '',
    stockAdjustmentNumber: Math.floor(Math.random() * 100000),
    // status: '',
   
    creator: '',
}
