
import { FETCH_ALL_STAFFCOMMISSIONDETAIL, ADD_NEW_STAFFCOMMISSIONDETAIL, UPDATE_STAFFCOMMISSIONDETAIL, DELETE_STAFFCOMMISSIONDETAIL, GET_STAFFCOMMISSIONDETAIL, START_LOADING, END_LOADING, FETCH_STAFFCOMMISSIONDETAIL_BY_USER } from '../actions/constants'

const staffCommissionDetails = (state = { isLoading: true, staffCommissionDetails: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case FETCH_ALL_STAFFCOMMISSIONDETAIL:
        return {
          ...state,
          staffCommissionDetails: action.payload,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_STAFFCOMMISSIONDETAIL_BY_USER:
        return { ...state, staffCommissionDetails: action.payload };

      case GET_STAFFCOMMISSIONDETAIL:
        return { ...state, staffCommissionDetails: action.payload };
      case ADD_NEW_STAFFCOMMISSIONDETAIL:
        return { ...state, staffCommissionDetails: [...state.staffCommissionDetails, ...action.payload] };
      case UPDATE_STAFFCOMMISSIONDETAIL:
        return { ...state, staffCommissionDetails: state.staffCommissionDetails.map((staffCommissionDetail) => (staffCommissionDetail._id === action.payload._id ? action.payload : staffCommissionDetail)) };
      case DELETE_STAFFCOMMISSIONDETAIL:
        return { ...state, staffCommissionDetails: state.staffCommissionDetails.filter((staffCommissionDetail) => staffCommissionDetail._id !== action.payload) };
      default:
        return state;
    }
  };

  export default staffCommissionDetails

