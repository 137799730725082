import * as api from '../api/index'

import { ADD_NEW_STAFF, ALL_STAFF,UPDATE_STAFF, DELETE_STAFF, FETCH_STAFF_BY_USER, FETCH_STAFF, START_LOADING, END_LOADING } from './constants'


export const getStaff = (id) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING });
      const { data } = await api.fetchStaff(id);
      dispatch({ type: FETCH_STAFF, payload: { staff: data } });

    } catch (error) {
      console.log(error);
    }
  };

  export const getAllStaff = () => async (dispatch) => {
    try {
  
      dispatch({ type: START_LOADING })
      const { data } = await api.fetchAllStaff();
    
      //  console.log(data.email)
      dispatch({ type: ALL_STAFF, payload: data });
      dispatch({ type: END_LOADING })
  
    } catch (error) {
      console.log(error);
    } };
export const getStaffByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
    const  { data: { data } } = await api.fetchStaffByUser(searchQuery)
      
      dispatch({ type: FETCH_STAFF_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response)
      
    }
  }


export const createStaff =(staff, openSnackbar) => async (dispatch) => {

    try {
        const { data } = await api.addStaff(staff)
        dispatch({ type: ADD_NEW_STAFF, payload: data })
        openSnackbar("Staff added successfully")

    } catch (error) {
        console.log(error)
    }
}


export const updateStaff =(id, staff, openSnackbar) => async (dispatch) => {
  try {
    const { data } = await api.updateStaff(id, staff)
    console.log("Action msg",data)
    dispatch({ type: UPDATE_STAFF, payload: data })
    openSnackbar("Staff updated successfully")
   
        
    } catch (error) {
        console.log(error)
    }
}

export const deleteStaff =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteStaff(id)

        dispatch({type: DELETE_STAFF, payload: id})
        openSnackbar("Staff deleted successfully")
    } catch (error) {
        console.log(error)
    }
}