import React, { createContext, useContext, useState,useEffect } from "react";
import { getProfilesByUser, updateProfile ,getProfile} from '../actions/profile';
import { useDispatch,useSelector } from 'react-redux';
const SettingContext = createContext();

export const useSetting = () => {
  return useContext(SettingContext);
};

export const SettingProvider = ({ children }) => {
    // const dispatch=useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'))
    const { profiles}  = useSelector((state) => state.profiles)
  const [openSettingDialog, setOpenSettingDialog] = useState(false);
  const [vatOption, setVatOption] = useState('');
  const [vatType, setVatType] = useState('');
  const [commonVatRate, setCommonVatRate] = useState(0)


  useEffect(() => {
    // Update vatOption and vatType when profiles change
    if (profiles) {
      setVatOption(profiles.vatOptions || ''); // Set the initial value for vatOption
      setVatType(profiles.vatType || ''); // Set the initial value for vatType
      setCommonVatRate(profiles.commonVatRate||0); // Set the initial value for commonVatRate
    }
  }, [profiles]);
  const handleSettingDialogOpen = () => {
    setOpenSettingDialog(true);
  };

  const handleSettingDialogClose = () => {
    setOpenSettingDialog(false);
  };

  const handleVatChange = (event) => {
   
    setVatOption(event.target.value);

  };
  const handleVatTypeChange = (event) => {
    
    setVatType(event.target.value);
  };
  const handleCommonVatRateChange= (event) => {
    setCommonVatRate(event.target.value);
  };
  return (
    <SettingContext.Provider
      value={{
        openSettingDialog,
        setOpenSettingDialog,
        vatOption,
        setVatOption,
        vatType,
        setVatType,
        handleSettingDialogOpen,
        handleSettingDialogClose,
        handleVatChange,
        handleVatTypeChange,
        handleCommonVatRateChange,
        commonVatRate,
        setCommonVatRate
      }}
    >
      {children}
    </SettingContext.Provider>
  );
};
