import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { ItemContext } from "../../Context/ItemContext";
import { useLocation, useHistory } from "react-router-dom";
import { Fab, Action } from "react-tiny-fab";
import "react-tiny-fab/dist/styles.css";
import AddIcon from "@material-ui/icons/Add";
import CreateIcon from "@material-ui/icons/Create";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AddClient from "../Invoice/AddClient";
import AddSupplier from "../Suppliers/AddSupplier";
import MeetingForm from "../Meetings/MeetingForm";
import Meetings from "../Meetings/Meetings";
import { AppContext } from "../../Context/AppContext";
import { EditContext } from "../../Context/EditContext";
import { useContext } from "react";
import AddItem from "../Items/addItem";
import MenuIcon from '@material-ui/icons/Menu';

import {
  Check,
  Pie,
  Bag,
  Card,
  Clock,
  Frown,
  Order,
  Rupee,
  Report,
  SalesPerson,
  Item,
  Customer,
} from "../Dashboard/Icons";
const FabButton = () => {
  const location = useLocation();
  const mainButtonStyles = {
    backgroundColor: "#ef7f1a",
   
  };
  const [open, setOpen] = useState(false);
  const [openSupplier, setOpenSupplier] = useState(false);
  const [openItem, setOpenItem] = useState(false);
  const { showForm, setShowForm ,stockAdjustmentShowForm, setStockAdjustmentShowForm} = useContext(AppContext);
  const { itemId, setItemId } = useContext(ItemContext);
  useEffect(() => {
    setStockAdjustmentShowForm(false);
    setOpen(false);
    setOpenItem(false);
  }, [location]);
  const user = JSON.parse(localStorage.getItem("profile"));
  const history = useHistory();
  // if(location.pathname === '/invoice') return null
  const handleAddMeeting = () => {
    //("handleAddMeeting CAlled");
    setStockAdjustmentShowForm(true);
  };
  const handleCloseForm = () => {
    setStockAdjustmentShowForm(false);
  };
  return (
    <div>
      <Dialog open={stockAdjustmentShowForm} onClose={handleCloseForm}>
        <DialogContent>
          <MeetingForm onClose={handleCloseForm} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <AddClient setOpen={setOpen} open={open} />
      <AddSupplier setOpen={setOpenSupplier} open={openSupplier} />
      <AddItem setOpen={setOpenItem} open={openItem} />
      {!stockAdjustmentShowForm &&
      
        !open &&
        !openItem &&
        !openSupplier && (
          <Fab
            mainButtonStyles={mainButtonStyles}
            icon={<MenuIcon style={{ transform: 'rotate(0deg)' }} />}
            alwaysShowTitle={true}
            style={{position:'fixed',bottom:"48px",left:"24px"}}
            event="click"
          >
            <Action
            text="Items"
           
             onClick={() =>  history.push(`/ItemsList`)}
            // onClick={handleAddMeeting}
            style={{ backgroundColor:"#ef7f1a"}}
          >
            <Report />
          </Action> 
            <Action
              text="Stock Summary"
              onClick={() => (window.location.href = "/stockDetails")}
              style={{ backgroundColor: "#ef7f1a" }}
            >
             <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 48 48"
                width="22px"
                height="22px"
                stroke="black"
              >
                <path d="M 1 3 L 1 15 L 3 15 L 3 48 L 47 48 L 47 15 L 49 15 L 49 3 Z M 3 5 L 47 5 L 47 13 L 3 13 Z M 5 15 L 45 15 L 45 46 L 5 46 Z M 17.5 19 C 15.578125 19 14 20.578125 14 22.5 C 14 24.421875 15.578125 26 17.5 26 L 32.5 26 C 34.421875 26 36 24.421875 36 22.5 C 36 20.578125 34.421875 19 32.5 19 Z M 17.5 21 L 32.5 21 C 33.339844 21 34 21.660156 34 22.5 C 34 23.339844 33.339844 24 32.5 24 L 17.5 24 C 16.660156 24 16 23.339844 16 22.5 C 16 21.660156 16.660156 21 17.5 21 Z" />
              </svg>
            </Action>
            {user?.result?.userType === "admin" && (
              <Action
                text="Stock Adjustments"

                //  onClick={handleAddMeeting}
                onClick={() =>  history.push(`/stockAdjustments`)}
                style={{ backgroundColor: "#ef7f1a" }}
              >
                <Item />
              </Action>
            )}
           
            <Action
            text="Invoices"
            onClick={() => (window.location.href = "/invoices")}
            style={{ backgroundColor:"#ef7f1a"}}
          >
            <Report />
            {/* <Customer /> */}
          </Action>
          <Action
            text="Purchases"
            onClick={() => (window.location.href = "/purchases")}
            style={{ backgroundColor:"#ef7f1a"}}
          >
            <Report />
            {/* <Customer /> */}
          </Action>
          
          {location.pathname !== "/invoice" && (
              <Action
                text="Add Purchase"
                // onClick={() =>  history.push(`/invoice`)}
                onClick={() => (window.location.href = "/purchase")}
                style={{ backgroundColor: "#ef7f1a" }}
              >
                <Order />
              </Action>
            )}

           
          </Fab>
        )}
    </div>
  );
};

export default FabButton;
