
export const FETCH_ALL = "FETCH_ALL"
export const ADD_NEW = "ADD_NEW"
export const UPDATE = "UPDATE"
export const DELETE = "DELETE"
export const GET_INVOICE = "GET_INVOICE"
export const FETCH_INVOICE_BY_USER = "FETCH_INVOICE_BY_USER"

export const FETCH_ALL_STAFFCOMMISSIONDETAIL = "FETCH_ALL_STAFFCOMMISSIONDETAIL"
export const ADD_NEW_STAFFCOMMISSIONDETAIL = "ADD_NEW_STAFFCOMMISSIONDETAIL"
export const UPDATE_STAFFCOMMISSIONDETAIL = "UPDATE_STAFFCOMMISSIONDETAIL"
export const UPDATE_STAFFCOMMISSIONDETAILS = "UPDATE_STAFFCOMMISSIONDETAILS"
export const DELETE_STAFFCOMMISSIONDETAIL = "DELETE_STAFFCOMMISSIONDETAIL"
export const GET_STAFFCOMMISSIONDETAIL = "GET_STAFFCOMMISSIONDETAIL"
export const FETCH_STAFFCOMMISSIONDETAIL_BY_USER = "FETCH_STAFFCOMMISSIONDETAIL_BY_USER"

export const GET_PURCHASE = "GET_PURCHASE"
export const FETCH_PURCHASE_BY_USER = "FETCH_PURCHASE_BY_USER"



export const FETCH_ALL_MEETINGS = "FETCH_ALL_MEETINGS"
export const ADD_NEW_MEETING = "ADD_NEW_MEETING"
export const UPDATE_MEETING = "UPDATE_MEETING"
export const DELETE_MEETING = "DELETE_MEETING"
export const GET_MEETING = "GET_MEETING"
export const FETCH_MEETING_BY_USER = "FETCH_MEETING_BY_USER"



export const ALL_CLIENTS = "ALL_CLIENTS"
export const UPDATE_CLIENT = "UPDATE_CLIENT"
export const DELETE_CLIENT = "DELETE_CLIENT"
export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT"
export const FETCH_CLIENTS_BY_USER = 'FETCH_PROFILE_USER';


export const ALL_SUPPLIERS = "ALL_SUPPLIERS"
export const UPDATE_SUPPLIER = "UPDATE_SUPPLIER"
export const DELETE_SUPPLIER = "DELETE_SUPPLIER"
export const ADD_NEW_SUPPLIER = "ADD_NEW_SUPPLIER"
export const FETCH_SUPPLIER_BY_USER = 'FETCH_SUPPLIER_USER';
export const FETCH_SUPPLIERS_BY_USER = 'FETCH_SUPPLIERS_USER';
export const FETCH_SUPPLIER = "FETCH_SUPPLIER"

export const AUTH = "AUTH"
export const LOGOUT = "LOGOUT"
export const UPDATE_USER = "UPDATE_USER"
export const FETCH_USERS = "FETCH_USERS"
export const DELETE_USER='DELETE_USER';
export const START_LOADING = "START_LOADING"
export const END_LOADING = "END_LOADING"
export const FETCH_CLIENT = "FETCH_CLIENT"

export const CREATE_PROFILE = 'CREATE_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const FETCH_PROFILES = 'FETCH_PROFILES';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_BY_USER = 'FETCH_PROFILE_BY_USER';
export const FETCH_PROFILES_USER = 'FETCH_PROFILES_USER';



export const ADD_NEW_ITEM= 'ADD_NEW_ITEM';
export const ADD_NEW_ITEMS= 'ADD_NEW_ITEMS';
export const UPDATE_ITEM='UPDATE_ITEM';
export const UPDATE_ITEMS='UPDATE_ITEMS';
export const DELETE_ITEM='DELETE_ITEM';
export const DELETE_ITEMS_BY_CATEGORY='DELETE_ITEMS_BY_CATEGORY';
export const FETCH_ITEMS='FETCH_ITEMS';
export const FETCH_ITEMS_USER='FETCH_ITEMS_USER';


export const ADD_NEW_CATEGORY= 'ADD_NEW_CATEGORY';
export const UPDATE_CATEGORY='UPDATE_CATEGORY';
export const DELETE_CATEGORY='DELETE_CATEGORY';
export const FETCH_CATEGORIES='FETCH_CATEGORIES';
export const FETCH_CATEGORY='FETCH_CATEGORY';

export const ADD_NEW_STOCK= 'ADD_NEW_STOCK';
export const ADD_NEW_STOCKS= 'ADD_NEW_STOCKS';
export const UPDATE_STOCK='UPDATE_STOCK';
export const DELETE_STOCK='DELETE_STOCK';
export const DELETE_STOCKS='DELETE_STOCKS';
export const FETCH_STOCKS='FETCH_STOCKS';
export const FETCH_STOCK='FETCH_STOCK';

export const FETCH_ALL_STOCKADJUSTMENTS = "FETCH_ALL_STOCKADJUSTMENTS"
export const ADD_NEW_STOCKADJUSTMENT = "ADD_NEW_STOCKADJUSTMENT"
export const UPDATE_STOCKADJUSTMENT = "UPDATE_STOCKADJUSTMENT"
export const DELETE_STOCKADJUSTMENT = "DELETE_STOCKADJUSTMENT"
export const GET_STOCKADJUSTMENT = "GET_STOCKADJUSTMENT"
export const FETCH_STOCKADJUSTMENT_BY_USER = "FETCH_STOCKADJUSTMENT_BY_USER"


export const ALL_STAFF = "ALL_STAFF"
export const UPDATE_STAFF = "UPDATE_STAFF"
export const DELETE_STAFF = "DELETE_STAFF"
export const ADD_NEW_STAFF = "ADD_NEW_STAFF"
export const FETCH_STAFF_BY_USER = 'FETCH_STAFF_USER';
export const FETCH_STAFF = "FETCH_STAFF"