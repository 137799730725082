import React, { createContext, useState } from "react";

// Create the StockContext
const StockContext = createContext();

// Create a StockProvider component
const StockProvider = ({ children }) => {
  // Define the state for the stock array
  const [trackStock, setTrackStock] = useState(false);
  const [stock, setStock] = useState([]);

  // You can define additional functions to update the stock array if needed
  // Provide the state and any additional functions through the context
  const contextValue = {
    stock,
    trackStock, setTrackStock
    // Add any additional functions here
  };

  return (
    <StockContext.Provider value={contextValue}>
      {children}
    </StockContext.Provider>
  );
};

export { StockContext, StockProvider };
