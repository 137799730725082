import React, { useState, useEffect } from "react";
// import "../../../node_modules/react-progress-button/react-progress-button.css"
import InvoiceTemplate from "../Home/InvoiceTemp";
import { useSnackbar } from "react-simple-snackbar";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initialState } from "../../initialState";
import { getInvoice, updateInvoice } from "../../actions/invoiceActions";
import { getAllItems } from "../../actions/itemActions";
import { toCommas } from "../../utils/utils";
import styles from "./InvoiceDetails.module.css";
import currencies from "../../currencies.json";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import Spinner from "../Spinner/Spinner";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import ProgressButton from "react-progress-button";
import axios from "axios";
import { saveAs } from "file-saver";
import Modal from "../Payments/Modal";
import PrintIcon from "@material-ui/icons/Print";
import {
  useMediaQuery,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IconButton } from "@material-ui/core";
import InvoicePreview from "./invoicePreview";
const InvoiceDetails = () => {
  const location = useLocation();
  const [state, setState] = useState(0);
  const [invoiceData, setInvoiceData] = useState(initialState);
  const [rates, setRates] = useState(0);
  const [vat, setVat] = useState(0);
  const [currency, setCurrency] = useState(currencies[1].value);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [client, setClient] = useState([]);
  // const [supplier, setSupplier] = useState([]);
  const [signatureDataUrl, setSignatureDataUrl] = useState(null);
  const [type, setType] = React.useState("");
  const [status, setStatus] = useState("");
  const [company, setCompany] = useState({});
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { id } = useParams();
  // console.log("id", id);
  const { invoice } = useSelector((state) => state.invoices);
  // console.log(invoice);
  const items = useSelector((state) => state.items.items);
  const [isBillPreviewOpen, setIsBillPreviewOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [sendStatus, setSendStatus] = useState(null);
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [printStatus, setPrintStatus] = useState(null);
  // console.log("IsPrintBillFlag",invoice.isPrintBill)
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const user = JSON.parse(localStorage.getItem("profile"));
  const [printInvoice, setPrintInvoice] = useState(false);
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    large: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    table: {
      minWidth: 650,
    },

    headerContainer: {
      // display: 'flex'
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(1),
      backgroundColor: "#f2f2f2",
      borderRadius: "10px 10px 0px 0px",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    dispatch(getInvoice(id));
  }, [id, dispatch, location]);

  useEffect(() => {
    if (invoice) {
      //Automatically set the default invoice values as the ones in the invoice to be updated
      setInvoiceData(invoice);
      setRates(invoice.rates);
      setClient(invoice.client);
      // setSupplier(invoice.supplier);
      setType(invoice.type);
      setStatus(invoice.status);
      setSelectedDate(invoice.dueDate);
      setVat(invoice.vat);
      setCurrency(invoice.currency);
      setSubTotal(invoice.subTotal);
      setTotal(invoice.total);
      setCompany(invoice?.businessDetails?.data);
      setSignatureDataUrl(invoice.signatureDataUrl);
    }
  }, [invoice]);
  useEffect(() => {
    dispatch(
      // getItemsByUser({ search: user?.result._id || user?.result?.googleId })
      getAllItems()
    );
    // eslint-disable-next-line
  }, [dispatch]);

  //Get the total amount paid
  let totalAmountReceived = 0;
  for (var i = 0; i < invoice?.paymentRecords?.length; i++) {
    totalAmountReceived += Number(invoice?.paymentRecords[i]?.amountPaid);
  }

  const editInvoice = (id) => {
    history.push(`/edit/invoice/${id}`);
  };

  const createAndPrintPdf = () => {
    setPrintStatus("loading");
    axios
      .post(
        `${process.env.REACT_APP_API}/create-pdf/${invoice.invoiceNumber}`,
        {
          name: invoice.client.name,
          address: invoice.client.address,
          phone: invoice.client.phone,
          email: invoice.client.email,
          dueDate: invoice.client.dueDate,
          // supplierName: invoice.supplier.name,
          // supplierAddress: invoice.supplier.address,
          // supplierPhone: invoice.supplier.phone,
          // supplierEmail: invoice.supplier.email,
          signatureDataUrl: invoice.signatureDataUrl,
          date: invoice.createdAt,
          id: invoice.invoiceNumber,
          notes: invoice.notes,
          subTotal: toCommas(invoice.subTotal),
          total: toCommas(invoice.total),
          type: invoice.type,
          vat: invoice.vat,
          items: invoice.items,
          status: invoice.status,
          totalAmountReceived: toCommas(totalAmountReceived),
          balanceDue: toCommas(total - totalAmountReceived),
          company: company,
        }
      )
      .then(() =>
        axios.get(
          `${process.env.REACT_APP_API}/fetch-pdf/${invoice.invoiceNumber}`,
          {
            responseType: "blob",
          }
        )
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        const downloadUrl = window.URL.createObjectURL(pdfBlob);
        setPrintStatus("success");

        // Open a new browser window for printing the PDF
        const printWindow = window.open(downloadUrl, "_blank");

        // Wait for the PDF to load, then trigger printing
        printWindow.onload = () => {
          printWindow.print();
          // You can close the print window automatically if needed
          // printWindow.close();
        };
      });
  };
  const createAndDownloadPdf = () => {
    setDownloadStatus("loading");
    axios
      .post(
        `${process.env.REACT_APP_API}/create-pdf/${invoice.invoiceNumber}`,
        {
          name: invoice.client.name,
          address: invoice.client.address,
          phone: invoice.client.phone,
          email: invoice.client.email,
          dueDate: invoice.client.dueDate,
          // supplierName: invoice.supplier.name,
          // supplierAddress: invoice.supplier.address,
          // supplierPhone: invoice.supplier.phone,
          // supplierEmail: invoice.supplier.email,
          signatureDataUrl: invoice.signatureDataUrl,
          date: invoice.createdAt,
          id: invoice.invoiceNumber,
          notes: invoice.notes,
          subTotal: toCommas(invoice.subTotal),
          total: toCommas(invoice.total),
          receivedAmount: toCommas(invoice.receivedAmount),
          change: toCommas(invoice.change),
          paymentMode:invoice.paymentMode,
          type: invoice.type,
          vat: invoice.vat,
          discount:invoice.discount,
          items: invoice.items,
          status: invoice.status,
          totalAmountReceived: toCommas(totalAmountReceived),
          balanceDue: toCommas(total - totalAmountReceived),
          company: company,
        }
      )
      .then(() =>
        axios.get(
          `${process.env.REACT_APP_API}/fetch-pdf/${invoice.invoiceNumber}`,
          {
            responseType: "blob",
          }
        )
      )
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        // const downloadUrl = window.URL.createObjectURL(pdfBlob);
        // setDownloadStatus("success");
        // window.open(downloadUrl, "_blank");
        saveAs(pdfBlob, `order${invoice.invoiceNumber}.pdf`);
        const downloadUrl = `https://posstore.mindtree.co.th/files/Exported-order/order${invoice.invoiceNumber}.pdf`;
        window.open(downloadUrl, "_blank");
        setDownloadStatus("success");
      });
  };

  //SEND PDF INVOICE VIA EMAIL
  const sendPdf = (e) => {
    e.preventDefault();
    setSendStatus("loading");
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_API}/send-pdf-whatsapp`, {
        name: invoice.client.name,
        address: invoice.client.address,
        phone: invoice.client.phone,
        email: invoice.client.email,
        dueDate: invoice.client.dueDate,
        // supplierName: invoice.supplier.name,
        // supplierAddress: invoice.supplier.address,
        // supplierPhone: invoice.supplier.phone,
        // supplierEmail: invoice.supplier.email,
        signatureDataUrl: invoice.signatureDataUrl,
        date: invoice.createdAt,
        id: invoice.invoiceNumber,
        notes: invoice.notes,
        subTotal: toCommas(invoice.subTotal),
        total: toCommas(invoice.total),
        receivedAmount: toCommas(invoice.receivedAmount),
        change: toCommas(invoice.change),
        paymentMode:invoice.paymentMode,
        type: invoice.type,
        vat: invoice.vat,
        discount:invoice.discount,
        items: invoice.items,
        status: invoice.status,
        totalAmountReceived: toCommas(totalAmountReceived),
        balanceDue: toCommas(total - totalAmountReceived),
        company: company,
      })
      .then((res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" });
        // console.log(resres.data)
        const downloadUrl = window.URL.createObjectURL(pdfBlob);
        setSendStatus("success");
        const encodedFilePath = encodeURIComponent(downloadUrl);
        console.log("Client Phone Number", invoice.client.phone);
        const whatsappURL = `https://wa.me/${invoice.client.phone}?text=https://posstore.mindtree.co.th/files/Exported-order/order${invoice.invoiceNumber}.pdf`;

        window.open(whatsappURL, "_blank");

        // window.open(res.data.whatsappURL, "_blank");
      })
      // .then(() => console.log("invoice sent successfully"))
      .then(() => {
        setSendStatus("success");
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSendStatus("error");
      });
  };

  const iconSize = {
    height: "18px",
    width: "18px",
    marginRight: "10px",
    color: "gray",
  };
  const [open, setOpen] = useState(false);

  function checkStatus() {
    return totalAmountReceived >= total
      ? "green"
      : status === "Partial"
      ? "#1976d2"
      : status === "Paid"
      ? "green"
      : status === "Unpaid"
      ? "red"
      : "red";
  }

  if (!invoice) {
    return <Spinner />;
  }

  const colorTheme = createTheme({
    palette: {
      primary: {
        main: "#ef7f1a",
      },
    },
  });

  const handleInvoiceReprint = () => {
    console.log("Bill Preview Click");
     setPrintInvoice(true);

    setTimeout(() => {
       setPrintInvoice(false);
    }, 3500);
    //console.log("Print Invoice State",printInvoice);
  };

  return (
    <div className={styles.PageLayout}>
      <div
        className={styles.buttons}
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
          gap: "10px",
        }}
      >
        <div style={{ width: isSmallScreen ? "90%" : "auto" }}>
          {user?.result?.userType === "admin" && (
            <button
              className={styles.btn}
              onClick={() => editInvoice(invoiceData._id)}
            >
              <BorderColorIcon style={iconSize} />
              Edit Order
            </button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            marginRight: "10px",
          }}
        >
          <ProgressButton
            onClick={createAndDownloadPdf}
            state={downloadStatus}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "Transparent",
              color: "#000000",
              border: "none",
              borderRadius: "24px",
              padding: "8px",
              height: "40px",
              width: isSmallScreen ? "100%" : "10rem",
              marginTop: "7px",
              cursor: "pointer",
            }}
          >
            Download
          </ProgressButton>
          {/* <ProgressButton
            onClick={handleInvoiceReprint}
            state={printStatus}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              backgroundColor: "Transparent",
              color: "#000000",
              border: "none",
              borderRadius: "24px",
              padding: "8px",
              height: "40px",
              width: isSmallScreen ? "100%" : "10rem",
              marginTop: "7px",
              cursor: "pointer",
            }}
          >
           Reprint
          </ProgressButton> */}
          <IconButton aria-label="Re-Print"onClick={handleInvoiceReprint}>
            <PrintIcon />
          </IconButton>
          <ThemeProvider theme={colorTheme}>
            {loading ? (
              <CircularProgress />
            ) : (
              <img
                className={styles.wp}
                src="https://www.sharethis.com/wp-content/uploads/2017/05/WhatsApp.png"
                alt=""
                style={{ height: "3rem", width: "3rem", cursor: "pointer" }}
                onClick={sendPdf}
              />
            )}
          </ThemeProvider>
        </div>
      </div>

      {/* {(invoice.isPrintBill || printInvoice ) && (
        <div style={{ position: "absolute", left: "-9999px", top: "-9999px" }}>
          <InvoiceTemplate invoice={invoice} />
        </div>
      )
      
      } */}
      { printInvoice && (
        <div style={{ position: "absolute", left: "-9999px", top: "-9999px" }}>
          <InvoiceTemplate invoice={invoice} />
        </div>
      )}
      <Modal open={open} setOpen={setOpen} invoice={invoice} />
      <div className={styles.invoiceLayout}>
        <div>
          <InvoicePreview invoice={invoice} />
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
