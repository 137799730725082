/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { useDispatch } from 'react-redux';
import { createStaff } from '../../actions/staffActions';
import { useLocation } from 'react-router-dom';

import { useSnackbar } from 'react-simple-snackbar';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#1976D2',
    marginLeft: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddStaff = ({ setOpen, open ,currentId, setCurrentId}) => {
  const location = useLocation();
  const [staffData, setStaffData] = useState({ name: '', email: '', phone: '+91', code: '', userId: [] });
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [errors, setErrors] = useState({});
  useEffect(() => {
    var checkId = user?.result?._id;
    if (checkId !== undefined) {
      setStaffData({ ...staffData, userId: [checkId] });
    } else {
      setStaffData({ ...staffData, userId: [user?.result?.googleId] });
    }
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    const maskedValue = value.startsWith('+91') ? value : '+91' + value.slice(3);
    if(name==='phone') {
    setStaffData((prevData) => ({
      ...prevData,
      [name]: maskedValue,
    }));}
    else{
      setStaffData((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error for the specific field
    }));
  };
  
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (staffData.name.trim() === '') {
      newErrors.name = 'Name is required';
      isValid = false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (staffData.email.trim() === ''||!emailRegex.test(staffData.email.trim())) {
      newErrors.email = 'Please enter a valid emailId.';
      isValid = false;
    }

    
    if (staffData.phone.trim() === '') {
      newErrors.phone = 'Phone is required';
      isValid = false;
    }else if(staffData.phone.length<10||staffData.phone.length>13){
      newErrors.phone = 'Please enter a valid phone number.';
      isValid = false;
    }
    if (staffData.code.trim() === '') {
      newErrors.code = 'Code is required';
      isValid = false;
    }
  
  
    setErrors(newErrors);
    return isValid;
  };
  const handleSubmitStaff = (e) => {
    e.preventDefault();
    if (validateForm()) { // Check if the form is valid before submitting
      
        
      
        dispatch(createStaff(staffData, openSnackbar));
      
      clear();
      handleClose();
    }
  };

  const clear = () => {
    setStaffData({ name: '', email: '', phone: '+91', code: '', userId: [] });
     setErrors({}); // Clear the error messages
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ paddingLeft: '20px',backgroundColor: "#ef7f1a",color: "white"
      }}>
          New Staff
        </DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Staff Name"
                  fullWidth
                  name="name"
                  value={staffData.name}
                  onChange={handleChange}
                  error={!!errors.name} // Check if error exists
                  helperText={errors.name} // Display error message
                />
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Email"
                  fullWidth
                  name="email"
                  value={staffData.email}
                  onChange={handleChange}
                  error={!!errors.email} // Check if error exists
                  helperText={errors.email} // Display error message
                  inputProps={{
                    pattern: "^[^\s@]+@[^\s@]+\.[^\s@]+$", // Email format regex pattern
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Phone"
                  fullWidth
                  name="phone"
                  value={staffData.phone}
                  onChange={handleChange}
                  error={!!errors.phone} // Check if error exists
                  helperText={errors.phone} // Display error message
                 
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Code"
                  fullWidth
                  name="code"
                  value={staffData.code}
                  onChange={handleChange}
                  error={!!errors.code} // Check if error exists
                  helperText={errors.code} // Display error message
                />
              </Grid>
              
            </Grid>
          </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSubmitStaff} variant="contained" style={{ marginRight: '25px' ,backgroundColor: "#ef7f1a",color: "white"}}>
            Save 
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddStaff;
