import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import { useDispatch } from "react-redux";
import styles from "./Staff.module.css";
import moment from "moment";
import NoData from "../../components/svgIcons/NoData";
import PropTypes from "prop-types";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Paper,
  AppBar,
  Tabs,
  Tab,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  IconButton,
  Container,
  Button,
  Typography,
  useMediaQuery,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { format } from "../../intl";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import GetAppIcon from "@material-ui/icons/GetApp";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { useSnackbar } from "react-simple-snackbar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { deleteStaff } from "../../actions/staffActions";
import { StaffCommissionDetailContext } from "../../Context/StaffCommissionDetailsContext";
// import staff from '../../staff.json'

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },

  tablecell: {
    fontSize: "16px",
  },
}));

const StaffCommission = ({ staff, staffCommissionDetails }) => {
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const {
    startDate,
    endDate,
    handleStartDateChange,
    handleEndDateChange,
    generate,
    handleGenerate,
    staffCommissionSummary,
  } = useContext(StaffCommissionDetailContext);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [currentTab, setCurrentTab] = useState(0);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleStaffNameClick = (id) => {
  //   history.push(`/stats/${id}`);
  // };
  const handleEdit = (id) => {
    history.push(`/edit/staff/${id}`);
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this client?"
    );
    if (confirmDelete) {
      dispatch(deleteStaff(id, openSnackbar));
    }
  };
  useEffect(() => {
    const rows = staff;
    setRows(rows);
  }, [rows]);
  const tableStyle = {
    width: 160,
    fontSize: 14,
    cursor: "pointer",
    borderBottom: "none",
    padding: "8px",
    textAlign: "center",
  };
  const headerStyle = { borderBottom: "none", textAlign: "center" };
  // const filteredRows = rows?.filter((row) =>
  //   row.name.toLowerCase().includes(searchQuery.toLowerCase())
  // );
  const handleStaffNameClick = (id) => {
    history.push(`/staffstats/${id}`);
  };

  const createAndDownloadPdf = () => {
    //(rows);
    //(filteredRows);
    setLoading(true);
    if (staffCommissionSummary.length) {
      axios
        .post(`${process.env.REACT_APP_API}/create-commission-summary-report`, {
          staffCommissionSummary,
          startDate: moment(startDate).format(" Do MMM YYYY"),
          endDate: moment(endDate).format(" Do MMM YYYY"),
        })
        .then(() =>
          axios.get(
            `${process.env.REACT_APP_API}/fetch-commission-summary-report`,
            {
              responseType: "blob",
            }
          )
        )
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          // const downloadUrl = window.URL.createObjectURL(pdfBlob);
          // setDownloadStatus("success");
          // window.open(downloadUrl, "_blank");
          const currentDate = new Date();

          saveAs(pdfBlob, `CommissionSummaryReport${currentDate}.pdf`);
          const downloadUrl = `https://posstore.mindtree.co.th/files/Exported-Commission-Summary-Reports/commissionsummaryreport.pdf`;
          window.open(downloadUrl, "_blank");
          setLoading(false);
        });
    } else {
      openSnackbar("No record to Export");
    }
  };
  return (
    <div className={styles.pageLayout}>
      <Container style={{ width: isMobile ? "100%" : "75%" }}>
        <Paper elevation={3}>
          <Container>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="From"
                  name="FromDate"
                  value={startDate}
                  onChange={handleStartDateChange}
                  required
                  format="dd/MM/yyyy"
                  style={{ maxWidth: isMobile ? "50%" : "100%" }}
                />

                <KeyboardDatePicker
                  label="To"
                  name="EndDate"
                  value={endDate}
                  onChange={handleEndDateChange}
                  required
                  format="dd/MM/yyyy"
                  style={{ maxWidth: "50%" }}
                />
              </MuiPickersUtilsProvider>
              <Button
                variant="outlined"
                style={{
                  margin: "16px 0 8px 0",
                  height: "50px",
                }}
                onClick={() => handleGenerate(rows, staffCommissionDetails)}
              >
                Generate
              </Button>
              {loading ? (
                <CircularProgress
                  style={{ marginTop: "20px", marginLeft: "10px" }}
                />
              ) : (
                <Button
                  disabled={!endDate}
                  variant="outlined"
                  endIcon={<GetAppIcon />}
                  onClick={createAndDownloadPdf}
                  style={{
                    margin: "16px 0 8px 0",
                    height: "50px",
                  }}
                >
                  PDF
                </Button>
                // <IconButton
                //   onClick={createAndDownloadPdf}
                //   style={{
                //     margin: "16px 0 8px 0",
                //     height: "50px",
                //   }}
                // >
                //   <PictureAsPdfIcon />
                // </IconButton>
              )}
            </div>
          </Container>
        </Paper>
        {generate && (
          <div>
            <TableContainer component={Paper} elevation={0}>
              <Table
                className={classes.table}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ ...headerStyle, width: "10px" }}>
                      Sr#
                    </TableCell>
                    <TableCell style={headerStyle}>Staff Name</TableCell>
                    {/* <TableCell style={headerStyle}>No of services</TableCell> */}
                    <TableCell style={headerStyle}>Total Commission</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {(rowsPerPage > 0
                    ? staffCommissionSummary?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : rows
                  ).map((row, index) => (
                    <TableRow key={row._id} style={{ cursor: "context-menu" }}>
                      <TableCell
                        style={{
                          ...tableStyle,
                          width: "10px",
                          cursor: "context-menu",
                        }}
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell style={tableStyle} scope="row">
                        {" "}
                        <Button
                          style={{ textTransform: "none" }}
                          onClick={() => handleStaffNameClick(row._id)}
                        >
                          {" "}
                          {row.name}{" "}
                        </Button>
                      </TableCell>
                      <TableCell style={tableStyle} scope="row">
                        {" "}
                        <Button
                          style={{ textTransform: "none" }}
                          onClick={() => handleStaffNameClick(row._id)}
                        >
                          {" "}
                          {format(row.totalCommission)}{" "}
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={6}
                      count={staffCommissionSummary.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
        )}
      </Container>
    </div>
  );
};

export default StaffCommission;
