import React, { useState, useEffect, useRef } from "react";
import styles from "./Invoice.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { toCommas } from "../../utils/utils";
import { useSnackbar } from "react-simple-snackbar";
import AddSupplier from "../Suppliers/AddSupplier";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import InputBase from "@material-ui/core/InputBase";
import { Container, Grid } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import { initialState } from "../../initialState";
import currencies from "../../currencies.json";
import {
  createInvoice,
  getInvoice,
  updateInvoice,
} from "../../actions/invoiceActions";
import SignaturePad from "./SignaturePad";
import { getClientsByUser, getAllClients } from "../../actions/clientActions";
import { getAllSuppliers } from "../../actions/supplierActions";
// import {getItems } from '../../actions/itemActions'
import AddClient from "./AddClient";
import InvoiceType from "./InvoiceType";
import axios from "axios";
import { useLocation } from "react-router-dom";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { getItemsByUser, getAllItems } from "../../actions/itemActions";
import { endOfDay } from "date-fns";
// import AddItem from '../Items/addItem'

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
  },
  table: {
    minWidth: 650,
    padding: theme.spacing(3),
  },

  headerContainer: {
    // display: 'flex'
    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));
const options = {
  position: "bottom",
  style: {
    backgroundColor: "red",
    border: "2px solid red",
    color: "white",
    fontFamily: "Menlo, monospace",
    fontSize: "20px",
    textAlign: "center",
  },
  closeStyle: {
    color: "lightcoral",
    fontSize: "16px",
  },
};
const Invoice = () => {
  const location = useLocation();
  const [invoiceData, setInvoiceData] = useState(initialState);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [rates, setRates] = useState(0);
  const [vat, setVat] = useState(0);
  const [currency, setCurrency] = useState(currencies[1].value);
  const [subTotal, setSubTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState("");
  const [client, setClient] = useState(null);
  const [defaultClient, setDefaultClient] = useState(null);
  const [editQty, setEditQty] = useState(false);
  const inputRef = useRef(null);
  // const [signatureDataUrl, setSignatureDataUrl] = useState(null)
  //console.log(client);
  const [timer, setTimer] = useState(null);
  // (supplier);
  const [searchQuery, setSearchQuery] = useState("");
  const [openSnackbar, closeSnackbar] = useSnackbar(options);
  // const [item, setItem] = useState(null);
  const [type, setType] = useState("Invoice");
  const [status, setStatus] = useState("");
  const { id } = useParams();
  // const [scanning, setScanning] = useState(false);
  const clients = useSelector((state) => state.clients.clients);
  // const suppliers = useSelector((state) => state.suppliers.suppliers);

  // const [supplier, setSupplier] = useState(null);
  // console.log("Supplier", supplier);
  const items = useSelector((state) => state.items.items);
  //console.log(items)
  const { invoice } = useSelector((state) => state.invoices);
  const dispatch = useDispatch();
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("profile"));
  const today = new Date();

  const [selectedDate, setSelectedDate] = useState(
    today.getTime() + 7 * 24 * 60 * 60 * 1000
  );
  useEffect(() => {
    getTotalCount();
    // eslint-disable-next-line
  }, []);

  const getTotalCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/invoices/count?searchQuery=${user?.result?._id}`
      );
      const currentDate = new Date();
      // console.log("Date: ", currentDate);

      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const hours = String(currentDate.getHours()).padStart(2, "0");
      const minutes = String(currentDate.getMinutes()).padStart(2, "0");
      const seconds = String(currentDate.getSeconds()).padStart(2, "0");
      const randomID = Math.floor(100 + Math.random() * 900);
      const invoiceNumber = `${randomID}${seconds}`;
      // const invoiceNumber = `${year}${month}${day}${hours}${minutes}${seconds}`;

      // console.log("CurrentDate", currentDate);

      //   console.log(response.data);
      //Get total count of invoice from the server and increment by one to serialized numbering of invoice
      setInvoiceData({
        ...invoiceData,
        invoiceNumber: invoiceNumber,
      });
    } catch (error) {
      console.error(error);
    }
  };
  // Listen for changes in the form data
  useEffect(() => {
    // Whenever formData changes, set hasUnsavedChanges to true
    setHasUnsavedChanges(true);
  }, [invoiceData]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        // Display a confirmation message
        e.preventDefault();
        e.returnValue = " Are you sure you want to leave this page?";
      }
    };

    // Attach the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);
  useEffect(() => {
    dispatch(getInvoice(id));

    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    const defaultClient = clients.filter((clt) => clt.name === "Cash");
    console.log("Default Client", defaultClient);
    setDefaultClient(defaultClient[0]);
  }, [clients]);
  useEffect(() => {
    dispatch(
      // getClientsByUser({ search: user?.result._id || user?.result?.googleId })
      getAllClients()
    );
    // dispatch(
    //   // getClientsByUser({ search: user?.result._id || user?.result?.googleId })
    //   getAllSuppliers()
    // );

    dispatch(
      // getItemsByUser({ search: user?.result._id || user?.result?.googleId })
      getAllItems()
    );
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    if (!invoice) {
      setClient(null);
      // setSupplier(null);
    }
  }, [location]);
  useEffect(() => {
    if (invoice) {
      //Automatically set the default invoice values as the ones in the invoice to be updated
      setInvoiceData(invoice);
      setRates(invoice.rates);
      setClient(invoice.client);
      // setSupplier(invoice.supplier);
      setType(invoice.type);
      setStatus(invoice.status);
      setSelectedDate(invoice.dueDate);
      // setSignatureDataUrl(invoice.signatureDataUrl);
    }
  }, [invoice]);

  useEffect(() => {
    if (type === "Receipt") {
      setStatus("Paid");
    } else {
      setStatus("Unpaid");
    }
  }, [type]);

  const defaultProps = {
    options: currencies,
    getOptionLabel: (option) => option.label,
  };

  const clientsProps = {
    options: clients,
    getOptionLabel: (option) => option.name,
  };
  // const suppliersProps = {
  //   options: suppliers,
  //   getOptionLabel: (option) => option.name || "",
  // };
  const itemsProps = {
    options: items,
    getOptionLabel: (option) => option.itemName || "",
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleRates = (e) => {
    setRates(e.target.value);
    setInvoiceData((prevState) => ({ ...prevState, tax: e.target.value }));
  };

  // console.log(invoiceData)
  // Change handler for dynamically added input field
  const handleChange = (index, e) => {
    const values = [...invoiceData.items];
    values[index][e.target.name] = e.target.value;
    setInvoiceData({ ...invoiceData, items: values });
  };
  // const handleNameChange = (index, e, value) => {
  //   console.log(value)
  //   const values = [...invoiceData.items];
  //   values[index].itemName = value;
  //   values[index].unitPrice = getItemPrice(value);
  //   setInvoiceData({ ...invoiceData, items: values });

  // };
  // const getItemPrice = (itemName) => {
  //   // console.log('getItemPrice function in', itemName);
  //   // console.log("itemName: " + itemName)

  //   const selectedItem = items.find((item) => item.itemName === itemName.itemName);
  //   // console.log("selected item: " + selectedItem)
  //   return selectedItem ? selectedItem.unitPrice : 0; // Return 0 if item price is not found
  // };
  const handleNameChange = (index, value) => {
    const updatedItems = [...invoiceData.items];
    updatedItems[index] = {
      ...updatedItems[index],
      itemName: value.itemName,
      unitPrice: value.unitPrice,
      unit: value.unit,
    };
    setInvoiceData({ ...invoiceData, items: updatedItems });
    
    setEditQty(true);
  };
  let barcodeScan = "";
  useEffect(() => {
    function handleKeyDown(e) {
      // console.log("handle")
      if (e.keyCode === 13 && barcodeScan.length > 3) {
        console.log("Barcode Scan: " + barcodeScan);
        handleBarcodeScan(barcodeScan);
        return;
      }
      if (e.keyCode === 16) {
        return;
      }
      barcodeScan += e.key;
      setTimeout(() => {
        barcodeScan = "";
        console.log("Barcode Scan: " + barcodeScan);
      }, 100);
    }
    document.addEventListener("keydown", handleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });
  const handleBarcodeScan = (scannedValue) => {
    console.log("handleBarcodeScan Called");
    const scannedItem = scannedValue;
    // Find the item with a matching barcode
    const matchingItem = items.find(
      (item) => item.barcode === scannedItem || item._id === scannedItem
    );

    if (matchingItem) {
      // Check if the item already exists in the invoiceData
      const existingItemIndex = invoiceData.items.findIndex(
        (item) => item.itemName === matchingItem.itemName
      );

      if (existingItemIndex !== -1) {
        // If the item already exists, update the quantity by one
        const updatedItems = [...invoiceData.items];
        updatedItems[existingItemIndex].quantity =
          parseInt(updatedItems[existingItemIndex].quantity, 10) + 1;

        setInvoiceData({ ...invoiceData, items: updatedItems });
      } else {
        // If it's a new item, add it with a quantity of 1
        const updatedItems = [...invoiceData.items];
        updatedItems.push({
          itemName: matchingItem.itemName,
          unitPrice: matchingItem.unitPrice,
          unit: matchingItem.unit,
          quantity: 1,
        });

        setInvoiceData({ ...invoiceData, items: updatedItems });
      }
    } else {
      {
        searchQuery
          ? openSnackbar(
              "Item not found for the entered barcode. Please check."
            )
          : openSnackbar(
              "Item not found for the scanned barcode. Please check."
            );
      }
    }
  };

  const handleBarcodeInputChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    handleBarcodeScan(searchQuery);
    // setSearchQuery("");
  };

  useEffect(() => {
    //Get the subtotal
    const subTotal = () => {
      var arr = document.getElementsByName("amount");
      var subtotal = 0;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].value) {
          subtotal += +arr[i].value;
        }
        // document.getElementById("subtotal").value = subtotal;
        setSubTotal(subtotal);
      }
    };

    subTotal();
  }, [invoiceData]);

  useEffect(() => {
    const total = () => {
      //Tax rate is calculated as (input / 100 ) * subtotal + subtotal
      const overallSum = subTotal - (rates / 100) * subTotal;
      //VAT is calculated as tax rates /100 * subtotal
      setVat((rates / 100) * subTotal);
      setTotal(overallSum);
      if(invoiceData.items.length ===0){
        setTotal(0);
      }
    };
    total();
  }, [invoiceData, rates, subTotal]);

  const handleAddField = (e) => {
    e.preventDefault();
    setInvoiceData((prevState) => ({
      ...prevState,
      items: [
        ...prevState.items,
        {
          itemName: "",
          unitPrice: "",
          quantity: 0,
          discount: "",
          amount: "",
        },
      ],
    }));
  };

  const handleRemoveField = (index) => {
    const values = invoiceData.items;
    values.splice(index, 1);
    if(values.length===1){
      setTotal(0)
      setInvoiceData((prevState) => ({ ...prevState, total:null }));
    }
    else
    setInvoiceData((prevState) => ({ ...prevState, values }));
    // console.log(values)
  };
  const handleClient = (value) => {
    setSelectedDate(today.getTime() + value?.dueDate * 24 * 60 * 60 * 1000);
  };
  // console.log(invoiceData);

  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();

    const hasEmptyItems = invoiceData.items.some(
      (item) => !item.quantity && !item.paymentMode
    );
    // console.log(hasEmptyItems);
    if (
      hasEmptyItems ||
      // !subTotal ||
      !total
      // !vat ||
      // !rates ||
      //  !currency ||
      // !selectedDate ||
      // !client
      // !signatureDataUrl||
      // !supplier
      // !type ||
      // !status
    ) {
      // console.log("client", client);
      // Update the error state with an appropriate error message
      openSnackbar("Please fill the details");
      return;
    }
    // const currentDate = new Date();
    // const year = currentDate.getFullYear();
    // const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    // const day = String(currentDate.getDate()).padStart(2, "0");
    // const hours = String(currentDate.getHours()).padStart(2, "0");
    // const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    // const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    // const randomID = Math.floor(100 + Math.random() * 900);
    //    const invoiceNumber = `${randomID}${seconds}`;

    // const invoiceNumber = `${year}${month}${day}${hours}${minutes}${seconds}`;

    setError("");
    if (invoice) {
      dispatch(
        updateInvoice(invoice._id, {
          ...invoiceData,
          subTotal: subTotal,
          total: total,
          vat: vat,
          rates: rates,
          currency: currency,
          dueDate: selectedDate,
          client,
          // supplier,
          // signatureDataUrl,
          type: type,
          status: status,
          isPrintBill: true,
        })
      );

      history.push(`/invoice/${invoice._id}`);
    } else {
      dispatch(
        createInvoice(
          {
            ...invoiceData,
            subTotal: subTotal,
            total: total,
            vat: vat,
            rates: rates,
            currency: currency,
            dueDate: selectedDate,
            invoiceNumber: invoiceData.invoiceNumber,
            client: client ? client : defaultClient,
            // supplier,
            // signatureDataUrl,
            type: type,
            status: status,
            paymentRecords: [],
            creator: [user?.result?._id || user?.result?.googleId],
            createdAt: new Date(),
            isPrintBill: true,
          },
          history
        )
      );
    }

    setInvoiceData(initialState);
  };

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // const [openSupplier, setOpenSupplier] = useState(false);
  const CustomPaper = (props) => {
    return <Paper elevation={3} {...props} />;
  };

  if (!user) {
    history.push("/login");
  }

  return (
    <div className={styles.invoiceLayout}>
      <form onSubmit={handleSubmit} className="mu-form">
        <AddClient setOpen={setOpen} open={open} />
        {/* <AddSupplier setOpen={setOpenSupplier} open={openSupplier} /> */}
        <Container className={classes.headerContainer}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <div style={{ marginTop: "20px" }}>
                {" "}
                <h1>Date</h1>
                <p>
                  {invoice
                    ? moment(invoice.createdAt).format(" Do MMM YYYY")
                    : moment().format("Do MMM YYYY")}
                </p>
              </div>
            </Grid>

            <Grid item>
              <InvoiceType type={type} setType={setType} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <span style={{ marginRight: "10px", fontUnit: "bold" }}>
                  ID:
                </span>
                <div
                  style={{
                    backgroundColor: "#f4f4f4",
                    padding: "8px",
                    borderRadius: "4px",
                  }}
                >
                  <span style={{ color: "black" }}>
                    <Typography variant="body2" color="initial">
                      {invoiceData.invoiceNumber}
                    </Typography>
                  </span>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <Divider />
        <Grid
          container
          justifyContent="space-between"
          style={{ marginTop: "40px", marginBottom: "20px" }}
        >
          <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
            <Container>
              {client && (
                <>
                  <Typography variant="subtitle1" gutterBottom>
                    {client.name}
                  </Typography>

                  {/* <Typography variant="body2">{client.email}</Typography> */}
                  <Typography variant="body1">{client.phone}</Typography>
                  {/* <Typography variant="body2">{client.address}</Typography>
                  <Typography variant="body2">{client.dueDate}</Typography> */}
                  {/* <Typography variant="body2">
                      {client.dueDate} Credit Days
                    </Typography> */}
                  <Button
                    color="primary"
                    size="small"
                    style={{
                      textTransform: "none",
                      backgroundColor: "#ef7f1a",
                      color: "white",
                    }}
                    onClick={() => setClient(null)}
                  >
                    Change
                  </Button>
                </>
              )}
              <div style={client ? { display: "none" } : { display: "block" }}>
                <Autocomplete
                  {...clientsProps}
                  PaperComponent={CustomPaper}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // required={!invoice && true}
                      label="Select Customer"
                      margin="normal"
                      variant="outlined"
                    />
                  )}
                  value={clients?.name}
                  onChange={(event, value) => {
                    setClient(value);
                    handleClient(value);
                  }}
                  // onChange={(event, value) => setClient(value) }
                />
              </div>
              {!client && (
                <>
                  <Grid item style={{ paddingBottom: "10px" }}>
                    <Chip
                      avatar={
                        <Avatar
                          style={{ backgroundColor: "#ef7f1a", color: "white" }}
                        >
                          +
                        </Avatar>
                      }
                      label="New Customer"
                      onClick={() => setOpen(true)}
                      variant="outlined"
                    />
                  </Grid>
                </>
              )}
            </Container>
          </Grid>
          <Grid item xs={12} md={6} style={{ paddingRight: "10px" }}>
            <Container>
              <div style={{ marginTop: "6.5px" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                  <InputLabel id="demo-simple-select-outlined-label">
                    Payment Mode
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={invoiceData.paymentMode}
                    required={true}
                    onChange={(e) => {
                      // console.log("Invoice.js me  signature console.log",signatureDataUrl)
                      setInvoiceData({
                        ...invoiceData,
                        paymentMode: e.target.value,
                      });
                    }}
                    label="Payment Method"
                  >
                    <MenuItem value="Select">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="Online">Online</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Container>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ textAlign: "right", marginBottom: "10px" }}
          >
            {/* <Typography
                variant="overline"
                style={{ color: "gray" }}
                gutterBottom
              >
                Status
              </Typography>
              <Typography
                variant="h6"
                gutterBottom
                style={{ color: type === "Receipt" ? "green" : "red" }}
              >
                {type === "Receipt" ? "Paid" : "Unpaid"}
              </Typography> */}

            {/* <Typography
              variant="overline"
              style={{ color: "gray" }}
              gutterBottom
            >
              Payment Term
            </Typography>
            <Typography variant="body2" gutterBottom> */}
            {/* {selectedDate
                  ? moment(selectedDate).format("MMM Do YYYY")
                  : "27th Sep 2021"} */}
            {/* {client ? client.dueDate + " days" : ""} */}
            {/* </Typography> */}
            {/* <Typography variant="overline" gutterBottom>
              Amount
            </Typography>
            <Typography variant="h6" gutterBottom>
              {currency} {toCommas(total)}
            </Typography> */}
          </Grid>
        </Grid>
        <div>
          <Container
            style={{
              display: "flex",
              alignItems: "end",
              justifyContent: "start",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            <TextField
              id="search-bar"
              label="Enter Barcode"
              variant="outlined"
              margin="normal"
              value={searchQuery}
              onChange={handleBarcodeInputChange}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  // Execute your function here with e.target.value
                  e.preventDefault();
                }
              }}
            />
            {searchQuery && (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSearchSubmit}
                style={{ margin: "0 0 20px 20px" }}
              >
                Add Item
              </Button>
            )}
          </Container>
        </div>
        <div>
          <TableContainer component={Paper} className="tb-container">
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontSize: "20px" }}>Delete</TableCell>
                  <TableCell style={{ fontSize: "20px" }}>Item</TableCell>
                  <TableCell style={{ fontSize: "20px" }}>Unit</TableCell>
                  <TableCell style={{ fontSize: "20px" }}>Qty</TableCell>
                  <TableCell style={{ fontSize: "20px" }}>MRP</TableCell>
                  <TableCell align="center" style={{ fontSize: "20px" }}>
                    Amount
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoiceData.items.map((itemField, index) => (
                  // console.log("itemField:", itemField), // Log the itemField object
                  // console.log("itemName:", itemField.itemName), // Log the itemName value
                  <TableRow key={index}>
                    <TableCell align="left">
                      <IconButton
                        style={{ border: "1px solid red" }}
                        onClick={() => handleRemoveField(index)}
                      >
                        <DeleteOutlineRoundedIcon
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "red",
                          }}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ cursor: "context-menu", fontSize: "18px" }}
                    >
                      {invoiceData.items[index].itemName}
                      <div
                        style={
                          invoiceData.items[index].itemName
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        <FormControl variant="outlined" fullWidth>
                          <Autocomplete
                            options={items}
                            getOptionLabel={(item) =>
                              `${item.itemName} | ${item.unit}`
                            }
                            value={items.itemName}
                            onChange={(e, value) => {
                              handleNameChange(index, value || "");
                              inputRef.current.focus();
                            }} // Modify this line
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Search Item"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </div>
                    </TableCell>

                    <TableCell
                      align="left"
                      style={{ cursor: "context-menu", fontSize: "18px" }}
                    >
                      {itemField.itemName ? (
                        <span>
                          {/* {getItemPrice(invoiceData.items[index].itemName)} */}
                          {itemField.unit}
                        </span>
                      ) : (
                        0
                      )}
                    </TableCell>
                    <TableCell align="left" style={{ fontSize: "18px" }}>
                      {" "}
                      <InputBase
                        inputRef={inputRef}
                        sx={{ ml: 1, flex: 1, width: "10px" }}
                        type="number"
                        name="quantity"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.itemName ? itemField.quantity : "0"}
                        placeholder="0"
                        style={{ fontSize: "18px", width: "30px" }}
                        // autoFocus={itemField.itemName }
                      />{" "}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{ cursor: "context-menu", fontSize: "18px" }}
                    >
                      {itemField.itemName ? (
                        <span>
                          {/* {getItemPrice(invoiceData.items[index].itemName)} */}
                          {itemField.unitPrice}
                        </span>
                      ) : (
                        0
                      )}
                    </TableCell>

                    <TableCell align="center">
                      {" "}
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        type="number"
                        name="amount"
                        onChange={(e) => handleChange(index, e)}
                        value={itemField.quantity * itemField.unitPrice}
                        disabled
                        style={{ fontSize: "18px", display: "none" }}
                      />{" "}
                      <Typography variant="h5" color="initial">
                        {itemField.quantity * itemField.unitPrice}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className={styles.addButton}>
            <button
              onClick={handleAddField}
              style={{ backgroundColor: "#ef7f1a", color: "white" }}
              // disabled={scanning}
            >
              +
            </button>
          </div>
        </div>
        {/* <div>
          <Grid
            item
            xs={12}
            md={6}
            style={{ paddingRight: "10px", marginTop: "10px" }}
          >
            <Container></Container>
          </Grid> */}
        <div className={styles.invoiceSummary}>
          <div className={styles.summary}>Order Summary</div>
          <div className={styles.summaryItem}>
            <p>Total Items:</p>
            <h4>
              {invoiceData.items.reduce((accumulator, item) => {
                const quantity = parseInt(item.quantity, 10) || 0; // Use 0 if item.quantity is empty or not a number
                return accumulator + quantity;
              }, 0)}
            </h4>
          </div>
          {invoiceData.paymentMode && (
            <div className={styles.summaryItem}>
              <p>Payment Mode:</p>
              <h4>{invoiceData.paymentMode}</h4>
            </div>
          )}

          <div className={styles.summaryItem}>
            <h2 style={{ marginLeft: "14px" }}>Total</h2>
            <h2 style={{ padding: "12px" }}>
              {currency} {toCommas(total)}
            </h2>
          </div>
        </div>
        {/* </div> */}
        {/* <div className={styles.toolBar}>
          <Container>
            <Grid container>
              <Grid item style={{ marginTop: "16px", marginRight: 40 }}>
                <TextField
                  type="text"
                  step="any"
                  name="rates"
                  id="rates"
                  value={rates}
                  onChange={handleRates}
                  placeholder="e.g 10"
                  label="Discount(%)"
                />
              </Grid> */}
        {/* <Grid item style={{ marginRight: 10, marginLeft: 80 }}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Due date"
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid> */}
        {/* <Grid item style={{ width: 270, marginRight: 10 }}>
                <Autocomplete
                  {...defaultProps}
                  id="debug"
                  debug
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select currency"
                      margin="normal"
                    />
                  )}
                  value={currency.value}
                  onChange={(event, value) => setCurrency(value.value)}
                />
              </Grid> */}
        {/* </Grid>
          </Container>
        </div> */}
        <br />
        {/* {signatureDataUrl ? (
               <div>
            
              <img src={signatureDataUrl} alt="signature" className="signature" width={150} height={150}/>
             
              
              <Button
                    color="primary"
                    size="small"
                    style={{
                      textTransform: "none",
                      backgroundColor: "#ef7f1a",
                      color: "white",
                      margin:5
                    }}
                    onClick={() => setSignatureDataUrl(null)}
                  >
                    Change
                  </Button>
                  </div>
            ):<div>
           
            <SignaturePad setSignatureDataUrl={setSignatureDataUrl}/>{
             
            }
          </div>} */}

        <div className={styles.note}>
          <h4>Notes/Payment Info</h4>
          <textarea
            style={{ border: "solid 1px #d6d6d6", padding: "10px" }}
            placeholder="Provide additional details or terms of service"
            onChange={(e) => {
              // console.log("Invoice.js me  signature console.log",signatureDataUrl)
              setInvoiceData({ ...invoiceData, notes: e.target.value });
            }}
            value={invoiceData.notes}
          />
        </div>

        {/* <button className={styles.submitButton} type="submit">Save and continue</button> */}
        <Grid container justifyContent="center">
          <Button
            variant="contained"
            style={{
              justifyContentContent: "center",
              backgroundColor: "#ef7f1a",
              color: "white",
            }}
            type="submit"
            color="primary"
            size="large"
            className={classes.button}
            startIcon={<SaveIcon />}
          >
            Save and Continue
          </Button>
          {error && (
            <Grid
              container
              justifyContent="center"
              style={{ marginTop: "10px" }}
            >
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default Invoice;
