import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  makeStyles,
  FormControl,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import {} from "@material-ui/core/styles";
import { useCart } from "../Context/CartContext";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    width: "600px",
    height: "620px",
  },
  title: {
    fontSize: "2rem",
    fontWeight: "bold",
    paddingBottom: theme.spacing(2),
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  changeText: {
    textAlign: "center",
    fontSize: "3.5rem",
    margin: theme.spacing(0.5),
  },
  receivedAmountField: {
    width: "50%",
    marginBottom: theme.spacing(1),
    "& .MuiInputLabel-root": {
      fontSize: "16px", // Adjust the font size for the label
    },
    "& .MuiOutlinedInput-root": {
      "& input": {
        padding: "10px 10.5px", // Remove the default padding
        fontSize: "28px",
        // Adjust the font size for the input text
      },
    },
  },
  changeField: {
    width: "40%",
    marginLeft: theme.spacing(2),
    "& .MuiOutlinedInput-root": {
      "& input": {
        padding: "10px 10.5px", // Remove the default padding
        fontSize: "28px",
        // Adjust the font size for the input text
      },
    },
  },
  dialPadContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",

    marginBottom: theme.spacing(2),
  },
  dialButton: {
    fontSize: "1.8rem",
    padding: "14px 0px ",
    textAlign: "center",
    background: theme.palette.primary.main,
    color: "#fff",
    border: `1px solid #fff`,
    cursor: "pointer",
    transition: "background 0.2s",
    "&:hover": {
      background: theme.palette.primary.dark,
    },
    backspaceButton: {
      gridColumn: "span 3",
      background: theme.palette.warning.main,
      "&:hover": {
        background: theme.palette.warning.dark,
      },
    },
  },
  largeButton: {
    gridColumn: "span 2",
  },
  clearButton: {
    gridColumn: "span 3",
    background: theme.palette.error.main,
    "&:hover": {
      background: theme.palette.error.dark,
    },
  },
}));

function BillDialog({ open, onClose, currency ,setOpenBillDialog}) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const { getTotalPrice, receivedAmount, setReceivedAmount, calculateChange } =
    useCart();

  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleSaveAndPrint = () => {
    // Add logic to save and print the bill
    // You can use receivedAmount to calculate change
  };

  const handleDialButtonClick = (digit) => {
    setReceivedAmount((prev) => prev + digit);
  };

  const handleClearAll = () => {
    setReceivedAmount("");
  };

  const handleBackspace = () => {
    setReceivedAmount((prev) => prev.slice(0, -1));
  };

  return (
    <div>
     
      <IconButton onClick={()=>setOpenBillDialog(false)}>
        <ArrowBackIosIcon />{" "}
      </IconButton>
     
      
      <Typography className={classes.changeText}>
        {`${currency}${getTotalPrice().toFixed(2)}`}
      </Typography>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <FormControl className={classes.receivedAmountField} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={receivedAmount}
            onChange={(e) => setReceivedAmount(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <p style={{ fontSize: "xx-large" }}>{currency}</p>
              </InputAdornment>
            }
            labelWidth={60}
          />
        </FormControl>
        <FormControl className={classes.changeField} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Change</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={calculateChange()}
            disabled
            startAdornment={
              <InputAdornment position="start">
                <p style={{ fontSize: "xx-large" }}>{currency}</p>
              </InputAdornment>
            }
            labelWidth={60}
          />
        </FormControl>
        {/* <Typography variant="h5" style={{marginRight:"20px"}}>
            Change: {currency}
            {calculateChange()}
          </Typography> */}
      </div>
      <div className={classes.dialPadContainer}>
        {Array.from(Array(9).keys()).map((number) => (
          <div
            key={number}
            className={classes.dialButton}
            onClick={() => handleDialButtonClick((number + 1).toString())}
          >
            {number + 1}
          </div>
        ))}
        <div
          className={`${classes.dialButton} ${classes.largeButton}`}
          onClick={() => handleDialButtonClick("0")}
        >
          0
        </div>
        <div
          className={`${classes.dialButton} ${classes.backspaceButton}`}
          onClick={handleBackspace}
        >
          &#9003; {/* Backspace */}
        </div>
        <div
          className={`${classes.dialButton} ${classes.clearButton}`}
          onClick={handleClearAll}
        >
          Clear All
        </div>
      </div>
    </div>
  );
}

export default BillDialog;
