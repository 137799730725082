export const initialCommissionDetail = {
    serviceId:'',
    serviceUnitPrice:'',
    totalQty:0,
    invoiceId:'',
    staffDetails: [], 
    creator: '',
    createdAt: new Date(),
}

