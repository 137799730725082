import {
  ADD_NEW_STOCK,
  UPDATE_STOCK,
  DELETE_STOCK,
  DELETE_STOCKS,
  FETCH_STOCKS,
  FETCH_STOCK,
  START_LOADING,
  END_LOADING,
} from "../actions/constants";

const stocks = (state = { isLoading: true, stocks: [] }, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };
    case END_LOADING:
      return { ...state, isLoading: false };
    case FETCH_STOCKS:
      return {
        ...state,
        stocks: action.payload,
        currentPage: action.payload.currentPage,
        numberOfPages: action.payload.numberOfPages,
      };
    //   case FETCH_stocks_BY_USER:
    //     return { ...state, stocks: action.payload };

    case FETCH_STOCK:
      return { ...state, stock: action.payload };
    case ADD_NEW_STOCK:
      return { ...state, stocks: [...state.stocks, ...action.payload] };

    case UPDATE_STOCK:
      return {
        ...state,
        stocks: state.stocks.map((stock) =>
          stock._id === action.payload._id ? action.payload : stock
        ),
      };
    case DELETE_STOCK:
      return {
        ...state,
        stocks: state.stocks.filter((stock) => stock._id !== action.payload),
      };
    case DELETE_STOCKS:
      const { id, type } = action.payload;
      let filteredStocks;
      switch (type) {
        case "invoiceId":
          filteredStocks = state.stocks.filter(
            (stock) => stock.invoiceId !== id
          );
          break;
        case "purchaseId":
          filteredStocks = state.stocks.filter(
            (stock) => stock.purchaseId !== id
          );
          break;
        case "stockAdjustmentId":
          filteredStocks = state.stocks.filter(
            (stock) => stock.stockAdjustmentId !== id
          );
          break;
        default:
          filteredStocks = state.stocks; // No filtering for unknown type
      }
      return { ...state, stocks: filteredStocks };
    default:
      return state;
  }
};

export default stocks;

//   const stocks =( state = { stocks: [], }, action ) => {
//     switch (action.type) {
//         case FETCH_ALL:
//             return { ...state, stocks: action.payload }

//         case GET_stock:
//             return { ...state, stock: action.payload }

//         case ADD_NEW:
//             return { ...state, stocks: [...state.stocks, action.payload] }

//         case UPDATE:
//             return { ...state, stocks: state.stocks.map((stock) => stock._id === action.payload ? action.payload : stock) }

//         case DELETE:
//         return {...state, stocks: state.stocks.filter((stock) => stock._id !== action.payload)}

//         default:
//             return state;
//     }
// }

// export default stocks
