import React, { createContext, useContext, useState } from "react";

const DiscountContext = createContext();

export const useDiscount = () => {
  return useContext(DiscountContext);
};

export const DiscountProvider = ({ children }) => {
  const [discountType, setDiscountType] = useState(false);
  const [discountVal, setDiscountVal] = useState(0);
  const [discountRate, setDiscountRate] = useState(0);
  const [discountFlag, setDiscountFlag] = useState(false);
  const [openDiscountbox, setOpenDiscountbox] = useState(false);

  const handleDiscountTypeChange = () => {
    setDiscountType(!discountType);
  };

  const handleDisountBoxOpen = () => {
    setOpenDiscountbox(true);
  };

  const handleDisountBoxClose = () => {
    setOpenDiscountbox(false);
  };
  const calculateDiscount = (subTotal) => {
    let discountValue = parseFloat(discountVal);
    setDiscountRate(discountVal);
    if (discountType) {
      discountValue = (discountVal / 100) * subTotal;
    }
    setDiscountVal(discountValue);
  };
  const handleAddDiscount = (subTotal) => {
    // Perform actions with the discount value and type
    setDiscountFlag(true);
    calculateDiscount(subTotal);
    console.log("Discount Type:", discountType === 0 ? "Sum" : "Percentage");
    console.log("Discount Value:", discountVal);

    // Close the modal after handling the discount
    handleDisountBoxClose();
  };
  const clearDiscount = () => {
    setDiscountFlag(false);
    setDiscountVal(null);
    setDiscountType(false);
  };
  return (
    <DiscountContext.Provider
      value={{
        discountType,
        setDiscountType,
        discountVal,
        setDiscountVal,
        discountFlag,
        setDiscountFlag,
        openDiscountbox,
        setOpenDiscountbox,
        handleDiscountTypeChange,
        handleDisountBoxOpen,
        handleDisountBoxClose,
        handleAddDiscount,
        calculateDiscount,
        clearDiscount,
        discountRate,
        setDiscountRate,
      }}
    >
      {children}
    </DiscountContext.Provider>
  );
};
