/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import { getAllItems, getItemsByUser } from "../../actions/itemActions";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { createStaff, updateStaff, getStaff } from "../../actions/staffActions";
import { useSnackbar } from "react-simple-snackbar";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: "#1976D2",
    marginLeft: 0,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const EditStaff = () => {
  const location = useLocation();
  const history = useHistory();
  const [staffData, setStaffData] = useState({
    name: "",
    email: "",
    phone: "+91",
    code: "",
    userId: "",
    services: [],
  });

  const [user, setUser] = useState(JSON.parse(localStorage.getItem("profile")));
  const { id } = useParams();
  const dispatch = useDispatch();
  const staff = useSelector((state) => state.staff.staff);
  console.log("staff:", staff);
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [selectedServices, setSelectedServices] = useState([]);
  const [errors, setErrors] = useState({}); // State variable for error messages
  const { items } = useSelector((state) => state.items);
  const services = items
    .filter((item) => item.category.categoryName === "Services")
    .map((item) => ({
      id: item._id, // Assuming you have an id property in your items
      name: item.itemName,
      unitPrice: item.unitPrice,
      commission: 0, // Set the initial commission to 0
    }));
  console.log(services);
  useEffect(() => {
    dispatch(getAllItems());
  }, [location, dispatch]);
  const handleChange = (e) => {
    const { name, value } = e.target;

    const maskedValue = value.startsWith("+91")
      ? value
      : "+91" + value.slice(3);
    if (name === "phone") {
      setStaffData((prevData) => ({
        ...prevData,
        [name]: maskedValue,
      }));
    } else {
      setStaffData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Clear the error for the specific field
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (staffData.name.trim() === "") {
      newErrors.name = "Name is required";
      isValid = false;
    }
    if (staffData.email.trim() === "") {
      newErrors.email = "Email is required";
      isValid = false;
    }
    if (staffData.phone.trim() === "") {
      newErrors.phone = "Phone is required";
      isValid = false;
    } else if (staffData.phone.length < 10 || staffData.phone.length > 13) {
      newErrors.phone = "Please enter a valid phone number.";
      isValid = false;
    }
    if (staffData.code.trim() === "") {
      newErrors.code = "Code is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };
  useEffect(() => {
    dispatch(getStaff(id));

    // eslint-disable-next-line
  }, [id]);
  useEffect(() => {
    if (staff) {
      setStaffData(staff);
      setSelectedServices(staff.services);
    }
  }, [staff,location]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem("profile")));
    // setStaffData({...staffData, userId: user?.result?._id})
  }, [location]);

  useEffect(() => {
    var checkId = user?.result?._id;
    if (checkId !== undefined) {
      setStaffData({ ...staffData, userId: [checkId] });
    } else {
      setStaffData({ ...staffData, userId: [user?.result?.googleId] });
    }
  }, [location]);

  const clear = () => {
    setStaffData({
      name: "",
      email: "",
      phone: "",
      code: "",
      userId: [],
      services: [],
    });
    setErrors({}); // Clear the error messages
  };

  const handleSubmitStaff = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Check if the form is valid before submitting
      if (staff) {
        dispatch(
          updateStaff(
            staff._id,
            { ...staffData, services: selectedServices },
            openSnackbar
          )
        );
        // window.location.href = "/staff";
        history.push("/staff")
      } else {
        dispatch(
          createStaff(
            { ...staffData, services: selectedServices },
            openSnackbar
          )
        );
      }
      
    }
    console.log("Selected Services", { ...staffData, services: selectedServices })
  };

  const handleCheckboxChange = (serviceId) => {
    setSelectedServices((prevSelectedServices) => {
      const isSelected = prevSelectedServices.some(
        (service) => service.id === serviceId
      );

      if (isSelected) {
        return prevSelectedServices.filter(
          (service) => service.id !== serviceId
        );
      } else {
        return [
          ...prevSelectedServices,
          {
            id: serviceId,
            commission: 0,
          },
        ];
      }
    });
  };
  const handleCommissionChange = (serviceId, commission) => {
    setSelectedServices((prevSelectedServices) => {
      return prevSelectedServices.map((service) =>
        service.id === serviceId
          ? { ...service, commission: commission }
          : service
      );
    });
  };
  return (
    <div>
      <Container>
        <Paper
          elevation={3}
          style={{
            padding: "20px",
            borderRadius: "8px",
            maxWidth: "600px",
            margin: "auto",
          }}
        >
          <Typography
            variant="h5"
            align="center"
            style={{ marginBottom: "20px" }}
          >
            Personal Details
          </Typography>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Name"
                  fullWidth
                  name="name"
                  value={staffData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                  disabled={staffData.name === "Sadhana Ayurvedics Pvt. Ltd."}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Email"
                  fullWidth
                  name="email"
                  value={staffData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Phone"
                  fullWidth
                  name="phone"
                  value={staffData.phone}
                  onChange={handleChange}
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Code"
                  fullWidth
                  name="code"
                  value={staffData.code}
                  onChange={handleChange}
                  error={!!errors.code}
                  helperText={errors.code}
                />
              </Grid>
            </Grid>
            <Typography
              variant="h6"
              style={{ marginTop: "20px", marginBottom: "10px" }}
            >
              Service Details
            </Typography>
            <Grid container spacing={2}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Sr# </TableCell>
                      <TableCell>Service</TableCell>
                      <TableCell>Unit Price</TableCell>
                      <TableCell>Commission</TableCell>
                      <TableCell>Select</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {services.map((service, index) => (
                      <TableRow key={service.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{service.name}</TableCell>
                        <TableCell>{service.unitPrice}</TableCell>
                        <TableCell>
                          {selectedServices?.some((s) => s.id === service.id) ? (
                            <TextField
                              type="number"
                              value={
                                selectedServices.find(
                                  (s) => s.id === service.id
                                )?.commission || ""
                              }
                              onChange={(e) =>
                                handleCommissionChange(
                                  service.id,
                                  e.target.value
                                )
                              }
                              style={{ width: "80px", marginRight: "8px" }} // Add your desired styling here
                            />
                          ) : (
                            <span style={{ color: "red" }}>Not Selected</span>
                          )}
                        </TableCell>

                        <TableCell>
                          <Checkbox
                            checked={selectedServices?.some(
                              (s) => s.id === service.id
                            )}
                            onChange={() => handleCheckboxChange(service.id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Button
              onClick={handleSubmitStaff}
              variant="contained"
              style={{
                marginTop: "20px",
                backgroundColor: "#ef7f1a",
                color: "white",
              }}
            >
              Save
            </Button>
          </form>
        </Paper>
      </Container>
    </div>
  );
};

export default EditStaff;
