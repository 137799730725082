import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  CardContent,
  TextField,
  MenuItem,
  Button,
  makeStyles,
  IconButton,
  Container,
  Chip,
  Avatar,
  Paper,
  Typography,
} from "@material-ui/core";
import PaymentOptions from "./PaymentOptions";
import ClientInfoCard from "./ClientInfoCard";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ArrowBack } from "@material-ui/icons"; // Import the back icon
import { useCart } from "../Context/CartContext";
import { StaffCommissionDetailContext } from "../Context/StaffCommissionDetailsContext";
import { initialState } from "../initialState";
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCurrency } from "../Context/CurrencyContext";
import { useDiscount } from "../Context/DiscountContext";
import {
  createInvoice,
  getInvoice,
  updateInvoice,
} from "../actions/invoiceActions";
import { updateStaffCommissionDetails } from "../actions/staffCommissionDetailActions";
import { updateItems } from "../actions/itemActions";
import { getAllClients } from "../actions/clientActions";
import SaveIcon from "@material-ui/icons/Save";
import { usePayNow } from "../Context/PayNowContext";
import BillDialog from "./BillDialog";
import CardDialog from "./CardDialog";
import { createStock, deleteStockById } from "../actions/stockActions";
import AddClient from "../components/Invoice/AddClient";
const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 600, // Increase the size of the card
    margin: "auto",
    padding: "0 48px",

    boxShadow: "0 4px 8px 0 rgba(0,0,0,1.7)", // Add box shadow
  },
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  backButton: {
    position: "absolute",
    top: theme.spacing(17),
    left: theme.spacing(28),
  },
  saveButton: {
    display: "flex",
    justifyContent: "center",
  },
}));
const options = {
  position: "bottom",
  style: {
    backgroundColor: "red",
    border: "2px solid red",
    color: "white",
    fontFamily: "Menlo, monospace",
    fontSize: "20px",
    textAlign: "center",
  },
  closeStyle: {
    color: "lightcoral",
    fontSize: "16px",
  },
};
const PaymentDetails = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {
    cartItems,
    getTotalPrice,
    receivedAmount,
    setReceivedAmount,
    calculateChange,
    calculateVat,
    getSubTotalPrice,
    calculateDiscount,
  } = useCart();
  const { staffCommissionDetailsArray } = useContext(
    StaffCommissionDetailContext
  );
  const { currency, updateCurrency } = useCurrency();
  const [invoiceData, setInvoiceData] = useState(initialState);
  const [openSnackbar, closeSnackbar] = useSnackbar(options);
  const [error, setError] = useState("");
  const { invoice } = useSelector((state) => state.invoices);
  const user = JSON.parse(localStorage.getItem("profile"));
  const clients = useSelector((state) => state.clients.clients);
  const [client, setClient] = useState(null);
  const [defaultClient, setDefaultClient] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const {
    discountFlag,
    clearDiscount,
    discountVal,
    discountType,
    discountRate,
  } = useDiscount();
  const [itemsData, setItemsData] = useState([]);
  const [openBillDialog, setOpenBillDialog] = useState(false);
  const [openCardDialog, setOpenCardDialog] = useState(false);
  const handleBillDialogClose = () => {
    // You can add logic to close the dialog and handle bill printing here
    setOpenBillDialog(false);
  };
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const defaultClient = clients.filter((clt) => clt.name === "Cash");
    console.log("Default Client", defaultClient);
    setDefaultClient(defaultClient[0]);
  }, [clients]);

  useEffect(() => {
    dispatch(
      // getClientsByUser({ search: user?.result._id || user?.result?.googleId })
      getAllClients()
    );
    // dispatch(
    //   // getClientsByUser({ search: user?.result._id || user?.result?.googleId })
    //   getAllSuppliers()
    // );

    // dispatch(
    //   // getItemsByUser({ search: user?.result._id || user?.result?.googleId })
    //   getAllItems()
    // );
    // eslint-disable-next-line
  }, [dispatch]);
  useEffect(() => {
    setInvoiceData({ ...invoiceData, items: cartItems });
  }, [cartItems]);
  useEffect(() => {
    // Whenever formData changes, set hasUnsavedChanges to true
    setHasUnsavedChanges(true);
  }, [invoiceData]);
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        // Display a confirmation message
        e.preventDefault();
        e.returnValue =
          "You have unsaved changes. Are you sure you want to leave this page?";
      }
    };

    // Attach the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasUnsavedChanges]);

  useEffect(() => {
    if (invoice && invoice.items && invoice.items.length > 0) {
      // Extracting _id and name from each item and creating a new array of objects
      const extractedData = invoice.items.map((item) => ({
        _id: item._id,
        quantity: item.quantity,
      }));
      setItemsData(extractedData);
      setInvoiceData(invoice);
      setClient(invoice.client);
    }
  }, [invoice]);

  const stockDetailsItems = (cartItems, invoiceNumber) => {
    const stockItems = cartItems.map((item) => ({
      itemName: item.itemName,
      qty: -1 * item.quantity,
      transactionRef: "Invoice",
      unitPrice: item.unitPrice,
      categoryId: item.category.categoryId,
      itemId: item._id,
      invoiceId: invoiceNumber,
      createdAt: new Date(),
    }));

    return stockItems;
  };

  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();

    const hasEmptyItems = invoiceData.items.some(
      (item) => !item.quantity && !item.paymentMode
    );

    // console.log(hasEmptyItems);
    if (
      hasEmptyItems ||
      // !subTotal ||
      !getTotalPrice

      // !vat ||
      // !rates ||
      //  !currency ||
      // !selectedDate ||
      // !client
      // !signatureDataUrl||
      // !supplier
      // !type ||
      // !status
    ) {
      openSnackbar("Please fill the details");
      return;
    }
    if (invoiceData.paymentMode === "Cash" && !receivedAmount) {
      openSnackbar("Please fill the Amount");
      return;
    } else if (
      invoiceData.paymentMode === "Cash" &&
      receivedAmount < getTotalPrice()
    ) {
      openSnackbar("Please pay the required amount ");
      return;
    }
    setError("");
    if (invoice) {
      debugger;
      const updatedCartItems = invoiceData.items.map((item) => {
        debugger;
        const filteredItem = cartItems.find((i) => i._id === item._id);

        if (filteredItem) {
          const quantity = parseFloat(item.quantity);
          const currentQty = parseFloat(
            item.stockQty?.currentQty ? item.stockQty.currentQty : 0
          );

          if (!isNaN(quantity) && !isNaN(currentQty)) {
            let updatedCurrentQty = currentQty;

            if (itemsData) {
              const foundItemData = itemsData.find(
                (data) => data._id === item._id
              );
              if (foundItemData) {
                const difference = Math.abs(foundItemData.quantity - quantity);
                if (foundItemData.quantity < quantity) {
                  updatedCurrentQty -= difference;
                } else {
                  updatedCurrentQty += difference;
                }
              }
            }

            const updatedItem = {
              ...filteredItem,
              stockQty: {
                ...filteredItem.stockQty,
                currentQty: updatedCurrentQty,
              },
            };
            return updatedItem;
          } else {
            console.error("Invalid quantity or initialQty:", item);
            return item;
          }
        } else {
          console.error("Item not found for ID:", item.itemId);
          return item;
        }
      });

      console.log("Updated Item ", updatedCartItems);
       dispatch(updateItems(updatedCartItems, openSnackbar));

      dispatch(
        updateInvoice(
          invoice._id,
          {
            ...invoiceData,
            subTotal: getSubTotalPrice(),
            receivedAmount:
              invoiceData.paymentMode === "Cash" ? receivedAmount : null,
            change:
              invoiceData.paymentMode === "Cash" ? calculateChange() : null,
            total: getTotalPrice(),
            vat: calculateVat(),
            discount: discountVal ? discountVal.toFixed(2) : 0,
            // rates: rates,
            currency: currency,
            // dueDate: selectedDate,
            client: client ? client : defaultClient,
            // supplier,
            // signatureDataUrl,
            // type: type,
            isPrintBill: true,
            creator: [user?.result?._id || user?.result?.googleId],
          },
          staffCommissionDetailsArray
        )
      );
      dispatch(deleteStockById(invoiceData.invoiceNumber, "invoiceId"));
      debugger;
      console.log("invoiceData items", invoiceData.items);
      console.log(
        "stockDetailsItems",
        stockDetailsItems(invoiceData.items, invoiceData.invoiceNumber)
      );
      dispatch(
        createStock(
          stockDetailsItems(invoiceData.items, invoiceData.invoiceNumber)
        )
      );
      dispatch(updateStaffCommissionDetails(staffCommissionDetailsArray));
      history.push(`/invoice/${invoice._id}`);
    } else {
      const updatedCartItems = cartItems.map((item) => {
        const quantity = parseFloat(item.quantity);
        const currentQty = parseFloat(
          item.stockQty?.currentQty ? item.stockQty.currentQty : 0
        );

        // Check if the values are valid numbers before subtraction
        if (!isNaN(quantity) && !isNaN(currentQty)) {
          return {
            ...item,
            stockQty: {
              ...item.stockQty,
              currentQty: currentQty - quantity,
            },
          };
        } else {
          // Handle the case where the values are not valid numbers
          console.error("Invalid quantity or initialQty:", item);
          // You can choose to set a default value or skip updating the item
          return item; // or return a default value
        }
      });
      console.log("updated Items", updatedCartItems);

      dispatch(updateItems(updatedCartItems, openSnackbar));

      dispatch(
        createInvoice(
          {
            ...invoiceData,
            subTotal: getSubTotalPrice(),
            total: getTotalPrice(),
            receivedAmount:
              invoiceData.paymentMode === "Cash" ? receivedAmount : null,
            change:
              invoiceData.paymentMode === "Cash" ? calculateChange() : null,
            vat: calculateVat(),
            discount: discountVal ? discountVal.toFixed(2) : 0,
            // rates: rates,
            currency: currency,
            // dueDate: selectedDate,
            invoiceNumber: invoiceData.invoiceNumber,
            client: client ? client : defaultClient,
            // supplier,
            // signatureDataUrl,
            // type: type,

            // paymentRecords: [],
            creator: [user?.result?._id || user?.result?.googleId],
            createdAt: new Date(),
            isPrintBill: true,
          },
          staffCommissionDetailsArray,
          history
        )
      );

      dispatch(
        createStock(stockDetailsItems(cartItems, invoiceData.invoiceNumber))
      );
    }

    setInvoiceData(initialState);
  };
  const handleButton = () => {
    console.log("cartItems", cartItems);

    // const updatedCartItems = cartItems.map(item => {
    //   const quantity = parseFloat(item.quantity);
    //   const initialQty = parseFloat(item.stockQty.initialQty);

    //   // Check if the values are valid numbers before subtraction
    //   if (!isNaN(quantity) && !isNaN(initialQty)) {
    //     return {
    //       ...item,
    //       stockQty: {
    //         ...item.stockQty,
    //         initialQty: initialQty - quantity,
    //       },
    //     };
    //   } else {
    //     // Handle the case where the values are not valid numbers
    //     console.error('Invalid quantity or initialQty:', item);
    //     // You can choose to set a default value or skip updating the item
    //     return item; // or return a default value
    //   }
    // });
    // console.log("updated Items",updatedCartItems)
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "paymentMode") {
      setInvoiceData({
        ...invoiceData,
        [name]: value,
      });
      if (value === "Cash") {
        setOpenBillDialog(true);
      }
      if (value === "Card") {
        setOpenCardDialog(true);
      }
      // Check if "Cash" is selected and set openCashDialog accordingly
    }
  };
  const handleCardPayment = (updatedCardData) => {
    setInvoiceData({ ...invoiceData, card: updatedCardData });
  };
  const CustomPaper = (props) => {
    return <Paper elevation={3} {...props} />;
  };
  const clientsProps = {
    options: clients,
    getOptionLabel: (option) => option.name,
  };
  return (
    <>
      <AddClient setOpen={setOpen} open={open} />
      <Card className={classes.card} elevation={1}>
        <CardContent>
          {/* <IconButton className={classes.backButton} onClick={()=>setOpenBillDialog(!openBillDialog)}>
          <ArrowBack />
        </IconButton> */}
          {/* Your existing form elements */}
          {/* ... */}

          <form onSubmit={handleSubmit}>
            {!openBillDialog && !openCardDialog && (
              <>
                <Container>
                  {client && (
                    <>
                      <ClientInfoCard client={client} setClient={setClient} />
                    </>
                  )}
                  <div
                    style={client ? { display: "none" } : { display: "block" }}
                  >
                    <Autocomplete
                      {...clientsProps}
                      PaperComponent={CustomPaper}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // required={!invoice && true}
                          label="Select Customer"
                          margin="normal"
                          variant="outlined"
                          style={{ minWidth: 200 }}
                        />
                      )}
                      value={clients?.name}
                      onChange={(event, value) => {
                        setClient(value);
                      }}
                      // onChange={(event, value) => setClient(value) }
                    />
                  </div>
                  {!client && (
                    <>
                      <div style={{ paddingBottom: "10px" }}>
                        <Chip
                          avatar={
                            <Avatar
                              style={{
                                backgroundColor: "#ef7f1a",
                                color: "white",
                              }}
                            >
                              +
                            </Avatar>
                          }
                          label="New Customer"
                          onClick={() => setOpen(true)}
                          variant="outlined"
                        />
                      </div>
                    </>
                  )}
                </Container>
                {!invoice && (
                  <Container>
                    <PaymentOptions
                      invoiceData={invoiceData}
                      handleInputChange={handleInputChange}
                    />
                  </Container>
                )}
              </>
            )}
            {openBillDialog && (
              <BillDialog
                currency={currency}
                setOpenBillDialog={setOpenBillDialog}
              />
            )}
            {openCardDialog && (
              <CardDialog
                handleCardPayment={handleCardPayment}
                invoiceData={invoiceData}
                currency={currency}
                setOpenCardDialog={setOpenCardDialog}
              />
            )}
            <div className={classes.saveButton}>
              <Button
                variant="contained"
                style={{
                  justifyContent: "center",
                  backgroundColor: "#ef7f1a",
                  color: "white",
                }}
                type="submit"
                color="primary"
                size="large"
                startIcon={<SaveIcon />}
              >
                {invoice ? "UPDATE" : "Pay Now"}
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </>
  );
};

export default PaymentDetails;
