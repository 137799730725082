import React from "react";
import {
  Typography,
  Button,
  IconButton,
  ButtonGroup,
  Modal,
  TextField,
  Divider,
} from "@material-ui/core";
import { useDiscount } from "../Context/DiscountContext";
import { useCart } from "../Context/CartContext";
import ClearIcon from "@material-ui/icons/Clear";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import useStyles from "./CartStyles";

const CartSummary = ({ currency }) => {
    const classes=useStyles();
    const {
        discountType,
        setDiscountType,
        discountVal,
        setDiscountVal,
        discountFlag,
        setDiscountFlag,
        openDiscountbox,
        setOpenDiscountbox,
        handleDiscountTypeChange,
        handleDisountBoxOpen,
        handleDisountBoxClose,
        handleAddDiscount,
        calculateDiscount,
        clearDiscount,
      } = useDiscount();
      const {
        cartItems,
        selectedIndex,
        addToCart,
        removeFromCart,
        incrementQuantity,
        decrementQuantity,
        getTotalPrice,
        getSubTotalPrice,
        calculatePrice,
        handleOpenQtyDialog,
        handleCloseQtyDialog,
        QtydialogOpen,
        handleQuantityChange,
        calculateVat,
        subTotal,
        setSubTotal,
        setCartItems,
        vat,
        setVat,
      } = useCart();

      const isError =
      (discountType && discountVal > 100) || discountVal >= getSubTotalPrice();
  return (
    <div className={classes.totalAmountSection}>
      <Typography
        variant="body1"
        color="initial"
        className={classes.totalAmountText}
      >
        Sub-Total:{" "}
        <span className={classes.amountValue}>
          {`${currency}${getSubTotalPrice().toFixed(2)}`}
        </span>
      </Typography>
      <Typography
        variant="body1"
        color="initial"
        className={classes.totalAmountText}
      >
        Discount:{" "}
        <span className={classes.amountValue}>
          {discountVal && discountFlag && cartItems.length ? (
            <>
              <span>
                <Button
                  variant="contained"
                  color="lightGrey"
                  className={classes.button}
                  endIcon={<ClearIcon />}
                  onClick={clearDiscount}
                >
                  {currency}
                  {discountVal.toFixed(2)}
                </Button>
              </span>
            </>
          ) : (
            <IconButton
              disabled={cartItems.length === 0}
              aria-label="add"
              onClick={handleDisountBoxOpen}
            >
              <AddIcon fontSize="medium" />
            </IconButton>
          )}
        </span>
      </Typography>

      <Modal
        open={openDiscountbox}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleDisountBoxClose();
          }
        }}
      >
        <div className={classes.modal}>
          <TextField
            error={isError}
            helperText={isError ? "Incorrect entry." : null}
            type="number"
            label="Discount"
            value={discountVal}
            onChange={(e) => {
              setDiscountVal(e.target.value);
            }}
            // Add your input field props here
          />
          <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
            style={{ marginLeft: 10 }}
          >
            <Button
              onClick={handleDiscountTypeChange}
              color={discountType === false ? "primary" : "default"}
            >
              Flat
            </Button>
            <Button
              onClick={handleDiscountTypeChange}
              color={discountType ? "primary" : "default"}
            >
              %
            </Button>
          </ButtonGroup>
          <div className={classes.modalButtons}>
            <Button
              variant="contained"
              color="primary"
              disabled={isError}
              onClick={() => {
                handleAddDiscount(getSubTotalPrice().toFixed(2));
              }}
              className={classes.AddButtonModal}
            >
              Add
            </Button>
            <IconButton
              aria-label="clear"
              onClick={handleDisountBoxClose}
              style={{ marginTop: "auto" }}
            >
              <ClearIcon />
            </IconButton>
          </div>
        </div>
      </Modal>
      <Typography
        variant="body1"
        color="initial"
        className={classes.totalAmountText}
      >
        Vat:{" "}
        <span className={classes.amountValue}>
          {`${currency}${calculateVat().toFixed(2)}`}
        </span>
      </Typography>
      <Divider />
      <Typography
        variant="h5"
        color="initial"
        className={classes.totalAmountText}
        style={{ marginTop: "10px" }}
      >
        Total Amount:{" "}
        <span className={classes.amountValue}>
          {`${currency}${getTotalPrice().toFixed(2) || 0.0}`}
        </span>
      </Typography>
    </div>
  );
};

export default CartSummary;
