import React, { useState, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {Avatar} from "@material-ui/core";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useParams } from "react-router-dom";
import moment from "moment";
import { AppContext } from "../Context/AppContext";
import { EditContext } from "../Context/EditContext";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { StatsContext } from "../Context/StatsContext";
import {
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Drawer,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { useSnackbar } from "react-simple-snackbar";
import { getInvoices, deleteInvoice } from "../actions/invoiceActions";
import { getMeetings, deleteMeeting } from "../actions/meetingActions";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  getProfile,
  getProfiles,
  updateProfile,
  deleteProfile,
} from "../actions/profile";
import { getUsers } from "../actions/auth";
import EditProfileForm from "./editProfileForm";
import ProfileDetail from "../components/Settings/Form/Profile";
import MeetingForm from "../components/Meetings/MeetingForm";
import NoData from "../components/svgIcons/NoData";
import TextField from "@material-ui/core/TextField";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    height: "100%",
  },
  textMargin: {
    marginLeft: theme.spacing(17),
  },
  buttonGroup: {
    "& > *": {
      marginLeft: theme.spacing(8),
      width: "85%",
    },
  },
  drawer: {
    width: 300,
  },
  drawerContent: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3),
    height: "100%",
    backgroundColor: theme.palette.background.default,
  },
  drawerPaper: {
    width: 300,
  },
  tableContainer: {
    marginBottom: theme.spacing(2),
  },
  searchBar: {
    marginBottom: theme.spacing(2),
  },
  userProfileHeading: {
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    color: "black",
  },
  openDrawerButton: {
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1),
    },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(1),
      marginLeft: "10rem",
      cursor: "pointer"
    },
  },
  profileInfo: {
    marginLeft: "1rem",
    marginBottom: theme.spacing(1),
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  noDataContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: "20px",
    margin: "80px",
  },
  noDataIcon: {
    width: "100px",
    height: "100px",
  },
  noDataText: {
    padding: "40px",
    color: "gray",
    textAlign: "center",
  },
  editButton: {
    width: "100px",
    marginLeft: "28px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "28px",
      width: "80%",
    },
  },
  gridContainer: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: "10rem", // Apply left margin of 10rem on screens larger than or equal to small (sm)
    },
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  buttonContainer: {
    marginTop: "1rem",
  },
}));
const tableStyle = {
  width: 160,
  fontSize: 14,
  cursor: "pointer",
  borderBottom: "none",
  padding: "8px",
  textAlign: "center",
};
const headerStyle = { borderBottom: "none", textAlign: "center" };

const Stats = () => {
  const classes = useStyles();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const { currStat, setCurrStat } = useContext(StatsContext);
  const history = useHistory();
  const { userId } = useParams();
  //("userId", userId);
  const { profiles } = useSelector((state) => state.profiles);
  //("profiles", profiles);
  const [clientDetails, setClientDetails] = useState(null);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [updatedProfile, setUpdatedProfile] = useState(null);
  const [profile, setProfile] = useState([]);
  const { users } = useSelector((state) => state.auth);
  //("users", users);
  const dispatch = useDispatch();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openDrawerDialog, setOpenDrawerDialog] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const invoices = useSelector((state) => state.invoices.invoices);
  //("invoices", invoices);
  const { meetings } = useSelector((state) => state.meetings);
  //(meetings);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const isLoading = useSelector((state) => state.meetings.isLoading);
  const { showForm, setShowForm } = useContext(AppContext);
  const { currId, setCurrId } = useContext(EditContext);
  const [searchQuery, setSearchQuery] = useState("");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  // Simulating fetching client details based on the client ID

  useEffect(() => dispatch(getInvoices()), [location, dispatch, userId]);
  useEffect(() => dispatch(getMeetings()), [location, dispatch]);
  useEffect(() => {
    dispatch(getUsers());
    dispatch(getProfiles());
  }, [dispatch, userId]);
  useEffect(() => {
    const displayProfile = profiles.find(
      (profile) => profile.userId[0] === userId
    );
    //("displayProfile", displayProfile);
    setProfile(displayProfile);
  });
  useEffect(() => {
    const filteredInvoices = invoices.filter(
      (inv) => inv.creator[0] === userId
    );
    //("filtered", filteredInvoices);
    setFilteredInvoices(filteredInvoices);

    const filteredRep = meetings.filter((rep) => rep.creator[0] === userId);
    //("filtered Reports", filteredRep);
    setFilteredReports(filteredRep);
  }, [userId, invoices, meetings, location]);
  //
  const toCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  function checkStatus(status) {
    return status === "Partial"
      ? {
          border: "solid 0px #1976d2",
          backgroundColor: "#baddff",
          padding: "8px 10px",
          borderRadius: "20px",
          margin: "10px",
        }
      : status === "Paid"
      ? {
          border: "solid 0px green",
          backgroundColor: "#a5ffcd",
          padding: "8px 18px",
          borderRadius: "20px",
        }
      : status === "Unpaid"
      ? {
          color: "#ff0000",
          width: 160,
          fontSize: 14,
          cursor: "pointer",
          borderBottom: "none",
          padding: "8px",
          textAlign: "center",
        }
      : "red";
  }
  const handleViewInvoice = (id) => {
    history.push(`/invoice/${id}`);
  };
  const handleEditInvoice = (id) => {
    history.push(`/edit/invoice/${id}`);
  };
  // const handleDeleteInvoice = (invoice) => {};

  const findUserNameById = (userId) => {
    //("parameter" + userId);
    const user = users.find((user) => user._id == userId);
    //(user);
    return user ? user.name : "";
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const handleOpenDialog = (profile_Id) => {
    setSelectedProfile(profile_Id);
    // dispatch(getProfile(profile_Id));
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseForm = () => {
    setShowForm(false);
  };
  const handleUpdateProfile = (formData) => {
    dispatch(updateProfile(selectedProfile, formData, openSnackbar));

    setOpenDialog(false);
    setUpdatedProfile(formData);
  };
  const handleInputChange = (value) => {
    //(value);
    setCurrId(value);
    setShowForm(true);
  };
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const filteredRows = filteredInvoices.filter((row) =>
    row.client.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const filteredReportsRows = filteredReports.filter((row) =>
    row.client.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  //
  return (
    <>
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <IconButton
            aria-label=""
            onClick={handleOpenDrawer}
            className={classes.openDrawerButton}
          >
            <AccountCircleIcon style={{ fontSize: 40 }} />
          </IconButton> */}
          <Avatar
         
         onClick={handleOpenDrawer}
          className={classes.openDrawerButton}
          src={profile?.logo}
          alt=""
        />
          <Typography
            variant="h6"
            onClick={handleOpenDrawer}
            style={{
              fontWeight: "bold",
              color: "#333",
              marginLeft: "10px",
              cursor:"pointer"
            }}
           
          >
          {profile.name}
          </Typography>
        </div>
        <Drawer
          className={classes.drawer}
          variant="temporary"
          anchor="left"
          open={openDrawer}
          onClose={handleCloseDrawer}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {/* ... */}
          <div className={classes.drawerContent}>
            <Paper elevation={3} className={classes.paper}>
              <div className={classes.contentContainer}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.userProfileHeading}
                >
                  User Profile
                </Typography>
                {profile ? (
                  <div>
                    <ProfileDetail profiles={updatedProfile || profile} />
                    <Button
                      variant="outlined"
                      color="primary"
                      startIcon={<EditIcon />}
                      onClick={() => handleOpenDialog(profile._id)}
                      className={classes.editButton}
                    >
                      Edit
                    </Button>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.profileInfo}
                    >
                      Total Orders: {filteredInvoices.length}
                    </Typography>
                    <Typography
                      variant="h6"
                      gutterBottom
                      className={classes.profileInfo}
                    >
                      Total Reports: {filteredReports.length}
                    </Typography>

                    <Dialog
                      open={openDialog}
                      onClose={handleCloseDialog}
                      maxWidth="sm"
                    >
                      <DialogTitle>Edit Profile</DialogTitle>
                      <DialogContent className={classes.dialogContent}>
                        {profiles &&
                          profiles.map((profile) => {
                            if (profile._id === selectedProfile) {
                              return (
                                <EditProfileForm
                                  key={profile._id}
                                  profile={profile}
                                  onUpdateProfile={handleUpdateProfile}
                                  onCloseDialog={handleCloseDialog}
                                />
                              );
                            }
                          })}
                      </DialogContent>
                    </Dialog>
                  </div>
                ) : (
                  <Typography variant="body1" className={classes.textMargin}>
                    Loading User details...
                  </Typography>
                )}
              </div>
            </Paper>
          </div>
        </Drawer>
      </div>

      {/* Invoice and Reports Tabs */}
      <Grid
        item
        xs={12}
        sm={10}
        className={isMobile ? "" : classes.gridContainer}
      >
        <AppBar position="static" color="default">
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="Order Details" />
            <Tab label="Daily Reports" />
          </Tabs>
        </AppBar>

        {/* Tab Panel */}
        <Box p={3}>
          <TextField
            id="search-bar"
            label="Search Bar"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />

          {currentTab === 0 && filteredInvoices.length === 0 && (
            // Display no data message for "Daily Reports" tab
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: "20px",
                margin: "80px",
              }}
            >
              <NoData />
              <p
                style={{ padding: "40px", color: "gray", textAlign: "center" }}
              >
                No Orders for the selected Sales-Person
              </p>
            </div>
          )}
          {currentTab === 0 && filteredInvoices.length >0 &&(
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={headerStyle}>Number</TableCell>
                    <TableCell style={headerStyle}>Party Name</TableCell>
                    <TableCell style={headerStyle}>MRP Value</TableCell>
                    <TableCell style={headerStyle}>Due Date</TableCell>
                    {/* <TableCell style={headerStyle}>Status</TableCell> */}
                    <TableCell style={headerStyle}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredRows.map((invoice) => (
                    <TableRow key={invoice._id}>
                      <TableCell style={tableStyle}>
                        {invoice.invoiceNumber}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {invoice.client.name}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {invoice.total
                          ? `₹${invoice.total
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : invoice.total}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {moment(invoice.dueDate).fromNow()}{" "}
                      </TableCell>
                      {/* <TableCell style={checkStatus(invoice.status)}>
                        {invoice.status}
                      </TableCell> */}

                      <TableCell style={tableStyle}>
                        {" "}
                        <IconButton
                          onClick={() => handleViewInvoice(invoice._id)}
                        >
                          <RemoveRedEyeOutlinedIcon
                            style={{ width: "15px", height: "15px" }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => handleEditInvoice(invoice._id)}
                        >
                          <BorderColorIcon
                            style={{ width: "15px", height: "15px" }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            deleteInvoice(invoice._id, openSnackbar)
                          }
                        >
                          <DeleteOutlineRoundedIcon
                            style={{ width: "15px", height: "15px" }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {currentTab === 1 && filteredReports.length === 0 && (
            // Display no data message for "Daily Reports" tab
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: "20px",
                margin: "80px",
              }}
            >
              <NoData />
              <p
                style={{ padding: "40px", color: "gray", textAlign: "center" }}
              >
                No reports for the selected Sales-Person
              </p>
            </div>
          )}
          {currentTab === 1 && filteredReports.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={headerStyle}>Date</TableCell>
                    <TableCell style={headerStyle}>Time</TableCell>
                    <TableCell style={headerStyle}>Party Name</TableCell>
                    <TableCell style={headerStyle}>Order Received</TableCell>
                    <TableCell style={headerStyle}>Order (MRP Value)</TableCell>
                    <TableCell style={headerStyle}>Payment Received</TableCell>
                    <TableCell style={headerStyle}>Advance(Cash)</TableCell>
                    <TableCell style={headerStyle}>Advance(Cheque)</TableCell>
                    <TableCell style={headerStyle}>Remarks</TableCell>
                    <TableCell style={headerStyle}>Edit</TableCell>
                    <TableCell style={headerStyle}>Delete</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredReportsRows.map((row) => (
                    <TableRow key={row._id}>
                      {/* <TableCell style={tableStyle}>{row.client.name}</TableCell> */}
                      {/* <TableCell style={tableStyle}>
                     {row.client.address}
                   </TableCell> */}
                      <TableCell style={tableStyle}>
                        {moment(row.date).format("MMM Do YYYY")}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {moment(row.time).format(" h:mm:ss a")}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {row.client.name}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {row.orderRecieved}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {row.orderRupees && row.orderRupees
                          ? `₹${row.orderRupees
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : ""}
                      </TableCell>
                      <TableCell style={tableStyle}>{row.meetingType}</TableCell>
                      <TableCell style={tableStyle}>
                        {" "}
                        {row.advance && row.advance.cash
                          ? `₹${row.advance.cash
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : ""}
                      </TableCell>
                      <TableCell style={tableStyle}>
                        {row.advance && row.advance.cheque
                          ? `₹${row.advance.cheque
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : ""}
                      </TableCell>
                      <TableCell style={tableStyle}>{row.remarks}</TableCell>

                      <TableCell style={{ ...tableStyle, width: "10px" }}>
                        <IconButton onClick={() => handleInputChange(row._id)}>
                          <BorderColorIcon
                            style={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      </TableCell>
                      <TableCell style={{ ...tableStyle, width: "10px" }}>
                        <IconButton
                          onClick={
                            () => dispatch(deleteMeeting(row._id, openSnackbar))
                            // alert("Report deleted")
                          }
                        >
                          <DeleteOutlineRoundedIcon
                            style={{ width: "20px", height: "20px" }}
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Dialog open={showForm} onClose={handleCloseForm}>
            <DialogContent>
              <MeetingForm
                onClose={handleCloseForm}
                // onMeetingSubmit={handleMeetingSubmit}
                // currId={currId}
                // setCurrId={setCurrId}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseForm}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Grid>
    </>
  );
};

export default Stats;
