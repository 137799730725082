import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import axios from "axios";
import { saveAs } from "file-saver";
import moment from "moment";
import {
  createCategory,
  getAllCategories,
  deleteCategory,
} from "../actions/categoryActions";
import {
  useMediaQuery,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton,
  Container,
  TextField,
  CircularProgress,
  FormControl,
  makeStyles,
  useTheme,
  createTheme,
  ThemeProvider,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import ClearIcon from "@material-ui/icons/Clear";
// import { getAllClients } from "../actions/clientActions";
import { getAllItems, getItemsByUser } from "../actions/itemActions";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import Autocomplete from "@material-ui/lab/Autocomplete";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { useLocation } from "react-router-dom";

import ProgressButton from "react-progress-button";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  deleteInvoice,
  getInvoicesByUser,
  getInvoices,
} from "../actions/invoiceActions";
import { getUsers } from "../actions/auth";
import NoData from "../components/svgIcons/NoData";
import Spinner from "../components/Spinner/Spinner";
import { useSnackbar } from "react-simple-snackbar";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import GetAppIcon from "@material-ui/icons/GetApp";
import { getAllStocks } from "../actions/stockActions";
import { TrendingUpRounded } from "@material-ui/icons";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 500,
  },

  tablecell: {
    fontSize: "16px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "20px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  typography: {
    fontSize: "18px",
    fontWeight: 500,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
  },
}));

const tableStyle = {
  width: 160,
  fontSize: 14,

  borderBottom: "none",
  padding: "8px",
  textAlign: "center",
};
const headerStyle = { borderBottom: "none", textAlign: "center" };
const colorTheme = createTheme({
  palette: {
    primary: {
      main: "#ef7f1a",
    },
  },
});

const StockDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme();
  // const [tableData,setTableData] = useState([{
  //   category:"",
  //   itemId:"",
  //   itemName:"",
  //   openingStock:0,
  //   purchaseQty:0,
  //   saleQty:0,
  //   totalStock:0,
  //   unit:""
  // }])
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [downloadStatus, setDownloadStatus] = useState(null);
  const user = JSON.parse(localStorage.getItem("profile"));
  const stocks = useSelector((state) => state.stocks.stocks);
  const { items } = useSelector((state) => state.items);
  const { categories } = useSelector((state) => state.categories);
  // //(invoices);
  const [rows, setRows] = useState([]);
  const { users } = useSelector((state) => state.auth);
  // const clients = useSelector((state) => state.clients.clients);
  // // //(users);
  // //(rows);
  const [loading, setLoading] = useState(false);
  const isLoading = useSelector((state) => state.stocks.isLoading);
  const [searchQuery, setSearchQuery] = useState("");
  const [salesperson, setSalesperson] = useState(null);
  // const [party, setParty] = useState(null);
  const [category, setCategory] = useState(null);
  const [paymentMode, setPaymentMode] = useState("");
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("");

  // const rows = []

  // useEffect(() => {
  //     dispatch(getInvoices());
  // }, [dispatch]);

  //   useEffect(() => {
  //     dispatch(getInvoices());

  //     // eslint-disable-next-line
  //   }, [location, dispatch]);
  useEffect(() => {
    dispatch(getAllStocks());

    // eslint-disable-next-line
  }, [location, dispatch]);

  useEffect(() => {
    dispatch(getAllItems());
  }, [location, dispatch]);
  useEffect(() => {
    if (user?.result?.userType === "user") {
      const rows = stocks.filter((inv) => inv.creator[0] === user?.result?._id);
      setRows(rows);
    } else {
      // setRows(stocks);
      setRows(items);
    }

    // eslint-disable-next-line
  }, [items]);

  useEffect(() => {
    dispatch(getUsers());
  }, [location]);

  useEffect(() => {
    if (!categories.length) {
      dispatch(getAllCategories());
    }
  });
  const toCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const findUserNameById = (userId) => {
    // //("parameter" + userId);
    const user = users.find((user) => user._id == userId);
    // //(user);
    return user ? user.name : "";
  };

  const filterByDateRange = (invoice) => {
    if (!startDate || !endDate) return true;
    const stockDate = moment(invoice.createdAt);
    return stockDate.isBetween(startDate, endDate, "day", "[]");
  };
  const filterByEndDate = (invoice) => {
    if (!startDate || !endDate) return true;

    const stockDate = moment(invoice.createdAt);
    const isBeforeEndDate = stockDate.isSameOrBefore(endDate, "day");

    return isBeforeEndDate;
  };

  const filterByDateBeforeEndDate = (invoice) => {
    if (!startDate || !endDate) return true;

    const stockDate = moment(invoice.createdAt);
    const isBeforeEndDate = stockDate.isBefore(endDate, "day");

    return isBeforeEndDate;
  };
  const handleCategoryName = (categoryId) => {
    const category = categories.filter((c) => c._id === categoryId);
    return category[0].categoryName;
  };
   const filteredRows = rows.filter((row) =>row.toggleStock);
  
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value); // Update the selected filter when the user changes the option
  };
  const editInvoice = (id) => {
    history.push(`/edit/invoice/${id}`);
  };
  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this client?"
    );
    if (confirmDelete) {
      dispatch(deleteInvoice(id, openSnackbar));
    }
  };


  const calculatePurchaseQty = (rowId) => {
    // Filter stocks based on rowId and purchaseId existence
    const filteredStocks = stocks.filter(
      (stock) =>
        stock.itemId === rowId &&
        stock.hasOwnProperty("purchaseId") &&
        filterByDateRange(stock)
    );

    // Calculate sum of itemInQty for matching stocks
    const totalPurchaseQty = filteredStocks.reduce(
      (sum, stock) => sum + stock.qty,
      0
    );

    return totalPurchaseQty;
  };
  const calculateSalesQty = (rowId) => {
    // Filter stocks based on rowId and purchaseId existence
    const filteredStocks = stocks.filter(
      (stock) =>
        stock.itemId === rowId &&
        stock.hasOwnProperty("invoiceId") &&
        filterByDateRange(stock)
    );

    // Calculate sum of itemInQty for matching stocks
    const totalSalesQty = filteredStocks.reduce(
      (sum, stock) => sum + -1 * stock.qty,
      0
    );

    return totalSalesQty;
  };
  const calculateAvailableStock = (rowId) => {
    const filteredStocks = stocks.filter(
      (stock) => stock.itemId === rowId && filterByEndDate(stock)
    );

    const availableStockQty = filteredStocks.reduce((total, stock) => {
      return total + stock.qty;
    }, 0);

    return availableStockQty;
  };

  const calculateOpeningStock = (rowId, row) => {
    const filteredStocks = stocks.filter(
      (stock) => stock.itemId === rowId && filterByDateBeforeEndDate(stock)
    );

    const availableStockQty = filteredStocks.reduce((total, stock) => {
      return total + stock.qty;
    }, 0);

    return availableStockQty ? availableStockQty : row.stockQty.initialQty;
  };
  const calculateStockAdjustmentAddQty = (rowId) => {
    const filteredStocks = stocks.filter(
      (stock) =>
        stock.itemId === rowId &&
        stock.hasOwnProperty("stockAdjustmentId") &&
        stock.qty > 0 &&
        filterByDateRange(stock)
    );

    // Calculate sum of itemInQty for matching stocks
    const addQty = filteredStocks.reduce((sum, stock) => sum + stock.qty, 0);

    return addQty;
  };
  const calculateStockAdjustmentLessQty = (rowId) => {
    const filteredStocks = stocks.filter(
      (stock) =>
        stock.itemId === rowId &&
        stock.hasOwnProperty("stockAdjustmentId") &&
        stock.qty < 0 &&
        filterByDateRange(stock)
    );

    // Calculate sum of itemInQty for matching stocks
    const lessQty = filteredStocks.reduce((sum, stock) => sum + stock.qty, 0);

    return lessQty;
  };
  const createAndDownloadPdf = () => {
    //(rows);

    setLoading(true);
    if (filteredRows.length) {
      setDownloadStatus("loading");
      axios
        .post(`${process.env.REACT_APP_API}/create-stock-summary-report`, {
          // filteredRows,
          // stocks,
          startDate:moment(startDate).format(" Do MMM YYYY"),
       
           endDate:moment(endDate).format(" Do MMM YYYY"),
          // users,
          stockDetailsArray
        })
        .then(() =>
          axios.get(`${process.env.REACT_APP_API}/fetch-stock-summary-report`, {
            responseType: "blob",
          })
        )
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          // const downloadUrl = window.URL.createObjectURL(pdfBlob);
          // setDownloadStatus("success");
          // window.open(downloadUrl, "_blank");
          const currentDate = new Date();

          saveAs(pdfBlob, `OrderReport${currentDate}.pdf`);
          const downloadUrl = `https://posstore.mindtree.co.th/files/Exported-Stock-Summary-Reports/stocksummaryreport.pdf`;
          window.open(downloadUrl, "_blank");
          setLoading(false);
          setDownloadStatus("success");
        });
    } else {
      openSnackbar("No record to Export");
    }
  };
  
  // const createAndDownloadPdf = async () => {
  //   //(rows);

  //   setLoading(true);
  //   try {
  //     const requestBody = {
  //       stockDetailsArray
  //       /* Your request body data here */
  //     };

  //     const response = await axios.post(`${process.env.REACT_APP_API}/create-stock-summary-report`, requestBody, {
  //       responseType: 'arraybuffer', // Response type as arraybuffer to handle binary response
  //     });

  //     const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
  //     const url = window.URL.createObjectURL(pdfBlob);
  //     window.open(url);
  //     setLoading(false);
  //    setDownloadStatus("success");
  //   } catch (error) {
  //     console.error('Error generating PDF:', error);
  //     // Handle error
  //   }
  // };
  const handleClientNameClick = (id) => {
    history.push(`/stats/${id}`);
  };
  if (!user) {
    history.push("/login");
  }

  const handleStartDateChange = (date) => {
    // const formattedDate = date.toISOString().split("T")[0];
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    // const formattedDate = date.toISOString().split("T")[0];
    setEndDate(date);
  };
  // useEffect(() => {
  //   // Calculate and update tableData based on filteredRows
  //   const updatedTableData = filteredRows.map((row) => ({
  //     category: row.category.categoryName,
  //     itemId: row._id,
  //     itemName: row.itemName,
  //     openingStock: calculateOpeningStock(row._id) || 0,
  //     purchaseQty: calculatePurchaseQty(row._id)||0,
  //     saleQty: calculateSalesQty(row._id)||0,
  //     totalStock: calculateTotalStock(row._id)||0,
  //     unit: row.unit,
  //   }));

  //   setTableData(updatedTableData);
  // }, [filteredRows]);
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (rows.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
          margin: "80px",
        }}
      >
        <NoData />
        <p style={{ padding: "40px", color: "gray", textAlign: "center" }}>
          No transaction yet
        </p>
      </div>
    );
  }
  const categoryProps = {
    options: categories,
    getOptionLabel: (option) => option.categoryName,
  };
  //Group Rows category wise
  const groupedRows = {};
  filteredRows.forEach((row) => {
    const categoryId = row.category.categoryId;
    
  
    // Check if the category name is not "Services" before pushing
    
      if (!groupedRows[categoryId]) {
        groupedRows[categoryId] = [];
      }
      groupedRows[categoryId].push(row);
    
  });
  

  const stockDetailsArray = [];

  // Function to create and add stock details object to the array
  const createStockDetailsObject = (row) => {
    const stockDetailsObject = {
      categoryName: handleCategoryName(row.category.categoryId),
      itemName: row.itemName,
      openingStock: calculateOpeningStock(row._id, row) || 0,
      purchaseQty: calculatePurchaseQty(row._id) || 0,
      stockAdjustmentAddQty: calculateStockAdjustmentAddQty(row._id) || 0,
      stockAdjustmentLessQty: calculateStockAdjustmentLessQty(row._id) || 0,
      saleQty: calculateSalesQty(row._id) || 0,
      availableStock: calculateAvailableStock(row._id) || 0,
      unit: row.unit,
    };
  
    stockDetailsArray.push(stockDetailsObject);
  };
  filteredRows.forEach((row) => {
    createStockDetailsObject(row);
  });
 
  return (
    <div
      style={{
        minHeight: isMobile ? "740px" : "470px",
      }}
    >
      <ThemeProvider theme={colorTheme}>
        <Container
          style={{
            width: isMobile ? "100%" : "85%",
            paddingTop: "2px",
            paddingBottom: "2px",
            border: "none",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              gap: "10px",
              backgroundColor: "rgba(255, 255, 255)",
              margin: "5px",
              padding: "2px 10px",
              borderRadius: "2px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  label="From"
                  name="FromDate"
                  value={startDate}
                  onChange={handleStartDateChange}
                  required
                  format="yyyy/MM/dd"
                  style={{ maxWidth: isMobile ? "50%" : "100%" }}
                />

                <KeyboardDatePicker
                  label="To"
                  name="EndDate"
                  value={endDate}
                  onChange={handleEndDateChange}
                  required
                  format="yyyy/MM/dd"
                  style={{ maxWidth: "50%" }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              {/* {user?.result?.userType === "admin" && (
                <FormControl style={{ width: "100px" }}>
                  <InputLabel>Filter:</InputLabel>
                  <Select
                    value={selectedFilter}
                    onChange={(event) => handleFilterChange(event)} // Provide the event object
                  >
                    <MenuItem value="category">Category</MenuItem>
                  </Select>
                  {selectedFilter && (
                    <IconButton
                      size="small"
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "transparent",

                        color: "#ef7f1a",
                        borderRadius: "4px",
                      }}
                      onClick={() => {
                        setSelectedFilter("");
                        setSearchQuery("");
                        setCategory("");
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </FormControl>
              )}
              {selectedFilter && (
                <div>
              
                  {selectedFilter === "category" ? (
                    <ThemeProvider theme={colorTheme}>
                      <Container>
                        {category && (
                          <div
                            style={{
                              display: "flex",
                              marginTop: "20px",
                              gap: "10px",
                              height: "50px",
                            }}
                          >
                            <Typography variant="subtitle1" gutterBottom>
                              {category.categoryName}
                            </Typography>

                            <Button
                              color="primary"
                              size="small"
                              style={{
                                textTransform: "none",
                                backgroundColor: "#ef7f1a",
                                color: "white",
                              }}
                              onClick={() => {
                                setCategory(null);
                                setSearchQuery("");
                              }}
                            >
                              Change
                            </Button>
                          </div>
                        )}
                        <div
                          style={
                            category
                              ? { display: "none" }
                              : { display: "block", width: "200px" }
                          }
                        >
                          <Autocomplete
                            {...categoryProps}
                            // PaperComponent={CustomPaper}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // required={!invoice && true}
                                label="Select Category"
                                margin="normal"
                                variant="outlined"
                                // error={touched && !!errors.client}
                                // helperText={touched && errors.client}
                              />
                            )}
                            value={categories?.categoryName}
                            onChange={(event, value) => {
                              setCategory(value);
                              setSearchQuery(value ? value.name : "");

                              // handleClientChange();
                            }}
                            // onChange={(event, value) => setClient(value) }
                          />
                        </div>
                      </Container>
                    </ThemeProvider>
                  ) : null}
                </div>
              )} */}
              {user?.result?.userType !== "admin" && (
                <ThemeProvider theme={colorTheme}>
                  <Container>
                    {category && (
                      <div
                        style={{
                          display: "flex",
                          marginTop: "20px",
                          gap: "10px",
                          height: "50px",
                        }}
                      >
                        <Typography variant="subtitle1" gutterBottom>
                          {category.categoryName}
                        </Typography>

                        <Button
                          color="primary"
                          size="small"
                          style={{
                            textTransform: "none",
                            backgroundColor: "#ef7f1a",
                            color: "white",
                          }}
                          onClick={() => {
                            setCategory(null);
                            setSearchQuery("");
                          }}
                        >
                          Change
                        </Button>
                      </div>
                    )}
                    <div
                      style={
                        category
                          ? { display: "none" }
                          : { display: "block", width: "200px" }
                      }
                    >
                      <Autocomplete
                        {...categoryProps}
                        // PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // required={!invoice && true}
                            label="Select Category"
                            margin="normal"
                            variant="outlined"
                            // error={touched && !!errors.client}
                            // helperText={touched && errors.client}
                          />
                        )}
                        value={categories?.categoryName}
                        onChange={(event, value) => {
                          setCategory(value);
                          setSearchQuery(value ? value.name : "");

                          // handleClientChange();
                        }}
                        // onChange={(event, value) => setClient(value) }
                      />
                    </div>
                  </Container>
                </ThemeProvider>
              )}

              {loading ? (
                <CircularProgress
                  style={{ marginTop: "20px", marginLeft: "10px" }}
                />
              ) : (
                user?.result?.userType === "admin" && (
                  <Button
                  disabled={!endDate}
                    variant="outlined"
                    endIcon={<GetAppIcon />}
                    onClick={createAndDownloadPdf}
                    style={{
                      margin: "16px 0 8px 0",
                      height: "50px",
                    }}
                  >
                    PDF
                  </Button>
                )
              )}
            </div>
          </div>
          {startDate && endDate && (
            <TableContainer component={Paper} elevation={0}>
              <Table
                className={classes.table}
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={headerStyle}>CATEGORY & ITEM</TableCell>
                    <TableCell style={headerStyle}>
                      OPENING STOCK(TODAY'S)
                    </TableCell>
                    <TableCell style={headerStyle}>PURCHASE QTY</TableCell>
                    <TableCell style={headerStyle} colSpan={2}>
                      {
                        <div>
                          <p>STOCK ADJUSTMENT</p>
                          <p style={{display:"inline"}}>ADD</p>
                          <p style={{display:"inline",marginLeft:"7rem"}}>
                            LESS
                          </p>
                        </div>
                      }
                    </TableCell>
                    <TableCell style={headerStyle}>SALE QTY</TableCell>

                    <TableCell style={headerStyle}>AVAILABLE STOCK </TableCell>
                    <TableCell style={headerStyle}>UNIT</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {Object.keys(groupedRows).map((categoryId) => (
                    <React.Fragment key={categoryId}>
                      <TableRow>
                        <TableCell
                          colSpan={5}
                          style={{ fontWeight: "bold", padding: "8px" }}
                        >
                          {handleCategoryName(categoryId)}
                        </TableCell>
                      </TableRow>
                      {groupedRows[categoryId].map((row) => (
                        <TableRow key={row._id}>
                          <TableCell style={tableStyle}>
                            {" "}
                            {row.itemName}{" "}
                          </TableCell>
                          <TableCell style={tableStyle}>
                            {" "}
                            {calculateOpeningStock(row._id, row)
                              ? calculateOpeningStock(row._id, row)
                              : 0}{" "}
                          </TableCell>

                          <TableCell style={tableStyle}>
                            {" "}
                            {calculatePurchaseQty(row._id)}{" "}
                          </TableCell>
                          <TableCell style={tableStyle}>
                            {" "}
                            {calculateStockAdjustmentAddQty(row._id)}{" "}
                          </TableCell>
                          <TableCell style={tableStyle}>
                            {" "}
                            {calculateStockAdjustmentLessQty(row._id)}{" "}
                          </TableCell>
                          <TableCell style={tableStyle}>
                            {" "}
                            {calculateSalesQty(row._id)}{" "}
                          </TableCell>

                          <TableCell style={tableStyle}>
                            {row.currency} {calculateAvailableStock(row._id)}
                          </TableCell>
                          <TableCell style={tableStyle}> {row.unit} </TableCell>
                        </TableRow>
                      ))}
                    </React.Fragment>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={6}
                      count={filteredRows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          )}
        </Container>
      </ThemeProvider>
    </div>
  );
};

export default StockDetails;
