import React, { forwardRef } from "react";
import Barcode from "react-barcode";

const BarcodePrintComponent = forwardRef(({ barcodeData, quantity }, ref) => {
  const barcodes = Array.from({ length: quantity }, (_, index) => (
    <div key={index} style={{ marginBottom: "10px" }}>
      <div>{barcodeData.itemName}</div>
      <Barcode
        value={barcodeData.barcode?barcodeData.barcode:barcodeData.itemId}
        width={0.75}
        height={30}
        displayValue={true}
        fontSize={15}
      />
    </div>
  ));

  return <div ref={ref}>{barcodes}</div>;
});

export default BarcodePrintComponent;
