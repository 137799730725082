
import * as api from '../api/index'

import { AUTH, CREATE_PROFILE ,FETCH_USERS,START_LOADING,END_LOADING, DELETE_USER,UPDATE_USER} from './constants'


export const getUsers = () => async (dispatch) => {
    try {
  
      dispatch({ type: START_LOADING })
      const { data } = await api.fetchUsers();
    
      //  console.log(data.email)
      dispatch({ type: FETCH_USERS, payload: data });
      dispatch({ type: END_LOADING })
  
    } catch (error) {
      console.log(error);
    } };

export const deleteUser = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deleteUser(id);
    dispatch({ type: DELETE_USER, payload: id });
    openSnackbar("User deleted successfully");
  } catch (error) {
    console.log(error);
  }
};
export const updateUser = (id, form, openSnackbar) => async (dispatch) => {
    try {
      // Toggle the isActive value
      const { data } = await api.updateUser(id, form);
      dispatch({ type: UPDATE_USER, payload: data });
      openSnackbar("User updated successfully");
    } catch (error) {
      console.log(error);
    }
  };
  
// export const adminSignin =(formData, openSnackbar, setLoading) => async(dispatch) => {

//     try {
//         //login the user
//         const { data } = await api.adminSignIn(formData)
//         (data)
//         dispatch({ type: AUTH, data})
//         // const { info } = await api.createProfile({name: data?.result?.name, email: data?.result?.email, userId: data?.result?._id, phoneNumber: '', businessName: '', contactAddress: '', logo: '', website: ''});
//         // dispatch({ type: CREATE_PROFILE, payload: info });
//             // setLoading(false)
//         openSnackbar("Admin Signin successfull")
//         // history.push('/dashboard')
//         window.location.href="/adminDashboard"

//     } catch (error) {
//          console.log(error?.response?.data?.message)
//         openSnackbar(error?.response?.data?.message)
//         setLoading(false)
//     }
// }

export const adminSignup =(formData, openSnackbar, setLoading) => async(dispatch) => {

    try {
        //Sign up the user
        console.log('Admin Sign up Calling Action')
        const { data } = await api.adminSignUp(formData)
        // dispatch({ type: AUTH, data})
        const { info } = await api.createProfile({name: data?.result?.name, email: data?.result?.email, userId: data?.result?._id, phoneNumber: '', businessName: '', contactAddress: '', logo: '', website: ''});
        dispatch({ type: CREATE_PROFILE, payload: info });
        // window.location.href="../"
         openSnackbar("Sign up successfull")
       setTimeout(() => {
         window.location.reload();
       }, 2000);
        // history.push('/dashboard')
        

    } catch (error) {
        console.log(error)
        openSnackbar(error?.response?.data?.message)
        setLoading(false)
    }
}


export const signin =(formData, openSnackbar, setLoading) => async(dispatch) => {

    try {
        //login the user
        const { data } = await api.signIn(formData)
        //
        dispatch({ type: AUTH, data})
        // const { info } = await api.createProfile({name: data?.result?.name, email: data?.result?.email, userId: data?.result?._id, phoneNumber: '', businessName: '', contactAddress: '', logo: '', website: ''});
        // dispatch({ type: CREATE_PROFILE, payload: info });
            // setLoading(false)
            window.location.href="/PosMainScreen"
        openSnackbar("Signin successfull")
        // history.push('/dashboard')
        
        

    } catch (error) {
         console.log(error?.response?.data?.message)
        openSnackbar(error?.response?.data?.message)
        setLoading(false)
    }
}

export const signup =(formData, openSnackbar, setLoading) => async(dispatch) => {

    try {
        //Sign up the user
        const { data } = await api.signUp(formData)
        // dispatch({ type: AUTH, data})
        const { info } = await api.createProfile({name: data?.result?.name, email: data?.result?.email, userId: data?.result?._id, phoneNumber: '', businessName: '', contactAddress: '', logo: '', website: ''});
        dispatch({ type: CREATE_PROFILE, payload: info });
        
      setTimeout(() => {
          window.location.reload();
      }, 1000);
        // history.push('/dashboard')
        openSnackbar("Sign up successfull")

    } catch (error) {
        console.log(error)
        openSnackbar(error?.response?.data?.message)
        setLoading(false)
    }
}



export const forgot =(formData) => async(dispatch) => {
    try {
        await api.forgot(formData)
    } catch (error) {
        console.log(error)
    }
}


export const reset =(formData, history) => async(dispatch) => {

    try {
        await api.reset(formData)
        history.push('/login')

    } catch (error) {
        alert(error)
    }
}
