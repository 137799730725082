import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddClient from "../components/Invoice/AddClient";
import AddSupplier from "../components/Suppliers/AddSupplier";
import AddStaff from "../components/Staff/AddStaff";
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Settings as SettingsIcon,
  Help as HelpIcon,
  PermIdentity as PermIdentityIcon,
  AssignmentInd as AssignmentIndIcon,
  GroupAdd as GroupAddIcon,
  Add as AddIcon,
} from "@material-ui/icons";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const CartHeader = ({ classes, theme, handleDrawerClose }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openClient, setOpenClient] = useState(false);
  const [openSupplier, setOpenSupplier] = useState(false);
  const [openStaff, setOpenStaff] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <AddClient setOpen={setOpenClient} open={openClient} />
      <AddSupplier setOpen={setOpenSupplier} open={openSupplier} />
      <AddStaff setOpen={setOpenStaff} open={openStaff} />
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === "rtl" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
        <Link to="/settings" style={{ textDecoration: "none" }}>
          <IconButton aria-label="delete" disabled color="primary">
            <SettingsIcon />
          </IconButton>
        </Link>
        <Link to="/customers" style={{ textDecoration: "none" }}>
          <IconButton aria-label="delete" disabled color="primary">
            <PermIdentityIcon />
          </IconButton>
        </Link>

        <IconButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          onClick={handleClick}
        >
          <GroupAddIcon />
        </IconButton>
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <StyledMenuItem onClick={()=>setOpenClient(true)}>
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Customer" />
          </StyledMenuItem>
          <StyledMenuItem onClick={()=>setOpenSupplier(true)}>
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Supplier" />
          </StyledMenuItem>
          {/* <StyledMenuItem onClick={()=>setOpenStaff(true)}>
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Staff" />
          </StyledMenuItem> */}
        </StyledMenu>
        <Link to="/help" style={{ textDecoration: "none" }}>
          <IconButton aria-label="delete" disabled color="primary">
            <HelpIcon />
          </IconButton>
        </Link>

        {/* <Link to="/staff" style={{ textDecoration: "none" }}>
          <IconButton aria-label="delete" disabled color="primary">
            <AssignmentIndIcon />
          </IconButton>
        </Link> */}
      </div>
    </>
  );
};

export default CartHeader;
