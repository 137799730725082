import React, { createContext, useContext, useEffect, useState } from "react";
import DiscountContext, { useDiscount } from "./DiscountContext";
import SettingContext, { useSetting } from "./SettingContext";
import { useDispatch, useSelector } from "react-redux";

const CartContext = createContext();
export function useCart() {
  return useContext(CartContext);
}

export function CartProvider({ children }) {
  const [cartItems, setCartItems] = useState([]);
  const [QtydialogOpen, setQtyDialogOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [receivedAmount, setReceivedAmount] = useState("");
  const [vat, setVat] = useState();
  const profileDetail = JSON.parse(localStorage.getItem("profileDetail"));
  const profile = JSON.parse(localStorage.getItem("profile"));
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  // Function to filter items based on the selected category
  const filterItemsByCategory = (category) => {
    setSelectedCategory(category);
    setSearchQuery(""); // Clear search query when category selected
  };

  // const [subTotal, setSubTotal] = useState(0);
  const {
    discountFlag,
    clearDiscount,
    discountVal,
    discountType,
    discountRate,
  } = useDiscount();

  // Define methods to interact with cartItems
  const handleAddToCart = (item, quantity, openSnackbar) => {
    debugger;
    const updatedCartItems = [...cartItems];
    const existingItem = updatedCartItems.find(
      (cartItem) => cartItem._id === item._id
    );

    if (existingItem) {
      if (existingItem.isStaffReq) {
        existingItem.quantity = quantity;
        console.log("existing item Updated Quantity",existingItem.quantity )
      } else {
        if (
          existingItem.toggleStock &&
          existingItem.stockQty.currentQty - existingItem.quantity <=
            existingItem.stockQty.minQty
        ) {
          // Show an alert for not enough stock\
          if (existingItem.stockQty.currentQty - existingItem.quantity > 0) {
            alert(`Minimum Quantity Reached for ${existingItem.itemName}`);
            existingItem.quantity++;
          } else {
            alert(`Not enough stock for the item ${existingItem.itemName}`);
            return;
          }
        } else {
          existingItem.quantity++;
        }
      }
    } else {
      if (item.isStaffReq) {
        updatedCartItems.push({ ...item, quantity: quantity });
      } else {
        if (
          item.toggleStock &&
          item.stockQty.currentQty - item.quantity <= item.stockQty.minQty
        ) {
          if (item.stockQty.currentQty - item.quantity > 0) {
            alert(`Minimum Quantity Reached for ${item.itemName}`);
            updatedCartItems.push({ ...item, quantity: 1 });
          } else {
            alert(`Not enough stock for the item ${item.itemName}`);
            return;
          }
        } else {
          updatedCartItems.push({ ...item, quantity: 1 });
        }
      }
    }
    // console.log(updatedCartItems);
    setCartItems(updatedCartItems);

    clearDiscount();
  };

  const removeFromCart = (index) => {
    const updatedCartItems = [...cartItems];
    updatedCartItems.splice(index, 1);
    setCartItems(updatedCartItems);
    clearDiscount();
  };

  const incrementQuantity = (index, openSnackbar) => {
    const updatedCartItems = [...cartItems];
    const { quantity, stockQty, itemName } = updatedCartItems[index];
    if (stockQty.currentQty - quantity <= stockQty.minQty) {
      // Show an alert for not enough stock

      alert(`Minimum Quantity Reached for ${itemName}`);

      // alert(`Not enough stock for the item ${updatedCartItems[index].itemName}`);
      setCartItems(updatedCartItems);
    }

    updatedCartItems[index].quantity++;

    setCartItems(updatedCartItems);
    clearDiscount();
  };
  const decrementQuantity = (index) => {
    const updatedCartItems = [...cartItems];
    if (updatedCartItems[index].quantity > 1) {
      updatedCartItems[index].quantity--;
    }
    setCartItems(updatedCartItems);
    clearDiscount();
    // handleCloseQtyDialog()
  };

  const handleQuantityChange = (event, index, openSnackbar) => {
    const updatedCartItems = [...cartItems];
    const newQuantity = parseInt(event.target.value, 10); // Parse the value as an integer
    if (isNaN(newQuantity) || newQuantity === "" || newQuantity < 1) {
      alert("Please enter a valid quantity");
      return;
    }
    if (updatedCartItems[index].toggleStock) {
      if (newQuantity > updatedCartItems[index].stockQty.currentQty) {
        alert(
          `Not enough stock for the item ${updatedCartItems[index].itemName}`
        );
        return;
      }
      if (
        newQuantity >
        updatedCartItems[index].stockQty.currentQty -
          updatedCartItems[index].stockQty.minQty
      ) {
        // Show an alert for not enough stock
        // alert(`Not enough stock for the item ${updatedCartItems[index].itemName}`);
        alert(
          `Minimum Quantity Reached for ${updatedCartItems[index].itemName}`
        );
      }
    }
    updatedCartItems[index].quantity = newQuantity;
    setCartItems(updatedCartItems);
    clearDiscount();
  };

  const handleOpenQtyDialog = (index) => {
    console.log("handleOpenQtyDialog index: " + index);
    setSelectedIndex(index);
    setQtyDialogOpen(true);
  };
  const handleCloseQtyDialog = () => {
    setQtyDialogOpen(false);
  };
  const getSubTotalPrice = () => {
    if (
      (profileDetail?.vatType === "inclusive" ||
        profile.userProfile.vatType === "inclusive") &&
      (!profileDetail || profileDetail.vatType !== "exclusive")
    ) {
      let subTotal = 0;
      for (const item of cartItems) {
        subTotal +=
          item.quantity *
          Math.round(
            (parseFloat(item.unitPrice) /
              (parseFloat(item.unitPrice) + item.vat)) *
              item.unitPrice
          );
      }
      console.log(subTotal);
      return subTotal;
    }

    let subTotal = 0;

    for (const item of cartItems) {
      subTotal += item.quantity * item.unitPrice;
    }

    return subTotal;
  };
  const calculateVat = () => {
    if (
      (profileDetail?.vatType === "inclusive" ||
        profile.userProfile.vatType === "inclusive") &&
      (!profileDetail || profileDetail.vatType !== "exclusive")
    ) {
      let vat = 0;

      for (const item of cartItems) {
        vat +=
          item.quantity *
          Math.round(
            (item.unitPrice / (parseFloat(item.unitPrice) + item.vat)) *
              item.vat
          );
      }

      return vat;
    }

    // let vat= 0;
    // let subTotal=0;
    // // let subTotalWithoutVat=0;
    // for (const item of cartItems) {
    //   subTotal += item.quantity * (parseFloat(item.unitPrice)+((item.vat/100)*item.unitPrice));

    // }
    // const discount= (discountVal? discountVal:0);
    // subTotal -= discount
    // vat= (subTotal/(100+profile.commonVatRate))* (profile.commonVatRate)
    // return vat;

    let vat = 0;
    if (discountFlag && discountType) {
      //Exclusive Tax if Discount is selected and it is of % type
      for (const item of cartItems) {
        vat +=
          item.quantity *
          (parseFloat(item.unitPrice) -
            parseFloat(item.unitPrice) * (discountRate / 100)) *
          (item.vat / 100);
      }
    } else if (discountFlag && !discountType) {
      //Exclusive Tax if Discount is selected and it is of Flat type
      const subTotal = getSubTotalPrice();
      for (const item of cartItems) {
        vat +=
          (item.quantity * parseFloat(item.unitPrice) -
            (discountVal / subTotal) *
              (item.quantity * parseFloat(item.unitPrice))) *
          (item.vat / 100);
      }
    } else {
      //Exclusive Tax with no discount
      for (const item of cartItems) {
        vat += item.quantity * parseFloat(item.unitPrice) * (item.vat / 100);
      }
    }
    return vat;
  };

  const getTotalPrice = () => {
    const totalPrice =
      parseFloat(getSubTotalPrice().toFixed(2)) -
      (discountFlag ? parseFloat(discountVal) : 0) +
      parseFloat(calculateVat());
    return totalPrice;
  };
  const calculateChange = () => {
    const total = getTotalPrice();

    if (receivedAmount >= total) {
      return (receivedAmount - total).toFixed(2);
    } else {
      return "";
    }
  };

  const calculatePrice = (quantity, price) => (quantity * price).toFixed(2);
  return (
    <CartContext.Provider
      value={{
        cartItems,
        QtydialogOpen,
        selectedIndex,
        receivedAmount,
        handleAddToCart,
        removeFromCart,
        incrementQuantity,
        decrementQuantity,
        calculatePrice,
        getTotalPrice,
        getSubTotalPrice,
        handleOpenQtyDialog,
        handleCloseQtyDialog,
        handleQuantityChange,
        setReceivedAmount,
        calculateChange,
        calculateVat,
        setCartItems,
        selectedCategory,
        setSelectedCategory,
        filterItemsByCategory,
        searchQuery,
        setSearchQuery,
        vat,
        setVat,
      }}
    >
      {children}
    </CartContext.Provider>
  );
}
