import * as api from '../api/index'

import { ADD_NEW_STOCKADJUSTMENT, UPDATE_STOCKADJUSTMENT, DELETE_STOCKADJUSTMENT, GET_STOCKADJUSTMENT, FETCH_STOCKADJUSTMENT_BY_USER, FETCH_ALL_STOCKADJUSTMENTS,START_LOADING, END_LOADING } from './constants'

export const getStockAdjustments = () => async (dispatch)=> {
    try {
        dispatch({ type: START_LOADING })
        const { data } = await api.fetchStockAdjustments()
        // console.log(data)
        dispatch({ type: FETCH_ALL_STOCKADJUSTMENTS, payload: data })
        dispatch({ type: END_LOADING })
    } catch (error) {
        console.log(error)
    }
}

export const getStockAdjustmentsByUser =(searchQuery) => async (dispatch) => {
    try {
      dispatch({ type: START_LOADING })
    const  { data: { data } } = await api.fetchStockAdjustmentsByUser(searchQuery)
      dispatch({ type: FETCH_STOCKADJUSTMENT_BY_USER, payload: data });
      dispatch({ type: END_LOADING })
    } catch (error) {
      console.log(error.response)
      
    }
  }


export const getStockAdjustment = (id) => async (dispatch)=> {

    const user = JSON.parse(localStorage.getItem('profile'))

    try {
        const { data } = await api.fetchStockAdjustment(id)
        const businessDetails = await api.fetchProfilesByUser({search: user?.result?._id || user?.result?.googleId})
        const stockAdjustmentData = {...data, businessDetails}
        // console.log(stockAdjustmentData)
        dispatch({ type: GET_STOCKADJUSTMENT, payload: stockAdjustmentData  })
    } catch (error) {
        console.log(error.response)
    }
}

export const createStockAdjustment =(stockAdjustment, history) => async (dispatch) => {

    try {
       
        dispatch({ type: START_LOADING })
        const { data } = await api.addStockAdjustment(stockAdjustment)
        dispatch({ type: ADD_NEW_STOCKADJUSTMENT, payload: data })
         history.push(`/stockAdjustment/${data._id}`)
        dispatch({ type: END_LOADING })
    } catch (error) {
        console.log(error)
    }
}

export const updateStockAdjustment =(id, stockAdjustment) => async (dispatch) => {

    try {
        const { data } = await api.updateStockAdjustment(id, stockAdjustment)
        dispatch({ type: UPDATE_STOCKADJUSTMENT, payload: data })
        
    } catch (error) {
        console.log(error)
    }
}

export const deleteStockAdjustment =(id, openSnackbar) => async (dispatch) => {
    try {
        await api.deleteStockAdjustment(id)

        dispatch({type: DELETE_STOCKADJUSTMENT, payload: id})
        openSnackbar("Stock Adjustment deleted successfully")
    } catch (error) {
        console.log(error.response)
    }
}