import * as api from "../api/index";
import {
  START_LOADING,
  END_LOADING,
  FETCH_ITEMS,
  FETCH_ITEMS_USER,
  ADD_NEW_ITEM,
  ADD_NEW_ITEMS,
  UPDATE_ITEM,
  DELETE_ITEM,
  DELETE_ITEMS_BY_CATEGORY,
  ADD_NEW_STOCK,
  ADD_NEW_STOCKS,
} from "./constants";

export const getAllItems = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchItems();
    // console.log(data)
    dispatch({ type: FETCH_ITEMS, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};
export const getItemsByUser = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data },
    } = await api.fetchItemsByUser(searchQuery);
    dispatch({ type: FETCH_ITEMS_USER, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log("getItemsByuser in itemActions error");
    console.log(error.response);
  }
};

export const createItem = (item, openSnackbar) => async (dispatch) => {
  try {
    const { data } = await api.addItem(item);
    dispatch({ type: ADD_NEW_ITEM, payload: data });
    const { initialStockdata } = await api.addStock([
      {
        itemName: data.itemName,
        transactionRef: "Item Master",
        qty: data.stockQty.initialQty,
        unitPrice: data.unitPrice,
        categoryId: data.category.categoryId,
        itemId: data._id,
        createdAt: data.createdAt,
      },
    ]);
    dispatch({ type: ADD_NEW_STOCK, payload: initialStockdata });
    openSnackbar("Item added successfully");
  } catch (error) {
    console.log(error);
  }
};
const stockDetailsItems = (cartItems) => {
  const stockItems = cartItems.map((data) => ({
    itemName: data.itemName,
    transactionRef: "Item Master",
    qty: data.stockQty.initialQty,
    unitPrice: data.unitPrice,
    categoryId: data.category.categoryId,
    itemId: data._id,
    createdAt: data.createdAt,
  }));

  return stockItems;
};
export const createItems = (items, openSnackbar) => async (dispatch) => {
  try {
    const { data } = await api.addItems(items);
    dispatch({ type: ADD_NEW_ITEMS, payload: data });
 
      const { initialStockdata } = await api.addStock(stockDetailsItems(data));
      dispatch({ type: ADD_NEW_STOCKS, payload: initialStockdata });
    

    openSnackbar("Item added successfully");
  } catch (error) {
    console.log(error);
  }
};

// export const createItems = (item, openSnackbar) => async (dispatch) => {
//   try {
//     const { data } = await api.addItems(item);
//     dispatch({ type: ADD_NEW_ITEM, payload: data });

//     const { initialStockdata } = await api.addStock([
//       {
//         itemName: data.itemName,
//         transactionRef: "Item Master",
//         qty: data.stockQty.initialQty,
//         unitPrice: data.unitPrice,
//         categoryId: data.category.categoryId,
//         itemId: data._id,
//         createdAt: data.createdAt,
//       },
//     ]);
//     dispatch({ type: ADD_NEW_STOCK, payload: initialStockdata });

//     openSnackbar("Item added successfully");
//   } catch (error) {
//     console.log(error);
//   }
// };
export const updateItem = (id, item, openSnackbar) => async (dispatch) => {
  try {
    const { data } = await api.updateItem(id, item);
    dispatch({ type: UPDATE_ITEM, payload: data });
    openSnackbar("Item updated successfully");
  } catch (error) {
    console.log(error);
  }
};
export const updateItems = (items, openSnackbar) => async () => {
  try {
    console.log("Inside updateItems Function in react", items);
    debugger;
    const { message } = await api.updateItems(items);

    openSnackbar(message);
  } catch (error) {
    console.log(error);
  }
};
export const deleteItem = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deleteItem(id);
    dispatch({ type: DELETE_ITEM, payload: id });
    openSnackbar("Item deleted successfully");
  } catch (error) {
    console.log(error);
  }
};
export const deleteItemsByCategoryId = (id, openSnackbar) => async (dispatch) => {
  debugger
  try {
    await api.deleteItemsByCategoryId(id);
    dispatch({ type: DELETE_ITEMS_BY_CATEGORY, payload: id });
    openSnackbar("Items deleted successfully with category");
  } catch (error) {
    console.log(error);
  }
};