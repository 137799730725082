
import { ALL_STAFF, ADD_NEW_STAFF, UPDATE_STAFF, DELETE_STAFF, FETCH_STAFF_BY_USER, FETCH_STAFF, START_LOADING, END_LOADING } from '../actions/constants'

const staff = (state = { isLoading: true, staff: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case ALL_STAFF:
        return {
          ...state,
          staff: action.payload.data,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_STAFF_BY_USER:
        return { ...state, staff: action.payload };

      case FETCH_STAFF:
        return { ...state, staff: action.payload.staff };
      case ADD_NEW_STAFF:
        return { ...state, staff: [...state.staff, action.payload] };
      case UPDATE_STAFF:
        return { ...state, staff: state.staff.map((staff) => (staff._id === action.payload._id ? action.payload : staff)) };
      case DELETE_STAFF:
        return { ...state, staff: state.staff.filter((staff) => staff._id !== action.payload) };
      default:
        return state;
    }
  };

  export default staff




//   const staff =(staff =[], action) => {
//     switch (action.type) {
//         case ALL_STAFF:
//             return action.payload

//         case FETCH_STAFF_BY_USER:
//             return action.payload

//         case ADD_NEW_STAFF:
//             return [...staff, action.payload]

//         case UPDATE_STAFF:
//             return staff.map((staff) => staff._id === action.payload ? action.payload : staff)
        
//         case DELETE_STAFF: 
//         return staff.filter((staff) => staff._id !== action.payload)
        
//         default:
//             return staff;
//     }
// }

// export default staff
