import React, { useState } from 'react';
import { TextField, FormControl, InputLabel, MenuItem, Select, Box, Button,IconButton } from '@material-ui/core';
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles((theme) => ({
    backButton: {
      marginBottom: theme.spacing(2),
      // Add any other styles you want for the back button
    },
    displayButton: {
      backgroundColor: 'grey',
      // Other styles for the display button
    },
    formControl: {
      minWidth: 120,
      margin: theme.spacing(1),
    },
    textField: {
      margin: theme.spacing(1),
    },
  }));
  

const CardDialog = ( {setOpenCardDialog,invoiceData,handleCardPayment}) => {

    const classes = useStyles();
  const handleCardChange = (event) => {

    const { name, value } = event.target;
    if (event.target.value.length === 5 && name === 'cardNumber') {
        
        return
    }
    const updatedCard = { ...invoiceData.card, [name]: value };
    handleCardPayment( updatedCard );
  };


  return (
        <>
        <div>
          <IconButton onClick={()=>setOpenCardDialog(false)} className={classes.backButton}>
        <ArrowBackIosIcon />{" "}
      </IconButton>
      </div>
      {/* <Button onClick={()=>console.log(invoiceData)} style={{backgroundColor:"grey"}}>
        Display InvoiceData
      </Button> */}
      <div>
      <FormControl className={classes.formControl} >
        <InputLabel id="card-mode-label">Card Mode</InputLabel>
        <Select
          labelId="card-mode-label"
          id="card-mode"
          value={invoiceData.card.cardMode}
          label="Card Mode"
          name="cardMode"
          onChange={handleCardChange}
        >
          <MenuItem value="Debit">Debit Card</MenuItem>
          <MenuItem value="Credit">Credit Card</MenuItem>
        </Select>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 120 }} className={classes.formControl}>
        <InputLabel id="card-type-label">Card Type</InputLabel>
        <Select
          labelId="card-type-label"
          id="card-type"
          value={invoiceData.card.cardType}
          label="Card Type"
          name="cardType"
          onChange={handleCardChange}
        >
          <MenuItem value="Visa">Visa</MenuItem>
          <MenuItem value="Rupay">Rupay</MenuItem>
          <MenuItem value="Mastercard">Mastercard</MenuItem>
        </Select>
      </FormControl>

      <Box sx={{ m: 1 }}>
        <TextField
          id="card-number"
          label="Card Number"
          variant="outlined"
          type="number"
          name="cardNumber"
          value={invoiceData.card.cardNumber}
          onChange={handleCardChange}
        />
      </Box>
      </div>
      </>   
    
  );
};

export default CardDialog;
