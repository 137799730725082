/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-number-input';
import './phoneInputStyles.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import { useDispatch } from 'react-redux';
import { createSupplier } from '../../actions/supplierActions';
import { useLocation } from 'react-router-dom';

import { useSnackbar } from 'react-simple-snackbar';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#1976D2',
    marginLeft: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddSupplier = ({ setOpen, open ,currentId, setCurrentId}) => {
  const location = useLocation();
  const [supplierData, setSupplierData] = useState({ name: '', email: '', phone: '', address: '', gstNo: '', contactPerson: '', userId: [] });
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem('profile'));
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [errors, setErrors] = useState({});
  useEffect(() => {
    var checkId = user?.result?._id;
    if (checkId !== undefined) {
      setSupplierData({ ...supplierData, userId: [checkId] });
    } else {
      setSupplierData({ ...supplierData, userId: [user?.result?.googleId] });
    }
  }, [location]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // const maskedValue = value.startsWith('') ? value : '' + value.slice(3);
    // if(name==='phone') {
    // setSupplierData((prevData) => ({
    //   ...prevData,
    //   [name]: maskedValue,
    // }));}
    // else{
      setSupplierData((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    //}
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error for the specific field
    }));
  };
  
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (supplierData.name.trim() === '') {
      newErrors.name = 'Name is required';
      isValid = false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (supplierData.email.trim() === ''||!emailRegex.test(supplierData.email.trim())) {
      newErrors.email = 'Please enter a valid email address.';
      isValid = false;
    }

    
    if (supplierData.phone.trim() === '') {
      newErrors.phone = 'Phone is required';
      isValid = false;
    }else if(supplierData.phone.length<10||supplierData.phone.length>13){
      newErrors.phone = 'Please enter a valid phone number.';
      isValid = false;
    }
    if (supplierData.address.trim() === '') {
      newErrors.address = 'Address is required';
      isValid = false;
    }
    if (supplierData.gstNo.trim() === '') {
      newErrors.gstNo = 'GST No is required';
      isValid = false;
    }
    if (supplierData.contactPerson.trim() === '') {
      newErrors.contactPerson = 'Contact Person is required';
      isValid = false;
    }
    // if (supplierData.dueDate.trim() === '') {
    //   newErrors.dueDate = 'Payment Term Days are required';
    //   isValid = false;
    // }

    setErrors(newErrors);
    return isValid;
  };
  const handleSubmitSupplier = (e) => {
    e.preventDefault();
    if (validateForm()) { // Check if the form is valid before submitting
      
        
      
        dispatch(createSupplier(supplierData, openSnackbar));
      
      clear();
      handleClose();
    }
  };

  const clear = () => {
    setSupplierData({ name: '', email: '', phone: '', address: '', gstNo: '', contactPerson: '', userId: [] });
     setErrors({}); // Clear the error messages
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} fullWidth>
        <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ paddingLeft: '20px',backgroundColor: "#ef7f1a",color: "white"
      }}>
          New Supplier
        </DialogTitle>
        <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Supplier Name"
                  fullWidth
                  name="name"
                  value={supplierData.name}
                  onChange={handleChange}
                  error={!!errors.name} // Check if error exists
                  helperText={errors.name} // Display error message
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Contact Person"
                  fullWidth
                  name="contactPerson"
                  value={supplierData.contactPerson}
                  onChange={handleChange}
                  error={!!errors.contactPerson} // Check if error exists
                  helperText={errors.contactPerson} // Display error message
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Email"
                  fullWidth
                  name="email"
                  value={supplierData.email}
                  onChange={handleChange}
                  error={!!errors.email} // Check if error exists
                  helperText={errors.email} // Display error message
                  inputProps={{
                    pattern: "^[^\s@]+@[^\s@]+\.[^\s@]+$", // Email format regex pattern
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <TextField
                  variant="outlined"
                  label="Phone"
                  fullWidth
                  name="phone"
                  value={supplierData.phone}
                  onChange={handleChange}
                  error={!!errors.phone} // Check if error exists
                  helperText={errors.phone} // Display error message
                 
                /> */}
                 <PhoneInput
                 placeholder="Enter phone number"
                 value={supplierData.phone}
                 onChange={(value) => handleChange({ target: { name: 'phone', value } })}
                 error={!!errors.phone}
                 defaultCountry="TH" // Default country (India in this case)
                inputProps={{
                            name: 'phone',
                            required: true,
                  }}
                  
                  />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Address"
                  fullWidth
                  name="address"
                  value={supplierData.address}
                  onChange={handleChange}
                  error={!!errors.address} // Check if error exists
                  helperText={errors.address} // Display error message
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="GSTIN"
                  fullWidth
                  name="gstNo"
                  value={supplierData.gstNo}
                  onChange={handleChange}
                  error={!!errors.gstNo} // Check if error exists
                  helperText={errors.gstNo} // Display error message
                />
              </Grid> */}
             
              {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Payment Term Days"
                  fullWidth
                  type="number"
                  name="dueDate"
                  value={supplierData.dueDate} //
                  onChange={handleChange}
                  error={!!errors.dueDate} // Check if error exists
                  helperText={errors.dueDate} // Display error message
                 
                />
              </Grid> */}
            </Grid>
          </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleSubmitSupplier} variant="contained" style={{ marginRight: '25px' ,backgroundColor: "#ef7f1a",color: "white"}}>
            Save Supplier
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddSupplier;
