import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 350; // You should define drawerWidth if not defined already

const useStyles = makeStyles((theme) => ({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth + 10,
      backgroundColor: "#f5f5f5",
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-start",
      backgroundColor: "#fff",
    },
    tableWrapper: {
      minHeight: "47.5%",
      overflowY: "auto",
    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
    },
  
    tableRow: {
      maxHeight: "120px",
    },
    tableHead: {
      backgroundColor: "#f5f5f5",
      color: "black",
      fontWeight: "bold",
    },
    tableCell: {
      padding: theme.spacing(2),
      borderBottom: "1px solid #e0e0e0",
      textAlign: "left",
    },
    tableFooter: {
      fontWeight: "bold",
    },
    totalAmountSection: {
      width: drawerWidth,
      padding: theme.spacing(2),
      backgroundColor: "#f5f5f5",
      fontWeight: "bold",
      fontSize: "18px",
    },
    totalAmountText: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    deleteIcon: {
      color: theme.palette.error.main,
    },
    payNowButton: {
      margin: theme.spacing(2),
  
      color: "#fff",
      "&:hover": {
        backgroundColor: "#156CB0",
      },
    },
    incrementDecrementButton: {
      fontSize: "16px",
      fontWeight: "bold",
      padding: "4px",
      backgroundColor: "#1976D2",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#156CB0",
      },
    },
    quantityText: {
      fontWeight: "bold",
      marginRight: theme.spacing(1),
    },
  
    dialog: {
      maxWidth: "400px",
    },
    dialogTitle: {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
    dialogContent: {
      padding: theme.spacing(2),
    },
    itemText: {
      marginBottom: theme.spacing(1),
    },
    pillContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    pillButtonGroup: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#ffff", // Background color for the pill
      borderRadius: "60px",
      minWidth: "8rem",
      border: "black solid 1px", // Adjust to create a pill shape
    },
    pillIconButton: {
      padding: "8px",
      color: "black", // Adjust the padding as needed
    },
    quantityInput: {
      width: "20%",
      margin: "0 5rem 0 5rem",
      fontSize: "18px",
      // Add spacing around the input
    },
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(4),
      outline: "none",
    },
    AddButtonModal: {
      marginTop: theme.spacing(4),
    },
    modalButtons: {
      display: "flex",
      justifyContent: "space-between",
    },
  }));
export default useStyles;
