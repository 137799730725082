// AddStaffDialog.js

import React, { useState, useContext, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
  Checkbox,
  DialogActions,
  Button,
} from "@material-ui/core";
import { StaffCommissionDetailContext } from "../Context/StaffCommissionDetailsContext";
import { useCart } from "../Context/CartContext";
import {
  createStaffCommissionDetail,
  updateStaffCommissionDetail,
} from "../actions/staffCommissionDetailActions";
import { initialCommissionDetail } from "../initialStaffCommissionDetail";
import { useDispatch } from "react-redux";
const CommissionDialog = ({
  open,
  onClose,
  staff,
  onAddStaff,
  serviceId,
  service,
  openSnackbar,
  staffCommissionDetailEdit,
}) => {
  const dispatch = useDispatch();
  const [staffCommissionDetail, setStaffCommissionDetail] = useState(
    initialCommissionDetail
  );
  const { staffCommissionDetailsArray, setStaffCommissionDetailsArray } =
    useContext(StaffCommissionDetailContext);

  const [selectedStaffRows, setSelectedStaffRows] = useState([]);
  const user = JSON.parse(localStorage.getItem("profile"));
  const [quantity, setQuantity] = useState(0);
  const { handleAddToCart } = useCart();

  useEffect(() => {
    if (staffCommissionDetailEdit) {
      setQuantity(staffCommissionDetailEdit.totalQty);
      setSelectedStaffRows(staffCommissionDetailEdit.staffDetails);
      setStaffCommissionDetail(staffCommissionDetailEdit);
    }
  }, [staffCommissionDetailEdit]);

  const handleCheckboxChange = (staff) => {
    setSelectedStaffRows((prevSelectedStaffRows) => {
      const isSelected = prevSelectedStaffRows.some(
        (s) => s.staffId === staff._id
      );

      if (isSelected) {
        return prevSelectedStaffRows.filter((s) => s.staffId !== staff._id);
      } else {
        return [
          ...prevSelectedStaffRows,
          {
            staffId: staff._id,
            staffName: staff.name,
            staffCode: staff.code,
            staffQty: 0,
            staffCommission: staff.services.find((s) => s.id === serviceId)
              .commission,
            staffTotal: 0,
          },
        ];
      }
    });
    setStaffCommissionDetail({
      ...staffCommissionDetail,
      staffDetails: selectedStaffRows,
    });
  };

  const handleQuantityChange = (staffId, newQuantity) => {
    const updatedRows = selectedStaffRows.map((row) =>
      row.staffId === staffId
        ? {
            ...row,
            staffQty: parseInt(newQuantity),
            staffTotal: newQuantity * row.staffCommission,
          }
        : row
    );
    setSelectedStaffRows(updatedRows);
    setStaffCommissionDetail({
      ...staffCommissionDetail,
      staffDetails: updatedRows,
    });
    // console.log(selectedStaffRows);
  };
  const handleTotalQtyChange = (e) => {
    setQuantity(e.target.value);
    if (staffCommissionDetailEdit) {
      setStaffCommissionDetail({
        ...staffCommissionDetail,
        totalQty: e.target.value,
        modifiedAt: new Date(),
      });
    } else {
      setStaffCommissionDetail({
        ...staffCommissionDetail,
        totalQty: e.target.value,
        serviceId: serviceId,
        serviceName: service.itemName,
        serviceUnitPrice: service.unitPrice,
        creator: user?.result?._id || user?.result?.googleId,
        createdAt: new Date(),
        modifiedAt: new Date(),
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    debugger;
    
    const existingIndex = staffCommissionDetailsArray.findIndex(
      (detail) => detail.serviceId === staffCommissionDetail.serviceId
    );

    // If an existing staffCommissionDetail is found, remove it from the array
    if (staffCommissionDetailEdit) {
      const updatedArray = [...staffCommissionDetailsArray];
      updatedArray[existingIndex] = staffCommissionDetail;
      console.log("Updated Array",updatedArray);
      setStaffCommissionDetailsArray(updatedArray);
      
    } else {
      if (existingIndex !== -1) {
        const newArray = [...staffCommissionDetailsArray];
        newArray.splice(existingIndex, 1); // Remove the existing staffCommissionDetail
        setStaffCommissionDetailsArray(newArray);
      }

      // Add the new staffCommissionDetail to the array
      const updatedArray =
        existingIndex !== -1
          ? [
              ...staffCommissionDetailsArray.slice(0, existingIndex),
              staffCommissionDetail,
              ...staffCommissionDetailsArray.slice(existingIndex + 1),
            ]
          : [...staffCommissionDetailsArray, staffCommissionDetail];

      setStaffCommissionDetailsArray(updatedArray);
    }
    setStaffCommissionDetail(initialCommissionDetail);

    handleAddToCart(service, quantity, openSnackbar);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        id="customized-dialog-title"
        style={{
          paddingLeft: "20px",
          backgroundColor: "#ef7f1a",
          color: "white",
        }}
      >
        {staffCommissionDetailEdit
          ? " Edit Staff Commission"
          : "Add Staff Commission"}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Total Quantity"
          type="number"
          value={quantity}
          onChange={(e) => handleTotalQtyChange(e)}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Commission</TableCell>
                <TableCell>Number of Persons</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Select</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {staff
                .filter((staff) =>
                  staff.services.some((service) => service.id === serviceId)
                )
                .map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell>{row.code}</TableCell>
                    <TableCell>
                      {row.services.find((s) => s.id === serviceId).commission}
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        disabled={
                          !selectedStaffRows.find((r) => r.staffId === row._id)
                        }
                        value={
                          selectedStaffRows.find((r) => r.staffId === row._id)
                            ?.staffQty
                        }
                        onChange={(e) =>
                          handleQuantityChange(row._id, e.target.value)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {/* Calculate total based on quantity and commission */}
                      {(
                        (selectedStaffRows.find((r) => r.staffId === row._id)
                          ?.staffQty || 0) *
                        row.services.find((s) => s.id === serviceId).commission
                      ).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={selectedStaffRows?.some(
                          (s) => s.staffId === row._id
                        )}
                        onChange={() => handleCheckboxChange(row)}
                        style={{ color: "#ef7f1a" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* <Button onClick={() => console.log(staffCommissionDetail)}>Show</Button> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => console.log(staffCommissionDetail)}>
          Show
        </Button>
        <Button
          style={{ color: "White", backgroundColor: "#ef7f1a" }}
          onClick={handleSubmit}
        >
          {staffCommissionDetailEdit ? "Update" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommissionDialog;
