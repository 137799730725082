import React, { createContext, useContext, useState } from 'react';
import BillDialog from '../PoS/BillDialog';

const PayNowContext = createContext();

export function PayNowProvider({ children }) {
  const [openPayNow, setOpenPayNow] = useState(false);
  const [cashBillDialog, setBillDialog] = useState(false);
  const [cardBillDialog, setCardBillDialog] = useState(false);
  
  const handlePayNow = () => {
    setOpenPayNow(!openPayNow);
  };
  const handleCashBillDialog = () => {
    // You can add logic to close the dialog and handle bill printing here
    setBillDialog(!cashBillDialog);
  };
  const handleCardBillDialog = () => {
    // You can add logic to close the dialog and handle bill printing here
    setCardBillDialog(!cardBillDialog);
  };
  return (
    <PayNowContext.Provider value={{ openPayNow, handlePayNow ,cashBillDialog,handleCashBillDialog,cardBillDialog,handleCardBillDialog}}>
      {children}
    </PayNowContext.Provider>
  );
}

export function usePayNow() {
  return useContext(PayNowContext);
}
