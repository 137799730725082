import React from 'react';
import './HowItWorks.css'; // Your CSS file for styling

const HowItWorks = () => {
  return (
    <div className="how-it-works-container">
      <h2 className="section-heading">How It Works</h2>
      <div className="section-content">
        <div className="step">
          <h3>1. Point of Sale (PoS)</h3>
          <p>
            Seamlessly create orders, manage sales, and process transactions
            through the intuitive Point of Sale interface.
          </p>
        </div>

        <div className="step">
          <h3>2. View Invoices</h3>
          <p>
            Access and review generated invoices with detailed information
            about completed transactions for better record-keeping.
          </p>
        </div>

        <div className="step">
          <h3>3. Purchase Management</h3>
          <p>
            Track purchases, supplier details, and incoming stock to maintain a
            comprehensive view of your inventory.
          </p>
        </div>

        <div className="step">
          <h3>4. View/Edit Purchases</h3>
          <p>
            Easily access and modify purchase details to ensure accuracy in
            recorded transactions.
          </p>
        </div>

        <div className="step">
          <h3>5. Stock Adjustment</h3>
          <p>
            Effortlessly adjust stock levels for all items, enabling precise
            inventory control and management.
          </p>
        </div>

        <div className="step">
          <h3>6. Stock Details/Summary</h3>
          <p>
            View comprehensive summaries of stock levels, providing insights
            into inventory statuses at a glance.
          </p>
        </div>

        <div className="step">
          <h3>7. Store Management</h3>
          <p>
            Effectively manage store-related aspects, such as item categorization,
            location tracking, and more.
          </p>
        </div>

        <div className="step">
          <h3>8. Report Generation</h3>
          <p>
            Generate detailed reports encompassing invoices, purchases, stock
            adjustments, and summaries for informed decision-making.
          </p>
        </div>

        <div className="step">
          <h3>9. Customizable Settings</h3>
          <p>
            Tailor the application settings according to your store's specific
            needs, including pricing, tax configurations, and more.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
