
import { FETCH_ALL_STOCKADJUSTMENTS, ADD_NEW_STOCKADJUSTMENT, UPDATE_STOCKADJUSTMENT, DELETE_STOCKADJUSTMENT, GET_STOCKADJUSTMENT, START_LOADING, END_LOADING, FETCH_STOCKADJUSTMENT_BY_USER } from '../actions/constants'

const stockAdjustments = (state = { isLoading: true, stockAdjustments: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case FETCH_ALL_STOCKADJUSTMENTS:
        return {
          ...state,
          stockAdjustments: action.payload,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_STOCKADJUSTMENT_BY_USER:
        return { ...state, stockAdjustments: action.payload };

      case GET_STOCKADJUSTMENT:
        return { ...state, stockAdjustment: action.payload };
      case ADD_NEW_STOCKADJUSTMENT:
        return { ...state, stockAdjustments: [...state.stockAdjustments, action.payload] };
      case UPDATE_STOCKADJUSTMENT:
        return { ...state, stockAdjustments: state.stockAdjustments.map((stockAdjustment) => (stockAdjustment._id === action.payload._id ? action.payload : stockAdjustment)) };
      case DELETE_STOCKADJUSTMENT:
        return { ...state, stockAdjustments: state.stockAdjustments.filter((stockAdjustment) => stockAdjustment._id !== action.payload) };
      default:
        return state;
    }
  };

  export default stockAdjustments



//   const stockAdjustments =( state = { stockAdjustments: [], }, action ) => {
//     switch (action.type) {
//         case FETCH_ALL:
//             return { ...state, stockAdjustments: action.payload }

//         case GET_STOCKADJUSTMENT:
//             return { ...state, stockAdjustment: action.payload }

//         case ADD_NEW:
//             return { ...state, stockAdjustments: [...state.stockAdjustments, action.payload] }

//         case UPDATE:
//             return { ...state, stockAdjustments: state.stockAdjustments.map((stockAdjustment) => stockAdjustment._id === action.payload ? action.payload : stockAdjustment) }
        
//         case DELETE: 
//         return {...state, stockAdjustments: state.stockAdjustments.filter((stockAdjustment) => stockAdjustment._id !== action.payload)}
        
//         default:
//             return state;
//     }
// }

// export default stockAdjustments
