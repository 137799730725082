import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { EditContext } from "../../Context/EditContext";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from "@material-ui/core/Chip";
import Avatar from "@material-ui/core/Avatar";
import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
// import { toCommas } from "../../utils/utils";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { getClientsByUser, getAllClients } from "../../actions/clientActions";
import { Container } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import AddClient from "../Invoice/AddClient";
import {
  createMeeting,
  getMeeting,
  updateMeeting,
} from "../../actions/meetingActions";

const useStyles = makeStyles((theme) => ({
  formField: {
    marginBottom: theme.spacing(2),
  },
  dialogTitle: {
    backgroundColor: "#ef7f1a", // Replace 'primary.main' with your desired blue color
    color: "#fff", // White text color
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "white",
  },
}));

const colorTheme = createTheme({
  palette: {
    primary: {
      main: "#ef7f1a",
    },
  },
});
const MeetingForm = ({ onClose, onMeetingSubmit }) => {
  const classes = useStyles();
  const [client, setClient] = useState(null);
  const [touched, setTouched] = useState(false);
  const [lat, setLatitude] = useState("");
  const [long, setLongitude] = useState("");
  const [location, setLocation] = useState("");
  const { currId, setCurrId } = useContext(EditContext);
  const user = JSON.parse(localStorage.getItem("profile"));
  const dispatch = useDispatch();
  const history = useHistory();
  const clients = useSelector((state) => state.clients.clients);
  //(clients);
  // const { meeting } = useSelector((state) => state.meetings);
  const [advance, setAdvance] = useState({
    cash: "",
    cheque: "",
  });
  const meeting = useSelector((state) =>
    currId ? state.meetings.meetings.find((c) => c._id === currId) : null
  );
  // //(meeting);

  const [formData, setFormData] = useState({
    remarks: "",
    orderRupees: "",
    date: new Date(),
    time: new Date(),
    orderRecieved: "No",
    meetingType: "Yes",
  });
  const initialFormData = {
    remarks: "",
    orderRupees: "",
    date: new Date(),
    time: new Date(),
    orderRecieved: "No",
    meetingType: "Yes",
    // Add other form fields and their initial values here
  };
  const [errors, setErrors] = useState({
    remarks: "",
    orderRupees: "",
    orderRecieved: "",
    client: "",
    meetingType: "",
  });

  // const handleFieldChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData((prevFormData) => ({
  //     ...prevFormData,
  //     [name]: value,
  //   }));
  // };
  const handleFieldChange = (event) => {
    const { name, value } = event.target;

    if (name === "advance.cash" || name === "advance.cheque") {
      setAdvance((prevAdvance) => ({
        ...prevAdvance,
        [name.split(".")[1]]: value,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    // Clear the error message for the changed field
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const toCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const handleTime = (time) => {
    //(time);
    setFormData((prevFormData) => ({
      ...prevFormData,
      time: time,
    }));
  };
  const handleDate = (date) => {
    //();
    const formattedDate = date.toISOString().split("T")[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      date: formattedDate,
    }));
  };
  const handleClientChange = () => {
    setTouched(true);
    setErrors((prevErrors) => ({
      ...prevErrors,
      client: "",
    }));
  };
  const handleCancel = () => {
    setFormData({
      remarks: "",
      orderRupees: "",
      date: new Date(),
      time: new Date(),
      orderRecieved: "yes",
      meetingType: "Yes",
    });
    onClose();
    setCurrId(null);
  };
  useEffect(() => {
    if (meeting) {
      setFormData(meeting);
      setClient(meeting.client);
      setAdvance(meeting.advance);
    }
  }, [meeting]);
  useEffect(() => {
    // Add a "beforeunload" event listener to prompt the user before leaving the page
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const handleBeforeUnload = (event) => {
    // Check if the form has unsaved changes and display a confirmation message
    if (isFormDirty()) {
      event.preventDefault();
      event.returnValue = "You have unsaved changes. Are you sure you want to leave?";
    }
  };
  useEffect(() => {
    const unblock = history.block((location, action) => {
      // Check if the form has unsaved changes
      if (isFormDirty()) {
        // Prompt the user for confirmation when navigating to a different route
        return "You have unsaved changes. Are you sure you want to leave?";
      }
    });

    return () => {
      unblock(); // Unregister the route change listener when the component unmounts
    };
  }, [history]);
  const isFormDirty = () => {
    // Compare the current formData with the initialFormData
    return (
      formData.remarks !== initialFormData.remarks ||
      formData.orderRupees !== initialFormData.orderRupees ||
     formData.date!== initialFormData.date||
     formData.orderRecieved!== initialFormData.orderRecieved||
     formData.meetingType!== initialFormData.meetingType
      // Add more fields to check as needed
      // For example, if you have additional fields in your form, include them here
    );
  };

  // useEffect(() => {
  //   dispatch(
  //     // getClientsByUser({ search: user?.result._id || user?.result?.googleId })
  //     getAllClients()
  //   );

  //   // eslint-disable-next-line
  // }, []);
  const handleSubmit = (event) => {
    event.preventDefault();

    const requiredFields = ["date", "time", "meetingType", "remarks"];
    const fieldErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        fieldErrors[field] = "This field is required.";
      }
    });
    // //("Client", client);
    if (!client) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        client: "Party name is required.",
      }));
      return;
    }
    if (formData.orderRecieved === "Yes") {
      if (!formData.orderRupees) {
        fieldErrors.orderRupees = "This field is required.";
      }
    } else {
      formData.orderRupees = "";
    }
    if (formData.meetingType === "Yes") {
      if (!advance.cash && !advance.cheque) {
        fieldErrors["advance.cash"] =
          "At least fill one of 'cash' or 'cheque'.";
      }
    }
    // if (formData.meetingType == "No") {
    //   if (advance.cash) {
    //     setAdvance((prevAdvance) => ({
    //       ...prevAdvance,
    //       cash: "",
    //       cheque: "",
    //     }));
    //   }
    // }
    setErrors(fieldErrors);

    // If there are any errors, stop the form submission
    if (Object.keys(fieldErrors).length > 0) {
      return;
    }

    // if (formData.orderRecieved === "Yes" &&
    //   !formData.orderRupees || !advance.cash || !advance.cheque
    // )
    //   {
    //     // Handle the case when a required field is empty (e.g., show an error message)
    //     alert("Please fill in all required fields.");
    //     return;
    //   }

    //("Form Submitted");
    // onMeetingSubmit(formData);
    // Add your code here to handle the form submission
    //(currId);
    if (currId) {
      dispatch(
        updateMeeting(currId, {
          ...formData,
          client,
          advance: formData.meetingType === "No" ? null : advance,
        })
      );
      // history.push(`/meeting/${meeting._id}`);
    } else {
      //("in create New");
      //("Form Data", formData);
      //("Advances", advance);

      dispatch(
        createMeeting({
          ...formData,
          client,
          advance: formData.meetingType === "No" ? null : advance,
          creator: [user?.result?._id || user?.result?.googleId],
          location,
          createdAt: formData.date,
          // history
        })
      );
    }
    setFormData({
      remarks: "",
      orderRupees: "",

      date: new Date(),
      time: new Date(),
      orderRecieved: "No",
      meetingType: "Yes",
    });
    setCurrId(null);
    onClose();
  };
  const clientsProps = {
    options: clients,
    getOptionLabel: (option) => option.name,
  };
  const [open, setOpen] = useState(false);

  useEffect(() => {
    let watchId;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(success, error, {
        enableHighAccuracy: true,
      });
    } else {
      //("Geolocation not supported");
    }

    function success(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setLatitude(latitude);
      setLongitude(longitude);
      //(`Latitude: ${latitude}, Longitude: ${longitude}`);
    }
    watchId = navigator.geolocation.watchPosition(success, error, {
      enableHighAccuracy: true,
    });
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
    function error() {
      //("Unable to retrieve your location");
    }
  }, []);
  useEffect(() => {
    const handleGeocode = async () => {
      try {
        const response = await axios.get(
          `https://apis.mapmyindia.com/advancedmaps/v1/74da66263b83fc0074a643fd7bea16e4/rev_geocode?lat=${lat}&lng=${long}`
        );
        const data = response.data;
        if (data.results && data.results.length > 0) {
          setLocation(data.results[0].formatted_address);
        } else {
          setLocation("Address not found");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    handleGeocode();
  }, [lat, long]);

  return (
    <>
      <AddClient setOpen={setOpen} open={open} />

      <Dialog
        open
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleCancel();
          }
        }}
      >
        <DialogTitle
          id="customized-dialog-title"
          className={classes.dialogTitle}
        >
          {currId ? "Edit Report" : "Add Daily Report"}
          {onClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleCancel}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent>
          {/* <div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              padding: "10px",
              backgroundColor: "#f1f1f1",
            }}
          >
           
            <LocationOnIcon />
            <Typography>{location}</Typography>
          </div> */}

          <form onSubmit={handleSubmit}>
            <ThemeProvider theme={colorTheme}>
              <Grid
                container
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      label="Date"
                      name="date"
                      value={formData.date}
                      onChange={handleDate}
                      required
                      fullWidth
                      format="yyyy/MM/dd"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      label="Time"
                      name="time"
                      value={formData.time}
                      onChange={handleTime}
                      required
                      fullWidth
                      format="hh:mm a"
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                {/* <Grid item xs={12}>
              <TextField
                label="Party Name"
                name="partyName"
                value={formData.partyName}
                onChange={handleFieldChange}
                required
                fullWidth
                className={classes.formField}
                variant="outlined"
                size="small"
              />
            </Grid> */}
                <Grid item style={{ width: "100%" }}>
                  <Container>
                    <Typography
                      variant="overline"
                      style={{ color: "gray", paddingRight: "3px" }}
                      gutterBottom
                    >
                      Party Name
                    </Typography>

                    {client && (
                      <>
                        <Typography variant="subtitle1" gutterBottom>
                          {client.name}
                        </Typography>

                        {/* <Typography variant="body2">{client.email}</Typography> */}
                        <Typography variant="body1">{client.phone}</Typography>
                        <Button
                          color="primary"
                          size="small"
                          style={{
                            textTransform: "none",
                            backgroundColor: "#ef7f1a",
                            color: "white",
                          }}
                          onClick={() => setClient(null)}
                        >
                          Change
                        </Button>
                      </>
                    )}
                    <div
                      style={
                        client ? { display: "none" } : { display: "block" }
                      }
                    >
                      <Autocomplete
                        {...clientsProps}
                        // PaperComponent={CustomPaper}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // required={!invoice && true}
                            label="Select Party"
                            margin="normal"
                            variant="outlined"
                            error={touched && !!errors.client}
                            helperText={touched && errors.client}
                          />
                        )}
                        value={clients?.name}
                        onChange={(event, value) => {
                          setClient(value);
                          handleClientChange();
                        }}
                        // onChange={(event, value) => setClient(value) }
                      />
                    </div>
                    {!client && (
                      <>
                        <Grid item style={{ paddingBottom: "10px" }}>
                          <Chip
                            avatar={
                              <Avatar
                                style={{
                                  backgroundColor: "#ef7f1a",
                                  color: "white",
                                }}
                              >
                                +
                              </Avatar>
                            }
                            label="New Party"
                            onClick={() => setOpen(true)}
                            variant="outlined"
                          />
                        </Grid>
                      </>
                    )}
                  </Container>
                </Grid>
                <Grid item xs={12}>
                  <FormLabel labelplacement="start">Payment Recieved</FormLabel>
                  <RadioGroup
                    name="meetingType"
                    value={formData.meetingType}
                    onChange={handleFieldChange}
                    row
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio style={{ color: "#ef7f1a" }} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio style={{ color: "#ef7f1a" }} />}
                      label="No"
                    />
                  </RadioGroup>
                  {!!errors.meetingType && (
                    <Typography variant="body2" color="error">
                      {errors.meetingType}
                    </Typography>
                  )}
                </Grid>
                {formData.meetingType === "Yes" && (
                  <Grid item xs={12}>
                    <FormLabel labelplacement="start">Payment(Rs.)</FormLabel>
                    <Grid container spacing={2} style={{ marginTop: "6px" }}>
                      <Grid item xs={6}>
                        <TextField
                          label="Cash"
                          // color="warning"
                          name="advance.cash"
                          value={advance?.cash}
                          onChange={handleFieldChange}
                          fullWidth
                          type="number"
                          className={classes.formField}
                          variant="outlined"
                          size="small"
                          error={!!errors["advance.cash"]} // Set error prop based on error existence
                          helperText={errors["advance.cash"]} // Display error message
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Cheque"
                          name="advance.cheque"
                          value={advance?.cheque}
                          onChange={handleFieldChange}
                          fullWidth
                          type="number"
                          className={classes.formField}
                          variant="outlined"
                          size="small"
                          error={!!errors["advance.cheque"]} // Set error prop based on error existence
                          helperText={errors["advance.cheque"]} // Display error message
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {/* <Grid item xs={12}>
              <TextField
                label="Address"
                name="address"
                value={formData.address}
                onChange={handleFieldChange}
                required
                fullWidth
                multiline
                maxRows={4}
                className={classes.formField}
                variant="outlined"
                size="small"
              />
            </Grid> */}

                <Grid item xs={12}>
                  <FormLabel labelplacement="start">Order Recieved</FormLabel>
                  <RadioGroup
                    name="orderRecieved"
                    value={formData.orderRecieved}
                    onChange={handleFieldChange}
                    row
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio style={{ color: "#ef7f1a" }} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio style={{ color: "#ef7f1a" }} />}
                      label="No"
                    />
                  </RadioGroup>
                  {!!errors.orderRecieved && ( // Display error message conditionally
                    <Typography variant="body2" color="error">
                      {errors.orderRecieved}
                    </Typography>
                  )}
                </Grid>

                {formData.orderRecieved === "Yes" && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        label="Order(Rs.)"
                        name="orderRupees"
                        type="number"
                        value={formData.orderRupees}
                        onChange={handleFieldChange}
                        fullWidth
                        className={classes.formField}
                        variant="outlined"
                        size="small"
                        error={!!errors.orderRupees} // Set error prop based on error existence
                        helperText={errors.orderRupees} // Display error message
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={12}>
                  <TextField
                    label="Remarks"
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleFieldChange}
                    required
                    fullWidth
                    multiline
                    maxRows={4}
                    className={classes.formField}
                    variant="outlined"
                    size="small"
                    error={!!errors.remarks} // Set error prop based on error existence
                    helperText={errors.remarks} // Display error message
                  />
                </Grid>
              </Grid>
            </ThemeProvider>
          </form>
        </DialogContent>
        <DialogActions style={{ display: "flex" }}>
          <Button
            type="submit"
            variant="contained"
            // color="primary"
            style={{ backgroundColor: "#ef7f1a", color: "white" }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
          {/* <Button
            variant="contained"
            style={{ backgroundColor: "white", color: "#ef7f1a" }}
            onClick={handleCancel}
          >
            Cancel
          </Button> */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MeetingForm;
