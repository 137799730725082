import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createCategory,
  getAllCategories,
  deleteCategory
} from "../../actions/categoryActions";
import {deleteItemsByCategoryId} from "../../actions/itemActions";
import { useSnackbar } from "react-simple-snackbar";
import {
  Typography,
  TextField,
  Button,
  Container,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  Paper,
  IconButton
} from "@material-ui/core";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { makeStyles } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "450px",
  },
  inputContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
    margin: theme.spacing(2),
  },
  addCategoryButton: {},
}));

const Categories = () => {
  const [newCategoryName, setNewCategoryName] = useState("");
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [rowsPerPage, setRowsPerPage] = useState(5); 
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const { categories } = useSelector((state) => state.categories);
  const handleAddCategory = () => {
    if (newCategoryName) {
      dispatch(createCategory({ categoryName: newCategoryName }));
      setNewCategoryName("");
      openSnackbar("Category Created successfully");
    }
  };
  useEffect(() => {
    if (!categories.length) {
      dispatch(getAllCategories());
    }
  }, [dispatch, categories]);
  const tableStyle = {
    width: 160,
    fontSize: 14,
    cursor: "pointer",
    borderBottom: "none",
    padding: "8px",
    textAlign: "center",
  };
  const headerStyle = { borderBottom: "none", textAlign: "center" };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDelete = async(id) => {
    const confirmDelete = window.confirm(
     "Are you sure you want to delete this category and all associated items?"
    );
    if (confirmDelete) {
    dispatch(deleteItemsByCategoryId(id, openSnackbar));
     dispatch(deleteCategory(id, openSnackbar));
    
    }
  };
  const filteredCategories = categories.filter(c => c.categoryName!=="Services")
  return (
    <Container maxWidth="md" className={classes.container}>
      <Box className={classes.inputContainer}>
        <TextField
          label="Category Name"
          variant="outlined"
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddCategory}
          className={classes.addCategoryButton}
          startIcon={<AddIcon />}
        >
          Add Category
        </Button>
      </Box>
      <TableContainer component={Paper} elevation={0}>
        <Table className={classes.table} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <TableCell style={headerStyle }>
                Sr#
              </TableCell>
              <TableCell style={headerStyle}>Name</TableCell>
              <TableCell style={headerStyle}>Delete</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? filteredCategories?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredCategories
            ).map((row, index) => (
              <TableRow key={row._id} styel={{ cursor: "pointer" }}>
                <TableCell style={{ ...tableStyle, width: "10px" }}>
                  {index + 1}
                </TableCell>
                <TableCell style={tableStyle} scope="row">
                  <Button style={{ textTransform: "none" }}>
                    {row.categoryName}
                  </Button>
                </TableCell>
                {/* <TableCell style={tableStyle} scope="row">
                  <Button style={{ textTransform: "none" }}>
                    {row.categoryName}
                  </Button>
                </TableCell> */}
                {/* <TableCell style={{ ...tableStyle, width: "10px" }}>
                  <IconButton >
                    <BorderColorIcon
                      style={{ width: "20px", height: "20px" }}
                    />
                  </IconButton>
                </TableCell> */}
                <TableCell style={{ ...tableStyle, width: "10px" }}>
                  <IconButton  disabled={row.categoryName==="Services"}onClick={() => handleDelete(row._id)}>
                    <DeleteOutlineRoundedIcon
                      style={{ width: "20px", height: "20px" }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                colSpan={6}
                count={filteredCategories.length} // Update count to the number of filtered rows
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Categories;
