import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useCart } from "../Context/CartContext";
import { getAllCategories } from "../actions/categoryActions";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";


const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    width: "73.3%",
    zIndex: 9999,
    backgroundColor: "#f5f5f5", // Change as needed
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "white",
    transition: "width 0.3s ease",
  },
  list: {
    display: "flex",
    overflowX: "auto",
    padding: theme.spacing(1),
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
    scrollbarWidth: "none",
  },
  listItem: {
    marginRight: theme.spacing(2),
    fontWeight: "bold",
    backgroundColor: "white",
    textAlign: "center",
    minWidth: "200px",
    borderRadius: "20px",
    border: "2px solid Black",
    cursor:'pointer',
  },
  selectedItem: {
    backgroundColor: 'rgb(239, 127, 26)',
    color: 'white',
    border:'2px solid white',
  },
  arrowIcons:{
    backgroundColor: 'rgb(239, 127, 26)',
  }
}));

const FloatingFooter = ({ open }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categories);
  const { selectedCategory, setSelectedCategory } = useCart();

  const scrollLeft = () => {
    const list = document.getElementById("categoryList");
    if (list) {
      list.scrollBy({
        left: -150, // Adjust as needed for scroll amount
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    const list = document.getElementById("categoryList");
    if (list) {
      list.scrollBy({
        left: 150, // Adjust as needed for scroll amount
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    if (!categories.length) {
      dispatch(getAllCategories());
    }
  }, [dispatch, categories]);
  const numCategories = categories.length;
  const listItemWidth =
    numCategories * 150 > 1100 ? "150px" : `${1100 / numCategories}px`;

  return (
    <Paper
      className={classes.root}
      elevation={3}
      style={{ width: open ? "73.3%" : "100%" }}
    >
      <IconButton onClick={scrollLeft} className={classes.arrowIcons}>
        <ArrowBackIcon />
      </IconButton>
      <List id="categoryList" className={classes.list}>
        <ListItem
          className={`${classes.listItem} ${selectedCategory === null ? classes.selectedItem : ''}`}
          style={{ width: listItemWidth,justifyContent:"center"}}
          onClick={() => setSelectedCategory(null)}
        >
          <Typography variant="h6" color="initial" >All</Typography>
        </ListItem>
        {categories.map((category, index) => (
          <ListItem
            key={index}
            className={`${classes.listItem} ${selectedCategory === category ? classes.selectedItem : ''}`}
            style={{ width: listItemWidth }}
            onClick={() => setSelectedCategory(category)}
          >
            <ListItemText primary={category.categoryName} />
          </ListItem>
        ))}
      </List>
      <IconButton onClick={scrollRight} className={classes.arrowIcons}>
        <ArrowForwardIcon />
      </IconButton>
    </Paper>
  );
};

export default FloatingFooter;
