import { React, useState, useEffect,useContext } from "react";
import CartTable from "./CartTable";
import CartHeader from "./CartHeader";
import CartSummary from "./CartSummary";
import useStyles from "./CartStyles";
import { StaffCommissionDetailContext } from "../Context/StaffCommissionDetailsContext";
import { useCart } from "../Context/CartContext";
import { useSnackbar } from "react-simple-snackbar";
import { usePayNow } from "../Context/PayNowContext";
import { useDiscount } from "../Context/DiscountContext";
import { useTheme } from "@material-ui/core/styles";
import { Button, Drawer, Divider } from "@material-ui/core";



function Cart({ open, handleDrawerClose, currency, invoice }) {
  const classes = useStyles();
  const {
    staffCommissionDetail,
    setStaffCommissionDetail,
    staffCommissionDetailsArray,
    setStaffCommissionDetailsArray,
  } = useContext(StaffCommissionDetailContext);
  const theme = useTheme();
  const { cartItems, setCartItems, setVat } = useCart();
  const { openPayNow, handlePayNow } = usePayNow();
  const { setDiscountVal, setDiscountFlag } = useDiscount();

  // const [openSnackbar, closeSnackbar] = useSnackbar(options);

  useEffect(() => {
    if (invoice) {
      //Automatically set the default invoice values as the ones in the invoice to be updated
      setCartItems(invoice.items);
      invoice?.discount ? setDiscountFlag(true) : setDiscountFlag(false);
      setDiscountVal(invoice.discount);
      setVat(invoice.vat);
    }
  }, [invoice]);
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <CartHeader
        classes={classes}
        theme={theme}
        handleDrawerClose={handleDrawerClose}
      />

      <Divider />

      <CartTable currency={currency} />

      <Divider />
      <CartSummary currency={currency} />
      <Divider />
      {/* <Button onClick={() => console.log(staffCommissionDetailsArray)}>Show</Button> */}
      <Button
        variant="contained"
        color="primary"
        onClick={handlePayNow}
        className={classes.payNowButton}
        disabled={cartItems.length === 0 || openPayNow}
      >
        Save and Continue
      </Button>
    </Drawer>
  );
}
export default Cart;
