import {React,useState,useEffect} from "react";
import { createItems } from "../../actions/itemActions";
import { getAllCategories } from "../../actions/categoryActions";
import { useDispatch, useSelector } from "react-redux";
import * as xlsx from 'xlsx';
import { useSnackbar } from "react-simple-snackbar";
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography'
import { makeStyles } from "@material-ui/core/styles";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  form: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  fileName: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  clearButton: {
    position: 'absolute',
    right: 2,
  },
}));

const FileUpload = ({ setOpen, open }) => {
  const classes = useStyles();
  const { categories } = useSelector((state) => state.categories);
    console.log(categories)
  const [modifiedJson, setModifiedJson] = useState(null);
  const dispatch = useDispatch();
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [selectedFile, setSelectedFile] = useState(null);


  useEffect(() => {
    if (!categories.length) {
      dispatch(getAllCategories());
    }
  }, [dispatch, categories]);

  const findCategoryId=(cName)=>{
const filteredCategory = categories.find(c => c.categoryName === cName)
console.log(filteredCategory)
return filteredCategory._id
  }
 
    const readUploadFile = (e) => {
        e.preventDefault();
        if (e.target.files) {
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = xlsx.read(data, { type: "array" });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const json = xlsx.utils.sheet_to_json(worksheet);
                const modifiedJson = json.map(item => {
                  return {
                      createdAt: item.createdAt?item.createdAt:new Date(),
                      itemName: item.itemName,
                      stockQty: {
                          initialQty: item.initialQty?item.initialQty:0,
                          currentQty: item.currentQty?item.currentQty:0,
                          maxQty: item.maxQty?item.maxQty:0,
                          minQty: item.minQty?item.minQty:0,
                      },
                      category: {
                          categoryName: item.categoryName,
                          categoryId: item.categoryId?item.categoryId:findCategoryId(item.categoryName)
                      },
                      unitPrice: item.unitPrice,
                      unit: item.unit,
                      costPrice: item.costPrice,
                      barcode: item.barcode,
                      toggleStock: item.toggleStock?item.toggleStock:true,
                      __v: item.__v
                  };
              });
              setModifiedJson(modifiedJson);
              
            };
            const file = e.target.files[0];
            setSelectedFile(file);
            reader.readAsArrayBuffer(e.target.files[0]);
        }
       
    }
    const handleClose=()=>{
        setOpen(false);
    }
    const createItemData = () => {
      if (modifiedJson) {
        console.log(modifiedJson);
         dispatch(createItems(modifiedJson, openSnackbar)); 
       
         // Dispatch action with modified JSON
      } else {
          // Handle case when modified JSON is not available
          console.log('No modified JSON available');
      }
      handleClose();
  };
    
    const downloadSampleFile = () => {
      const headers = [
          "createdAt",
          "itemName",
          "categoryName",
          "categoryId",
          "unitPrice",
          "unit",
          "initialQty",
          "currentQty",
          "maxQty",
          "minQty",
          "costPrice",
          "barcode",
          "toggleStock",
          "__v"
      ];
  
      const sampleData = [
          headers,
          [
              "2023-11-23T13:39:42.952Z",
              "Massage Oil",
              "Cosmetics",
              "6548d639a6cd65005464106c",
              "100",
              "ml",
              500,
              510,
              10000,
              10,
              "40",
              "8901764372803",
              true,
              0
          ]
          // Add more sample data as needed
      ];
      const ws = xlsx.utils.aoa_to_sheet(sampleData);
      const wb = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, "Sample");
      xlsx.writeFile(wb, "sample.xlsx");
  };
  const clearFile = () => {
    setSelectedFile(null);
    // Reset the file input
    document.getElementById('upload').value = null;
  };
  return (
    <div className={classes.container}>
      <div>
       
        {selectedFile ? (
            <>
              <span className={classes.fileName}>{selectedFile.name}</span>
              <IconButton
                className={classes.clearButton}
                onClick={clearFile}
                aria-label="Clear"
              >
                <ClearIcon />
              </IconButton>
            </>
          ):( <FormControl component="form" className={classes.form}>
          <FormLabel htmlFor="upload"   style={{cursor: 'pointer'}}>Upload File</FormLabel>
          
          <Input
            type="file"
            name="upload"
            id="upload"
          
            className={classes.input}
            onChange={readUploadFile }
          />
          <IconButton
            className={classes.button}
            color="primary"
            aria-label="Upload"
            component="span"
            onClick={readUploadFile}
          >
            <CloudUploadIcon />
          </IconButton>
         
        </FormControl>)}
        {selectedFile ?  <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<CloudDownloadIcon />}
            onClick={createItemData}
          >
            Import File
          </Button>: (
            <div style={{display:"flex",flexDirection:"column"}}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<CloudDownloadIcon />}
          onClick={downloadSampleFile}
        >
          Download Sample File
        </Button>
              <Typography variant="body1" color="initial">Please fill the required fields in the Sheet</Typography>
        </div>
        )}
      </div>
    </div>
    
  );
};

export default FileUpload;
