import * as api from "../api/index";

import {
  ADD_NEW_CATEGORY,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
  FETCH_CATEGORIES,
  FETCH_CATEGORY,
  START_LOADING,
  END_LOADING,
} from "./constants";

export const getAllCategories = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchCategories();
    console.log(data);
    dispatch({ type: FETCH_CATEGORIES, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

// export const getCategoriesByUser = (searchQuery) => async (dispatch) => {
//   try {
//     dispatch({ type: START_LOADING });
//     const {
//       data: { data },
//     } = await api.fetchCategoriesByUser(searchQuery);
//     dispatch({ type: FETCH_Category_BY_USER, payload: data });
//     dispatch({ type: END_LOADING });
//   } catch (error) {
//     console.log(error.response);
//   }
// };

export const getCategory = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"));

  try {
    const { data } = await api.fetchCategory(id);
    // console.log("getCategorydata",data)
    // const businessDetails = await api.fetchProfilesByUser(user.result._id);
    // // console.log("Business Details: " ,businessDetails)
    // const CategoryData = { ...data, businessDetails };

    // console.log("Category Data",CategoryData)
    dispatch({ type: FETCH_CATEGORY, payload: data });
  } catch (error) {
    console.log(error.response);
  }
};

export const createCategory = (Category, history) => async (dispatch) => {
  try {
    console.log("Create Category Called");
    dispatch({ type: START_LOADING });
    const { data } = await api.addCategory(Category);
    console.log("Created Category Data", data);
    dispatch({ type: ADD_NEW_CATEGORY, payload: data });
    // history.push(`/Category/${data._id}`);
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const updateCategory = (id, Category) => async (dispatch) => {
  try {
    const { data } = await api.updateCategory(id, Category);
    dispatch({ type:  UPDATE_CATEGORY, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const deleteCategory = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deleteCategory(id);

    dispatch({ type:  DELETE_CATEGORY, payload: id });
    openSnackbar("Category deleted successfully");
  } catch (error) {
    console.log(error.response);
  }
};
