
import { FETCH_ALL, ADD_NEW, UPDATE, DELETE, GET_PURCHASE, START_LOADING, END_LOADING, FETCH_PURCHASE_BY_USER } from '../actions/constants'

const purchases = (state = { isLoading: true, purchases: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case FETCH_ALL:
        return {
          ...state,
          purchases: action.payload,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
      case FETCH_PURCHASE_BY_USER:
        return { ...state, purchases: action.payload };

      case GET_PURCHASE:
        return { ...state, purchase: action.payload };
      case ADD_NEW:
        return { ...state, purchases: [...state.purchases, action.payload] };
      case UPDATE:
        return { ...state, purchases: state.purchases.map((purchase) => (purchase._id === action.payload._id ? action.payload : purchase)) };
      case DELETE:
        return { ...state, purchases: state.purchases.filter((purchase) => purchase._id !== action.payload) };
      default:
        return state;
    }
  };

  export default purchases



//   const purchases =( state = { purchases: [], }, action ) => {
//     switch (action.type) {
//         case FETCH_ALL:
//             return { ...state, purchases: action.payload }

//         case GET_PURCHASE:
//             return { ...state, purchase: action.payload }

//         case ADD_NEW:
//             return { ...state, purchases: [...state.purchases, action.payload] }

//         case UPDATE:
//             return { ...state, purchases: state.purchases.map((purchase) => purchase._id === action.payload ? action.payload : purchase) }
        
//         case DELETE: 
//         return {...state, purchases: state.purchases.filter((purchase) => purchase._id !== action.payload)}
        
//         default:
//             return state;
//     }
// }

// export default purchases
