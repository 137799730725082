
import {
    ADD_NEW_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    FETCH_CATEGORIES,
    FETCH_CATEGORY,
    START_LOADING,
    END_LOADING,
  } from "../actions/constants";

const categories = (state = { isLoading: true, categories: [] }, action) => {
    switch (action.type) {
      case START_LOADING:
        return { ...state, isLoading: true };
      case END_LOADING:
        return { ...state, isLoading: false };
      case FETCH_CATEGORIES:
        return {
          ...state,
          categories: action.payload,
          currentPage: action.payload.currentPage,
          numberOfPages: action.payload.numberOfPages,
        };
    //   case FETCH_categories_BY_USER:
    //     return { ...state, categories: action.payload };

      case FETCH_CATEGORY:
        return { ...state, category: action.payload };
      case ADD_NEW_CATEGORY:
        return { ...state, categories: [...state.categories, action.payload] };
      case UPDATE_CATEGORY:
        return { ...state, categories: state.categories.map((category) => (category._id === action.payload._id ? action.payload : category)) };
      case DELETE_CATEGORY:
        return { ...state, categories: state.categories.filter((category) => category._id !== action.payload) };
      default:
        return state;
    }
  };

  export default categories



//   const categories =( state = { categories: [], }, action ) => {
//     switch (action.type) {
//         case FETCH_ALL:
//             return { ...state, categories: action.payload }

//         case GET_category:
//             return { ...state, category: action.payload }

//         case ADD_NEW:
//             return { ...state, categories: [...state.categories, action.payload] }

//         case UPDATE:
//             return { ...state, categories: state.categories.map((category) => category._id === action.payload ? action.payload : category) }
        
//         case DELETE: 
//         return {...state, categories: state.categories.filter((category) => category._id !== action.payload)}
        
//         default:
//             return state;
//     }
// }

// export default categories
