import React, { useEffect, useState } from "react";
import { toCommas } from "../../utils/utils";
import styles from "./Dashboard.module.css";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getInvoicesByUser, getInvoices } from "../../actions/invoiceActions";
import { getUsers } from "../../actions/auth";
import { getMeetings } from "../../actions/meetingActions";
import { getAllClients } from "../../actions/clientActions";
import Empty from "../svgIcons/Empty";
import Chart from "./Chart";
// import Donut from './Donut'
import moment from "moment";
import {
  Check,
  Pie,
  Bag,
  Card,
  Clock,
  Frown,
  Order,
  Rupee,
  Report,
  SalesPerson,
  Item,
} from "./Icons";
import Spinner from "../Spinner/Spinner";

const Dashboard = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = JSON.parse(localStorage.getItem("profile"));
  const { users } = useSelector((state) => state.auth);
  //(users);
  const [rows, setRows] = useState([]);
  const [reports, setReports] = useState([]);
  const { invoices, isLoading } = useSelector((state) => state?.invoices);
  const { meetings } = useSelector((state) => state?.meetings);
  //(invoices);
  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    if (user?.result?.userType === "user") {
      const userRows = invoices.filter((inv) => inv.creator[0] === user?.result?._id);
      const userRowsToday = userRows.filter((inv) => moment(inv.createdAt).isSame(today, 'day'));
      setRows(userRowsToday);
      
    } else {
      const rows = invoices.filter((inv) => moment(inv.createdAt).isSame(today, 'day'));
      setRows(rows);
    }

    // eslint-disable-next-line
  }, [invoices]);
  useEffect(() => {
    const today = moment().format("YYYY-MM-DD");
    if (user?.result?.userType === "user") {
      const reports = meetings.filter(
        (inv) => inv.creator[0] === user?.result?._id
      );
      const userReportsToday = reports.filter((inv) => moment(inv.createdAt).isSame(today, 'day'));
      setReports(userReportsToday);
    } else {
      const todayMeetings=meetings.filter((inv) => moment(inv.createdAt).isSame(today, 'day'));
      console.log(todayMeetings)
      setReports(todayMeetings);
    }

    // eslint-disable-next-line
  }, [meetings]);
  useEffect(() => {
    dispatch(
      // getClientsByUser({ search: user?.result._id || user?.result?.googleId })
      getAllClients()
    );

    // eslint-disable-next-line
  }, [dispatch]);
  // const unpaid = invoices?.filter((invoice) => (invoice.status === 'Unpaid') || (invoice.status === 'Partial'))
  const overDue = rows?.filter(
    (invoice) => invoice.dueDate <= new Date().toISOString()
  );
  const admins = users.filter((user) => user.userType === "admin");
  const regularUsers = users.filter((user) => user.userType === "user");
  const activeUsers = regularUsers.filter((user) => user.isActive === true);
  let paymentHistory = [];
  for (let i = 0; i < rows.length; i++) {
    let history = [];
    if (rows[i].paymentRecords !== undefined) {
      history = [...paymentHistory, rows[i].paymentRecords];
      paymentHistory = [].concat.apply([], history);
    }
  }

  //sort payment history by date
  const sortHistoryByDate = paymentHistory.sort(function (a, b) {
    var c = new Date(a.datePaid);
    var d = new Date(b.datePaid);
    return d - c;
  });

  let totalPaid = 0;
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].totalAmountReceived !== undefined) {
      totalPaid += rows[i].totalAmountReceived;
    }
  }

  let totalAmount = 0;
  //const currentDate = new Date(); // Get the current date

  for (let i = 0; i < rows.length; i++) {
   // const rowDate = new Date(rows[i].createdAt); // Convert row date to a Date object

    // Compare year, month, and day of current date and row date
    // if (
    //   rowDate.getFullYear() === currentDate.getFullYear() &&
    //   rowDate.getMonth() === currentDate.getMonth() &&
    //   rowDate.getDate() === currentDate.getDate()
    // )
     {
      totalAmount += rows[i].total;
    }
  }

  useEffect(() => {
    // {user?.result?.userType === "admin" &&
    dispatch(getInvoices());
    dispatch(getUsers());
    dispatch(getMeetings());
    // }

    // dispatch(getInvoicesByUser({ search: user?.result?._id || user?.result?.googleId}));
    // dispatch(getUsers());
    // dispatch(getMeetings())

    // dispatch(getInvoicesByUser({search: user?.result._id || user?.result?.googleId}));
    // eslint-disable-next-line
  }, [location, dispatch]);

  const unpaidInvoice = invoices?.filter(
    (invoice) => invoice.status === "Unpaid"
  );
  const paid = invoices?.filter((invoice) => invoice.status === "Paid");
  const partial = invoices?.filter((invoice) => invoice.status === "Partial");

  if (!user) {
    history.push("/login");
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <Spinner />
      </div>
    );
  }

  // if (invoices.length === 0) {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         alignItems: "center",
  //         justifyContent: "center",
  //         flexDirection: "column",
  //         paddingTop: "20px",
  //       }}
  //     >
  //       {/* <Spinner /> */}
  //       <Empty />
  //       <p style={{ padding: "40px", color: "gray" }}>
  //         Nothing to display. Click the plus icon to start creating
  //       </p>
  //     </div>
  //   );
  // }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.dateContainer}>
        <h2 className={styles.date} >{moment().format("MMM Do YYYY")}</h2>
      </div>
      <section className={styles.stat}>
        <ul className={styles.autoGrid}>
          {/* <li
            className={styles.listItem}
            style={{ backgroundColor: "#1976d2", color: "white" }}
          >
            <div>
              <p>{toCommas(totalPaid)}</p>
              <h2 style={{ color: "white" }}>Payment Received</h2>
            </div>
            <div>
              <Check />
            </div>
          </li> */}

          {/* <li className={styles.listItem}>
            <div>
              <p>{toCommas(totalAmount - totalPaid)}</p>
              <h2>Pending Amount</h2>
            </div>
            <div>
              <Pie />
            </div>
          </li> */}
          <Link to="/PosMainScreen" className={styles.link}>
            <li className={styles.listItem}>
              <div>
                <h1>IMIN</h1>
              </div>

              <div>
                <Report />
              </div>
            </li>
          </Link>
          <li className={styles.listItem}>
            <div>
              <p>{toCommas(totalAmount)}</p>
              <h2> Order MRP Value(₹)</h2>
            </div>
            <div>
              <Rupee />
            </div>
          </li>
          <Link to="/invoices" className={styles.link}>
            <li className={styles.listItem} >
              <div>
                <p>{rows.length}</p>
                <h2>Total Invoices</h2>
              </div>
              <div>
                <Order />
              </div>
            </li>
          </Link>
          {/* <Link to="/meetings" className={styles.link}>
            <li className={styles.listItem}>
              <div>
                <p>{reports.length}</p>
                <h2>Daily Report</h2>
              </div>

              <div>
                <Report />
              </div>
            </li>
          </Link> */}
          {/* {user?.result?.userType === "admin" && (
            <Link to="/adminDashboard" className={styles.link}> 
              <li className={styles.listItem}>
                <div>
                  <div>
                    <p>{regularUsers.length}</p>
                    <h2>Sales Executive</h2>
                  </div>
                  <div>
                    <p></p>
                    <h2 style={{ marginBottom: "5px" }}>
                      Active:{activeUsers.length}
                    </h2>

                    <h2>Inactive:{regularUsers.length - activeUsers.length}</h2>
                  </div>
                </div>
                <SalesPerson />
              </li>
            </Link>
          )} */}
          {/* <li
            className={styles.listItem}
            style={{ backgroundColor: "#206841", color: "white" }}
          >
            <div>
              <p>{paid.length}</p>
              <h2 style={{ color: "white" }}>Paid Invoices</h2>
            </div>
            <div>
              <Check />
            </div>
          </li> */}

          {/* <li className={styles.listItem}>
            <div>
              <p>{partial.length}</p>
              <h2>Partially Paid Invoices</h2>
            </div>
            <div>
              <Pie />
            </div>
          </li> */}

          {/* <li className={styles.listItem}>
            <div>
              <p>{unpaidInvoice.length}</p>
              <h2>Unpaid Invoices</h2>
            </div>
            <div>
              <Frown />
            </div>
          </li> */}

          {/* <li className={styles.listItem}>
            <div>
              <p>{overDue.length}</p>
              <h2>Overdue</h2>
            </div>
            <div>
              <Clock />
            </div>
          </li> */}
        </ul>
      </section>

      {/* {paymentHistory.length !== 0 && (
        <section>
          <Chart paymentHistory={paymentHistory} />
        </section>
      )}

      <section>
        <h1 style={{ textAlign: "center", padding: "30px" }}>
          {paymentHistory.length
            ? "Recent Payments"
            : "No payment received yet"}
        </h1>
        <div>
          <div className={styles.table}>
            <table>
              <tbody>
                {paymentHistory.length !== 0 && (
                  <tr>
                    <th style={{ padding: "15px" }}></th>
                    <th style={{ padding: "15px" }}>Paid By</th>
                    <th style={{ padding: "15px" }}>Date Paid</th>
                    <th style={{ padding: "15px" }}>Amount Paid</th>
                    <th style={{ padding: "15px" }}>Payment Method</th>
                    <th style={{ padding: "15px" }}>Note</th>
                  </tr>
                )}

                {sortHistoryByDate.slice(-10).map((record) => (
                  <tr className={styles.tableRow} key={record._id}>
                    <td>
                      <button>{record?.paidBy?.charAt(0)}</button>
                    </td>
                    <td>{record.paidBy}</td>
                    <td>{moment(record.datePaid).format("MMMM Do YYYY")}</td>
                    <td>
                      <h3 style={{ color: "#00A86B", fontSize: "14px" }}>
                        {toCommas(record.amountPaid)}
                      </h3>
                    </td>
                    <td>{record.paymentMethod}</td>
                    <td>{record.note}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Dashboard;
