import React, { useState, useEffect,useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Cart from "./Cart";
import AppBarComp from "./AppBarComp";
import FabButton from "../components/Fab/Fab";
import PaymentDetails from "./PaymentDetails";
import { useCurrency } from "../Context/CurrencyContext";
import ItemCards from "./ItemCards";
import { usePayNow } from "../Context/PayNowContext";
import BillDialog from "./BillDialog";
import CardDialog from "./CardDialog";
import FloatingFooter from "./FloatingFooter";
import {
  createInvoice,
  getInvoice,
  updateInvoice,
} from "../actions/invoiceActions";
import { getStaffCommissionDetail } from "../actions/staffCommissionDetailActions";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "100vh",
  },

  emptyCart: {
    textAlign: "center",
  },
  swipeContainer: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  payNowButton: {
    margin: theme.spacing(2),
  },
  swipeableItem: {
    transition: "transform 0.3s ease, background-color 0.3s ease",
    "&.swiped": {
      backgroundColor: "red",
      transform: "translateX(-100%)",
    },
  },
}));

function PosMainScreen() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const { currency, updateCurrency } = useCurrency();
  const { id } = useParams();
  const [open, setOpen] = useState(true);
  const {
    openPayNow,
    handlePayNow,
    cashBillDialog,
    handleCashBillDialog,
    cardBillDialog,
    handleCardBillDialog,
  } = usePayNow();

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const { invoice } = useSelector((state) => state.invoices);
  const  staffCommissionDetails  = useSelector(
    (state) => state.staffCommissionDetails.staffCommissionDetails
  );
 
  console.log(staffCommissionDetails);
  useEffect(() => {
    dispatch(getInvoice(id));
    dispatch(getStaffCommissionDetail(id));
    // eslint-disable-next-line
  }, [id]);

  return (
    <div className={classes.root}>
      <AppBarComp
        open={open}
        setOpen={setOpen}
        handlePayNow={handlePayNow}
        invoice={invoice}
      />
      {openPayNow ? (
        <PaymentDetails />
      ) : cashBillDialog ? (
        <BillDialog />
      ) : cardBillDialog ? (
        <CardDialog />
      ) : (
        <ItemCards staffCommissionDetails={staffCommissionDetails} />
      )}
      <Cart
        open={open}
        handleDrawerClose={handleDrawerClose}
        currency={currency}
        handlePayNow={handlePayNow}
        invoice={invoice}
      />
      {!openPayNow && <FloatingFooter open={open} />}
      <FabButton />
    </div>
  );
}

export default PosMainScreen;
