import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles({
  card: {
    marginBottom: '10px',
    border: '1px solid #ccc', // Border
    borderRadius: '8px', // Rounded corners
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // Elevation
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  changeButton: {
    textTransform: 'none',
    backgroundColor: '#ef7f1a',
    color: 'white',
    marginTop: '10px',
  },
  boldText: {
    fontWeight: 'bold', // Bold text
  },
});

const ClientInfoCard = ({ client, setClient }) => {
  const classes = useStyles();

  const handleClientChange = () => {
    setClient(null);
  };

  return (
    <Card className={classes.card} elevation={3}>
      <CardContent className={classes.cardContent}>
        <Typography variant="subtitle1" gutterBottom className={classes.boldText}>
          {client.name}
        </Typography>
        <Typography variant="body1" className={classes.boldText}>
          {client.phone}
        </Typography>
        <Button
          color="primary"
          size="small"
          className={classes.changeButton}
          onClick={handleClientChange}
        >
          Change
        </Button>
      </CardContent>
    </Card>
  );
};

export default ClientInfoCard;
