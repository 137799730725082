import React, { useState, useContext, useEffect } from "react";
import styles from "../../src/components/Staff/Staff.module.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AppContext } from "../Context/AppContext";
import { EditContext } from "../Context/EditContext";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Container,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useParams } from "react-router-dom";
import moment from "moment";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { StatsContext } from "../Context/StatsContext";
import { StaffCommissionDetailContext } from "../Context/StaffCommissionDetailsContext";
import {
  Grid,
  AppBar,
  Tabs,
  Tab,
  Typography,
  Box,
  Paper,
  Drawer,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { getInvoices, deleteInvoice } from "../actions/invoiceActions";
import {
  getStaffCommissionDetails,
  deleteStaffCommissionDetail,
} from "../actions/staffCommissionDetailActions";
import { getMeetings, deleteMeeting } from "../actions/meetingActions";
import { getStaff, getAllStaff } from "../actions/staffActions";
import { getUsers } from "../actions/auth";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import MeetingForm from "../components/Meetings/MeetingForm";
import NoData from "../components/svgIcons/NoData";
import { useSnackbar } from "react-simple-snackbar";
import MenuIcon from "@material-ui/icons/Menu";
import { ScheduleOutlined } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3),
    height: "100%",
  },
  textMargin: {
    marginLeft: theme.spacing(10),
  },
  gridContainer: {
    [theme.breakpoints.up("sm")]: {
      marginLeft: "0rem", // Apply left margin of 10rem on screens larger than or equal to small (sm)
    },
  },
  openDrawerButton: {
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(1),
    },
  },
  staffDetailsContainer: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
  },
}));
const tableStyle = {
  width: 160,
  fontSize: 14,
  cursor: "pointer",
  borderBottom: "none",
  padding: "8px",
  textAlign: "center",
};
const headerStyle = { borderBottom: "none", textAlign: "center" };

const StaffStats = () => {
  const classes = useStyles();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const { currStat, setCurrStat } = useContext(StatsContext);
  const history = useHistory();
  const { staffId } = useParams();
  const { startDate, endDate } = useContext(StaffCommissionDetailContext);
  ////console.log("staffId", staffId);
  const { showForm, setShowForm } = useContext(AppContext);
  const { currId, setCurrId } = useContext(EditContext);
  const [staffDetails, setClientDetails] = useState(null);
  // const [staff, setStaff] = useState(null);
  const [filteredStaffCommissionDetails, setFilteredStaffCommissionDetails] =
    useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const user = JSON.parse(localStorage.getItem("profile"));
  const { users } = useSelector((state) => state.auth);
  const staff = useSelector((state) => state.staff.staff);
  ////console.log("staff", staff);
  ////console.log("users", users);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [searchQuery, setSearchQuery] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [staffName, setStaffName] = useState("");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const dispatch = useDispatch();
  const staffCommissionDetails = useSelector(
    (state) => state.staffCommissionDetails.staffCommissionDetails
  );
  //console.log("staffCommissionDetails", staffCommissionDetails);
  // const { meetings } = useSelector((state) => state.meetings);
  // //console.log(meetings);
  // const isLoading = useSelector((state) => state.meetings.isLoading);
  // Simulating fetching client details based on the client ID
  useEffect(() => dispatch(getInvoices()), [location, dispatch, staffId]);
  useEffect(
    () => dispatch(getStaffCommissionDetails()),
    [location, dispatch, staffId]
  );
  useEffect(() => dispatch(getMeetings()), [location, dispatch]);
  useEffect(() => {
    dispatch(getUsers()); // Replace `getUsers` with the actual action to fetch users
  }, []);
  useEffect(() => dispatch(getStaff(staffId)), [location]);

  useEffect(() => {
    const filteredStaffCommissionDetails = staffCommissionDetails.filter(
      (scd) => scd.staffDetails.some((sd) => sd.staffId === staff?._id)
    );

    //console.log("filtered", filteredStaffCommissionDetails);
    setFilteredStaffCommissionDetails(filteredStaffCommissionDetails);

    // const filteredRep = meetings.filter(
    //   (rep) => rep.client.phone === client?.phone
    // );
    // //console.log("filtered Reports", filteredRep);
    // setFilteredReports(filteredRep);
    // setStaff(staff);
    // setStaffName(staff.name);
  }, [staffId, staffCommissionDetails, staff]);
  //
  const toCommas = (value) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  function checkStatus(status) {
    return status === "Partial"
      ? {
          border: "solid 0px #1976d2",
          backgroundColor: "#baddff",
          padding: "8px 10px",
          borderRadius: "20px",
          margin: "10px",
        }
      : status === "Paid"
      ? {
          border: "solid 0px green",
          backgroundColor: "#a5ffcd",
          padding: "8px 18px",
          borderRadius: "20px",
        }
      : status === "Unpaid"
      ? {
          color: "#ff0000",
          width: 160,
          fontSize: 14,
          cursor: "pointer",
          borderBottom: "none",
          padding: "8px",
          textAlign: "center",
        }
      : "red";
  }
  const handleViewInvoice = (id) => {
    history.push(`/invoice/${id}`);
  };
  const handleEditInvoice = (id) => {
    history.push(`/edit/invoice/${id}`);
  };
  const handleDeleteScd = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Detail?"
    );
    if (confirmDelete) {
      dispatch(deleteStaffCommissionDetail(id, openSnackbar));
    }
  };
  // const handleDeleteInvoice = (invoice) => {};

  const findUserNameById = (userId) => {
    //console.log("parameter" + userId);
    const user = users.find((user) => user._id == userId);
    //console.log(user);
    return user ? user.name : "";
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const handleCloseForm = () => {
    setShowForm(false);
  };
  const handleInputChange = (value) => {
    //console.log(value);
    setCurrId(value);
    setShowForm(true);
  };
  const handleDeleteMeeting = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this client?"
    );
    if (confirmDelete) {
      dispatch(deleteMeeting(id, openSnackbar));
    }
  };
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };
  const filteredRows = filteredStaffCommissionDetails.filter((row) => {
    const commissionDate = moment(row.createdAt);
    return commissionDate.isBetween(startDate, endDate, "days", "[]");
    // findUserNameById(row.creator)
    //   .toLowerCase()
    //   .includes(searchQuery.toLowerCase());
  });
  const filteredReportsRows = filteredReports.filter((row) =>
    findUserNameById(row.creator)
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  //
  return (
    <>
      <div className={styles.pageLayout}>
        <Container style={{ width: isMobile ? "100%" : "75%" }}>
          <Paper elevation={3}>
            <Container>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <IconButton
                  aria-label=""
                  onClick={handleOpenDrawer}
                  className={classes.openDrawerButton}
                >
                  <AccountCircleIcon style={{ fontSize: 40 }} />
                </IconButton>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  {staff?.name}
                </Typography>
                <Typography
                  variant="h6"
                  style={{
                    fontWeight: "bold",
                    color: "#333",
                  }}
                >
                  {`Commission Details from ${moment(startDate).format(
                    "Do MMM YYYY"
                  )} to ${moment(endDate).format("Do MMM YYYY")}`}
                </Typography>
              </div>
            </Container>
          </Paper>
          <div>
            <Drawer
              className={classes.drawer}
              variant="temporary"
              anchor="Top"
              open={openDrawer}
              onClose={handleCloseDrawer}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {/* ... */}

              {/* <Paper elevation={3} className={classes.staffDetailsPaper}> */}
              <div className={classes.staffDetailsContainer}>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.textMargin}
                >
                  Staff Details
                </Typography>
                {staff ? (
                  <div>
                    <Typography variant="body1" className={classes.textMargin}>
                      <strong>Staff :</strong> {staff.name}
                    </Typography>
                    <Typography variant="body1" className={classes.textMargin}>
                      <strong>Phone:</strong> {staff.phone}
                    </Typography>
                    <Typography variant="body1" className={classes.textMargin}>
                      <strong>Code:</strong> {staff.code}
                    </Typography>
                  </div>
                ) : (
                  <Typography variant="body1" className={classes.textMargin}>
                    Loading staff details...
                  </Typography>
                )}
              </div>
              {/* </Paper> */}
            </Drawer>
          </div>
          {/* Invoice and Reports Tabs */}
          <Grid
            item
            xs={12}
            sm={12}
            className={isMobile ? "" : classes.gridContainer}
          >
            <TextField
              id="search-bar"
              label="Search Bar"
              variant="outlined"
              fullWidth
              margin="normal"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />

            {filteredStaffCommissionDetails.length > 0 && (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={headerStyle}>Number</TableCell>

                      <TableCell style={headerStyle}>Created At</TableCell>
                      <TableCell style={headerStyle}>Service</TableCell>
                      <TableCell style={headerStyle}>Unit Price</TableCell>
                      <TableCell style={headerStyle}>Total Quantity</TableCell>
                      <TableCell style={headerStyle}>Qty</TableCell>
                      <TableCell style={headerStyle}>Commission</TableCell>
                      <TableCell style={headerStyle}>
                        Total Commission
                      </TableCell>
                      {/* <TableCell style={headerStyle}>Status</TableCell> */}
                      <TableCell style={headerStyle}>Created By</TableCell>
                      {user?.result?.userType === "admin" ? (
                        <TableCell style={headerStyle}>Actions</TableCell>
                      ) : (
                        <TableCell style={headerStyle}>View</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows.map((scd) => (
                      <TableRow key={scd._id}>
                        <TableCell style={tableStyle}>
                          {scd.invoiceNumber}
                        </TableCell>
                        <TableCell style={tableStyle}>
                          {moment(scd.createdAt).format("MMM Do YYYY")}
                        </TableCell>
                        <TableCell style={tableStyle}>
                          {scd.serviceName}
                        </TableCell>
                        <TableCell style={tableStyle}>
                          {scd.serviceUnitPrice}
                        </TableCell>
                        <TableCell style={tableStyle}>{scd.totalQty}</TableCell>
                        <TableCell style={tableStyle}>
                          {
                            scd.staffDetails.find(
                              (sd) => sd.staffId === staff?._id
                            ).staffQty
                          }
                        </TableCell>
                        <TableCell style={tableStyle}>
                          {
                            scd.staffDetails.find(
                              (sd) => sd.staffId === staff?._id
                            ).staffCommission
                          }
                        </TableCell>
                        <TableCell style={tableStyle}>
                          {
                            scd.staffDetails.find(
                              (sd) => sd.staffId === staff?._id
                            ).staffTotal
                          }
                        </TableCell>
                        {/* <TableCell style={tableStyle}>
                        {scd.total
                          ? `₹${scd.total
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                          : ""}
                      </TableCell> */}
                        {/* <TableCell style={tableStyle}>
                        {moment(scd.dueDate).fromNow()}{" "}
                      </TableCell> */}
                        {/* <TableCell style={checkStatus(scd.status)}>
                        {scd.status}
                      </TableCell> */}
                        <TableCell style={tableStyle}>
                          {findUserNameById(scd.creator)}
                        </TableCell>
                        {user?.result?.userType === "admin" ? (
                          <TableCell style={tableStyle}>
                            {" "}
                            <IconButton
                              onClick={() => handleViewInvoice(scd.invoiceId)}
                            >
                              <RemoveRedEyeOutlinedIcon
                                style={{ width: "15px", height: "15px" }}
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => handleEditInvoice(scd.invoiceId)}
                            >
                              <BorderColorIcon
                                style={{ width: "15px", height: "15px" }}
                              />
                            </IconButton>
                            <IconButton
                              onClick={() => handleDeleteScd(scd._id)}
                            >
                              <DeleteOutlineRoundedIcon
                                style={{ width: "15px", height: "15px" }}
                              />
                            </IconButton>
                          </TableCell>
                        ) : (
                          <TableCell style={tableStyle}>
                            {" "}
                            <IconButton
                              onClick={() => handleViewInvoice(scd.invoiceId)}
                            >
                              <RemoveRedEyeOutlinedIcon
                                style={{ width: "15px", height: "15px" }}
                              />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {filteredRows.length === 0 && (
              // Display no data message for "Daily Reports" tab
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  paddingTop: "20px",
                  margin: "80px",
                }}
              >
                <NoData />
                <p
                  style={{
                    padding: "40px",
                    color: "gray",
                    textAlign: "center",
                  }}
                >
                  No reports for the selected Staff
                </p>
              </div>
            )}
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default StaffStats;
