import React, { useState, useEffect } from "react";
import MeetingForm from "./MeetingForm";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { AppContext } from "../../Context/AppContext";
import { EditContext } from "../../Context/EditContext";
import { getMeetings, deleteMeeting } from "../../actions/meetingActions";
import { getUsers } from "../../actions/auth";
import { getAllClients } from "../../actions/clientActions";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { useMediaQuery } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import {
  makeStyles,
  useTheme,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import TablePagination from "@material-ui/core/TablePagination";
import TableContainer from "@material-ui/core/TableContainer";
import Container from "@material-ui/core/Container";
import TableFooter from "@material-ui/core/TableFooter";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import NoData from "../svgIcons/NoData";
import Spinner from "../Spinner/Spinner";
import { useSnackbar } from "react-simple-snackbar";
import "./Meetings.css";

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));
function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const useStyles2 = makeStyles((theme) => ({
  table: {
    minWidth: 300,
  },

  tablecell: {
    fontSize: "16px",
  },
}));
const colorTheme = createTheme({
  palette: {
    primary: {
      main: "#ef7f1a",
    },
  },
});
const tableStyle = {
  width: "auto",
  fontSize: 14,
  cursor: "pointer",
  borderBottom: "none",
  padding: "8px",
  textAlign: "center",
};
const headerStyle = { borderBottom: "none", textAlign: "center" };

const Meetings = () => {
  const { showForm, setShowForm } = useContext(AppContext);
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const user = JSON.parse(localStorage.getItem("profile"));
  const [salesperson, setSalesperson] = useState(null);
  const [party, setParty] = useState(null);
  const { meetings } = useSelector((state) => state.meetings);
  // //(meetings);
  const [rows, setRows] = useState([]);
  const { users } = useSelector((state) => state.auth);
  const clients = useSelector((state) => state.clients.clients);
  const isLoading = useSelector((state) => state.meetings.isLoading);
  const [downloadStatus, setDownloadStatus] = useState(null);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const { currId, setCurrId } = useContext(EditContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(""); // State to track the selected filter

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value); // Update the selected filter when the user changes the option
  };
  // //(meetings);

  useEffect(() => {
    dispatch(getMeetings());
    // dispatch(getInvoicesByUser({ search: user?.result?._id || user?.result?.googleId}));
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (user?.result?.userType === "user") {
      const rows = meetings.filter(
        (inv) => inv.creator[0] === user?.result?._id
      );
      setRows(rows);
    } else {
      setRows(meetings);
    }

    // eslint-disable-next-line
  }, [meetings]);
  useEffect(() => {
    dispatch(getUsers());
  }, [location]);
  const handleAddMeeting = () => {
    setShowForm(true);
  };
  // useEffect(() => {
  //   const initializedMeetingData = meetings.map((meeting) => ({
  //     ...meeting,
  //     editMode: false,
  //   }));
  //   setMeetingData(initializedMeetingData);
  // }, [meetings]);
  useEffect(() => {
    dispatch(
      // getClientsByUser({ search: user?.result._id || user?.result?.googleId })
      getAllClients()
    );

    // eslint-disable-next-line
  }, [dispatch]);

  const handleCloseForm = () => {
    setShowForm(false);
  };

  // const handleMeetingSubmit = (newMeetingData) => {
  //   setMeetingData((prevMeetingData) => [...prevMeetingData, newMeetingData]);
  //   setShowForm(false);
  // };
  const handleInputChange = (value) => {
    //(value);
    setCurrId(value);
    setShowForm(true);
  };
  const handleDelete = (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this client?"
    );
    if (confirmDelete) {
      dispatch(deleteMeeting(id, openSnackbar));
    }
  };
  const handleStartDateChange = (date) => {
    // const formattedDate = date.toISOString().split("T")[0];
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    // const formattedDate = date.toISOString().split("T")[0];
    setEndDate(date);
  };
  const findUserNameById = (userId) => {
    // //("parameter" + userId);
    const user = users.find((user) => user._id == userId);
    // //(user);
    return user ? user.name : "";
  };
  const createAndDownloadPdf = () => {
    //(rows);
    //(filteredRows);
    setLoading(true);
    if (filteredRows) {
      setDownloadStatus("loading");
      axios
        .post(`${process.env.REACT_APP_API}/create-dailyreport`, {
          filteredRows,
          users,
          startDate,
          endDate,
          salesperson,
          party
        })
        .then(() =>
          axios.get(`${process.env.REACT_APP_API}/fetch-dailyreport`, {
            responseType: "blob",
          })
        )
        .then((res) => {
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          // const downloadUrl = window.URL.createObjectURL(pdfBlob);
          // setDownloadStatus("success");
          // window.open(downloadUrl, "_blank");
          const currentDate = new Date();
          const NewDate = moment(currentDate).format("MMM Do YYYY");
          saveAs(pdfBlob, `DailyReports${NewDate}.pdf`);
          const downloadUrl = `http://orders.sadhanaayurvedics.com/files/Exported-DailyReport/daily_report.pdf`;
          window.open(downloadUrl, "_blank");
          setLoading(false);
          setDownloadStatus("success");
        });
    } else {
      openSnackbar("No record to Export");
    }
  };
  // const handleToggleEdit = (index) => {
  //   setMeetingData((prevMeetingData) => {
  //     const updatedData = [...prevMeetingData];
  //     updatedData[index].editMode = !updatedData[index].editMode;
  //     return updatedData;
  //   });
  // };

  // const handleSaveData = (index) => {
  //   setMeetingData((prevMeetingData) => {
  //     const updatedData = [...prevMeetingData];
  //     updatedData[index].editMode = false;
  //     return updatedData;
  //   });
  // };
  const formatCurrency = (value) => {
    if (!value) {
      return ""; // Return empty string if value is undefined or empty
    }

    const numericValue = value.replace(/\D/g, "");
    const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return `₹${formattedValue}`;
  };

  const containerClassName =
    rows.length === 0 ? "centered-container" : "meetings-container";

  const toCommas = (value) => {
    if (!value) {
      return;
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const filterByDateRange = (invoice) => {
    if (!startDate || !endDate) return true;
    const invoiceDate = moment(invoice.createdAt);
    return invoiceDate.isBetween(startDate, endDate, "day", "[]");
  };
  // const filteredRows = rows.filter(
  //   (row) =>
  //     filterByDateRange(row) &&
  //      user?.result?.userType === "admin" ? (
  //     findUserNameById(row.creator)
  //       .toLowerCase()
  //       .includes(
  //         searchQuery && typeof searchQuery === "string"
  //           ? searchQuery.toLowerCase()
  //           : ""
  //       )):(row.client.name.toLowerCase()
  //       .includes(
  //         searchQuery && typeof searchQuery === "string"
  //           ? searchQuery.toLowerCase()
  //           : ""
  //       ))
  // );
  const filteredRows = rows.filter((row) => {
    const isSalespersonFilter = selectedFilter === "salesperson";
    const searchQueryLowerCase = (searchQuery && typeof searchQuery === "string")
      ? searchQuery.toLowerCase()
      : "";
  
    if (isSalespersonFilter) {
      const creatorName = findUserNameById(row.creator).toLowerCase();
      return filterByDateRange(row) && creatorName.includes(searchQueryLowerCase);
    } else {
      const clientName = row.client.name.toLowerCase();
      return filterByDateRange(row) && clientName.includes(searchQueryLowerCase);
    }
  });
  
  const classes = useStyles2();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (rows.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
          margin: "80px",
        }}
      >
        <NoData />
        <p style={{ padding: "40px", color: "gray", textAlign: "center" }}>
          No Report yet. Click the plus icon to create Report
        </p>
      </div>
    );
  }
  const salespersonsProps = {
    options: users,
    getOptionLabel: (option) => option.name,
  };
  const clientsProps = {
    options: clients,
    getOptionLabel: (option) => option.name,
  };
  return (
    <div className="root">
      {/* <Typography variant="h2" align="center" gutterBottom style={{ marginTop: "2px", marginBottom: "10px" }}>
        Daily Reports
      </Typography> */}

      <Dialog open={showForm} onClose={handleCloseForm}>
        <DialogContent>
          <MeetingForm
            onClose={handleCloseForm}
            // onMeetingSubmit={handleMeetingSubmit}
            // currId={currId}
            // setCurrId={setCurrId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForm}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Container
        style={{
          width: isMobile ? "100%" : "85%",
          paddingTop: "2px",
          paddingBottom: "2px",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: "10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                label="From"
                name="FromDate"
                value={startDate}
                onChange={handleStartDateChange}
                required
                format="yyyy/MM/dd"
                style={{ width: isMobile ? "50%" : 150 }}
              />

              <KeyboardDatePicker
                label="To"
                name="EndDate"
                value={endDate}
                onChange={handleEndDateChange}
                required
                format="yyyy/MM/dd"
                style={{ width: isMobile ? "50%" : 150 }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
           {user?.result?.userType === "admin" && (
              <FormControl style={{ width: "100px" }}>
                <InputLabel>Filter:</InputLabel>
                <Select
                  value={selectedFilter}
                  onChange={(event) => handleFilterChange(event)} // Provide the event object
                >
                  <MenuItem value="salesperson">Filter by SalesPerson</MenuItem>
                  <MenuItem value="customer">Filter by Party</MenuItem>
                </Select>
                {selectedFilter && (
                  <IconButton
                    size="small"
                    style={{
                      marginLeft: "8px",
                      backgroundColor: "transparent",

                      color: "#ef7f1a",
                      borderRadius: "4px",
                    }}
                    onClick={() => {
                      setSelectedFilter("");
                      setSearchQuery("");
                      setSalesperson("");
                      setParty("");
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </FormControl>
           )}
            {selectedFilter && (
              <div>
                {/* Display filtering results or perform filtering logic based on selectedFilter */}
                {selectedFilter === "salesperson" ? (
                  <ThemeProvider theme={colorTheme}>
                   
                      <Container>
                        {salesperson && (
                          <div
                            style={{
                              display: "flex",
                              marginTop: "20px",
                              gap: "5px",
                              height: "50px",
                            }}
                          >
                            <Typography variant="subtitle1" gutterBottom>
                              {salesperson.name}
                            </Typography>

                            <Button
                              color="primary"
                              size="small"
                              style={{
                                textTransform: "none",
                                backgroundColor: "#ef7f1a",
                                color: "white",
                              }}
                              onClick={() => {
                                setSalesperson(null);
                                setSearchQuery("");
                              }}
                            >
                              Change
                            </Button>
                          </div>
                        )}
                        <div
                          style={
                            salesperson
                              ? { display: "none" }
                              : { display: "block", width: "200px" }
                          }
                        >
                          <Autocomplete
                            {...salespersonsProps}
                            // PaperComponent={CustomPaper}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                // required={!invoice && true}
                                label="Select Sales Person"
                                margin="normal"
                                variant="outlined"

                                // error={touched && !!errors.client}
                                // helperText={touched && errors.client}
                              />
                            )}
                            value={users?.name}
                            onChange={(event, value) => {
                              setSalesperson(value);
                              setSearchQuery(value ? value.name : "");

                              console.log("value: ", value);
                              // handleClientChange();
                            }}
                            // onChange={(event, value) => setClient(value) }
                          />
                        </div>
                      </Container>
                    
                  </ThemeProvider>
                ) : selectedFilter === "customer" ? (
                  <ThemeProvider theme={colorTheme}>
                    <Container>
                      {party && (
                        <div
                          style={{
                            display: "flex",
                            marginTop: "20px",
                            gap: "10px",
                            height: "50px",
                          }}
                        >
                          <Typography variant="subtitle1" gutterBottom>
                            {party.name}
                          </Typography>

                          <Button
                            color="primary"
                            size="small"
                            style={{
                              textTransform: "none",
                              backgroundColor: "#ef7f1a",
                              color: "white",
                            }}
                            onClick={() => {
                              setParty(null);
                              setSearchQuery("");
                            }}
                          >
                            Change
                          </Button>
                        </div>
                      )}
                      <div
                        style={
                          party
                            ? { display: "none" }
                            : { display: "block", width: "200px" }
                        }
                      >
                        <Autocomplete
                          {...clientsProps}
                          // PaperComponent={CustomPaper}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // required={!invoice && true}
                              label="Select Party"
                              margin="normal"
                              variant="outlined"
                              // error={touched && !!errors.client}
                              // helperText={touched && errors.client}
                            />
                          )}
                          value={clients?.name}
                          onChange={(event, value) => {
                            setParty(value);
                            setSearchQuery(value ? value.name : "");

                            // handleClientChange();
                          }}
                          // onChange={(event, value) => setClient(value) }
                        />
                      </div>
                    </Container>
                  </ThemeProvider>
                ) : null}
              </div>
            )}
            { user?.result?.userType !== "admin" && (<ThemeProvider theme={colorTheme}>
                    <Container>
                      {party && (
                        <div
                          style={{
                            display: "flex",
                            marginTop: "20px",
                            gap: "10px",
                            height: "50px",
                          }}
                        >
                          <Typography variant="subtitle1" gutterBottom>
                            {party.name}
                          </Typography>

                          <Button
                            color="primary"
                            size="small"
                            style={{
                              textTransform: "none",
                              backgroundColor: "#ef7f1a",
                              color: "white",
                            }}
                            onClick={() => {
                              setParty(null);
                              setSearchQuery("");
                            }}
                          >
                            Change
                          </Button>
                        </div>
                      )}
                      <div
                        style={
                          party
                            ? { display: "none" }
                            : { display: "block", width: "200px" }
                        }
                      >
                        <Autocomplete
                          {...clientsProps}
                          // PaperComponent={CustomPaper}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              // required={!invoice && true}
                              label="Select Party"
                              margin="normal"
                              variant="outlined"
                              // error={touched && !!errors.client}
                              // helperText={touched && errors.client}
                            />
                          )}
                          value={clients?.name}
                          onChange={(event, value) => {
                            setParty(value);
                            setSearchQuery(value ? value.name : "");

                            // handleClientChange();
                          }}
                          // onChange={(event, value) => setClient(value) }
                        />
                      </div>
                    </Container>
                  </ThemeProvider>)}
             

            {loading ? (
              <CircularProgress
                style={{ marginTop: "20px", marginLeft: "10px" }}
              />
            ) : (
              user?.result?.userType === "admin" && (
                <Button
                  variant="outlined"
                  endIcon={<GetAppIcon />}
                  onClick={createAndDownloadPdf}
                  style={{
                    margin: "16px 0 8px 0",
                  }}
                >
                  PDF
                </Button>
              )
            )}
          </div>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleAddMeeting}
            style={{
              margin: "16px 0 8px 16px",
              height: "3.5rem",
              justifyContent: isMobile ? "center" : "flex-end",
              fontWeight: "bold",
            }}
          >
            Add Daily Report
          </Button>
        </div>
        <TableContainer component={Paper} elevation={0}>
          <Table className={classes.table} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell style={headerStyle}>Date</TableCell>
                {!salesperson && user?.result?.userType === "admin" && (
                  <TableCell style={headerStyle}>Created By</TableCell>
                )}
                <TableCell style={headerStyle}>Party Name</TableCell>

                {/* <TableCell style={headerStyle}>Location</TableCell> */}

                {/* <TableCell style={headerStyle}>Time</TableCell> */}
                <TableCell style={headerStyle}>Order Received</TableCell>
                <TableCell style={headerStyle}>Order(MRP value)</TableCell>
                <TableCell style={headerStyle}>Payment Recieved</TableCell>
                <TableCell style={headerStyle}>Payment (Cash)</TableCell>
                <TableCell style={headerStyle}>Payment (Cheque)</TableCell>
                <TableCell style={{ width: "15%", textAlign: "center" }}>
                  Remarks
                </TableCell>
                <TableCell style={headerStyle}>Edit</TableCell>
                {user?.result?.userType === "admin" && (
                  <TableCell style={headerStyle}>Delete</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0
                ? filteredRows.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : filteredRows
              ).map((row, index) => (
                <TableRow key={row._id}>
                  <TableCell style={tableStyle}>
                    {moment(row.date).format("MMM Do YYYY")}
                  </TableCell>
                  {!salesperson && user?.result?.userType === "admin" && (
                    <TableCell style={tableStyle}>
                      {findUserNameById(row.creator)}
                    </TableCell>
                  )}
                  <TableCell style={tableStyle}>{row.client.name}</TableCell>

                  {/* <TableCell style={tableStyle}>
                    {row.location ? row.location : "N/A"}
                  </TableCell> */}

                  {/* <TableCell style={tableStyle}>
                    {moment(row.time).format(" h:mm:ss a")}
                  </TableCell> */}
                  <TableCell style={tableStyle}>
                    {row.orderRecieved ? row.orderRecieved : "No"}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {row.orderRupees && row.orderRupees
                      ? `₹${row.orderRupees
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : "N/A"}
                  </TableCell>
                  <TableCell style={tableStyle}>{row.meetingType}</TableCell>
                  <TableCell style={tableStyle}>
                    {" "}
                    {row.advance && row.advance.cash
                      ? `₹${row.advance.cash
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : "N/A"}
                  </TableCell>
                  <TableCell style={tableStyle}>
                    {row.advance && row.advance.cheque
                      ? `₹${row.advance.cheque
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
                      : "N/A"}
                  </TableCell>
                  <TableCell style={tableStyle}>{row.remarks}</TableCell>

                  <TableCell style={{ ...tableStyle, width: "10px" }}>
                    <IconButton onClick={() => handleInputChange(row._id)}>
                      <BorderColorIcon
                        style={{ width: "20px", height: "20px" }}
                      />
                    </IconButton>
                  </TableCell>
                  {user?.result?.userType === "admin" && (
                    <TableCell style={{ ...tableStyle, width: "10px" }}>
                      <IconButton onClick={() => handleDelete(row._id)}>
                        <DeleteOutlineRoundedIcon
                          style={{ width: "20px", height: "20px" }}
                        />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={6}
                  count={filteredRows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </Container>
    </div>
  );
};

export default Meetings;
