import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  InputBase,
  Badge,
} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import SearchIcon from "@material-ui/icons/Search";
import { useCart } from "../Context/CartContext";
import { usePayNow } from "../Context/PayNowContext";
import AddShoppingCartIcon from "@material-ui/icons/AddShoppingCart";
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
const drawerWidth = 350;
const useStyles = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },

  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },

  search: {
    display: "flex",
    backgroundColor: "white", // Background color for the search bar
    width: "100%",
    maxWidth: "400px", // Set the maximum width of the search bar
    transition: "background-color 0.3s ease", // Add transition for smoother hover effect
    "&:hover": {
      backgroundColor: "#e0e0e0", // Background color on hover
    },
    cursor: "pointer",
    border: "2px solid black",
    borderRadius: "20px", // Add a pointer cursor to indicate clickability
  },

  searchIcon: {
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer", // Add a pointer cursor to indicate clickability
  },

  inputRoot: {
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  inputInput: {
    width: "100%",
  },
}));

const AppBarComp = ({ open, setOpen,handlePayNow,invoice }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { cartItems, handleAddToCart,searchQuery,setSearchQuery } = useCart();
  const { openPayNow} =usePayNow();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const cartItemCount = () => cartItems.length;
  const profileDetail = JSON.parse(localStorage.getItem("profileDetail"));
  const profile =JSON.parse(localStorage.getItem("profile"));
  return (
    <AppBar
      position="fixed"
      className={`${classes.appBar} ${open ? classes.appBarShift : ""}`}
    >
      <Toolbar>
        {/* {invoice ? <IconButton aria-label="home"  onClick={() => (window.location.href = "/PosMainScreen")}>
          <HomeIcon />
        </IconButton>: openPayNow ?<IconButton aria-label="back" onClick={()=>handlePayNow()} >
      <ArrowBackIcon style={{fontSize:"2rem"}}/>
      </IconButton>:<IconButton aria-label="home"  onClick={() => (window.location.href = "/PosMainScreen")}>
          <HomeIcon /></IconButton> } */}
       {openPayNow?<IconButton aria-label="back" onClick={()=>handlePayNow()} >
      <ArrowBackIcon style={{fontSize:"2rem"}}/>
      </IconButton>:<IconButton aria-label="home"  onClick={() => (window.location.href = "/PosMainScreen")}>
          <HomeIcon />
        </IconButton>}
        <Typography variant="h4" noWrap className={classes.title}>
          {profileDetail?.businessName?profileDetail?.businessName:profile.userProfile.businessName}
        </Typography>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…" // Placeholder text for the search input
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={handleDrawerOpen}
          className={open ? classes.hide : ""}
        >
          <Badge badgeContent={cartItemCount()} color="secondary">
            <AddShoppingCartIcon />
          </Badge>
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComp;
