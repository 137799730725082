import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActionArea from "@material-ui/core/CardActionArea";
import { Payment, CreditCard, Computer, AttachMoney } from "@material-ui/icons"; // Import icons
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  card: {
    width: "30%", // Adjust width as needed
    textAlign: "center",
    cursor: "pointer",
    marginBottom: "20px",
  },
  selectedCard: {
    boxShadow: "rgb(239, 127, 26) 0px 0px 0px 3px",
    // boxShadow: "rgb(239, 127, 26) 0px 5px 15px",// Style for selected card
    elevation: 3,
  },
  icon: {
    fontSize: 50,
    margin: "20px auto",
  },
});

const PaymentOptions = ({ invoiceData, handleInputChange }) => {
  const classes = useStyles();

  const handleCardClick = (value) => {
    handleInputChange({ target: { name: "paymentMode", value } });
  };

  return (
    <div className={classes.root}>
      <Card
        className={`${classes.card} ${
          invoiceData.paymentMode === "Cash" ? classes.selectedCard : ""
        }`}
        onClick={() => handleCardClick("Cash")}
        elevation={3}
      >
        <CardActionArea>
          <CardContent>
            <AttachMoney className={classes.icon} />
         Cash
          </CardContent>
        </CardActionArea>
      </Card>

      <Card
        className={`${classes.card} ${
          invoiceData.paymentMode === "Card" ? classes.selectedCard : ""
        }`}
        onClick={() => handleCardClick("Card")}
        elevation={3}
      >
        <CardActionArea>
          <CardContent>
            <CreditCard className={classes.icon} />
            Card
          </CardContent>
        </CardActionArea>
      </Card>

      <Card
        className={`${classes.card} ${
          invoiceData.paymentMode === "Online" ? classes.selectedCard : ""
        }`}
        onClick={() => handleCardClick("Online")}
        elevation={3}
      >
        <CardActionArea>
          <CardContent>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="48"
              height="32"
              fill="currentColor"
              style={{ fontSize: 50, margin: "28px auto" }}
              viewBox="0 0 16 16"
            >
              <path d="M2 2h2v2H2z" />
              <path d="M6 0v6H0V0zM5 1H1v4h4zM4 12H2v2h2z" />
              <path d="M6 10v6H0v-6zm-5 1v4h4v-4zm11-9h2v2h-2z" />
              <path d="M10 0v6h6V0zm5 1v4h-4V1zM8 1V0h1v2H8v2H7V1zm0 5V4h1v2zM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8zm0 0v1H2V8H1v1H0V7h3v1zm10 1h-1V7h1zm-1 0h-1v2h2v-1h-1zm-4 0h2v1h-1v1h-1zm2 3v-1h-1v1h-1v1H9v1h3v-2zm0 0h3v1h-2v1h-1zm-4-1v1h1v-2H7v1z" />
              <path d="M7 12h1v3h4v1H7zm9 2v2h-3v-1h2v-1z" />
            </svg>
            {/* <Computer className={classes.icon} /> */}
            Online
          </CardContent>
        </CardActionArea>
      </Card>
    </div>
  );
};

export default PaymentOptions;
