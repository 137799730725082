import React, { createContext, useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";

// Create the StaffCommissionDetailContext
const StaffCommissionDetailContext = createContext();

// Create a StaffCommissionDetailProvider component
const StaffCommissionDetailProvider = ({ children }) => {
  // Define the state for the staffCommissionDetail array
  const [staffCommissionDetailsArray, setStaffCommissionDetailsArray] =
    useState([]);

  const [staffCommissionSummary, setStaffCommissionSummary] =useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate,setEndDate] = useState(null);
  const [generate, setGenerate] = useState(false);
  const handleStartDateChange = (date) => {
    // const formattedDate = date.toISOString().split("T")[0];
    setGenerate(false);
    setStartDate(date);
   
  };

  const handleEndDateChange = (date) => {
    // const formattedDate = date.toISOString().split("T")[0];
    setGenerate(false);
    setEndDate(date);
  };
  const handleGenerate = (staff, commissionDetailsArray) => {
   const   staffCommissionSummary= calTotalCommissionForStaff(staff, commissionDetailsArray);
   setStaffCommissionSummary(staffCommissionSummary)
    if (startDate && endDate) {
      setGenerate(true);
    }
  };


  const calTotalCommissionForStaff = (staff, commissionDetailsArray) => {
    if (!Array.isArray(staff)) {
      // Return an empty array or handle the error as needed
      return [];
  }
      const result = staff?.map(staffMember => {
          // Initialize totalCommission for the current staff member
          let totalCommission = 0;
  
          // Iterate over each commission details object in commissionDetailsArray
          commissionDetailsArray.forEach(commissionDetailsObj => {
              // Check if the commission details object falls within the date range
              const commissionDate = moment(commissionDetailsObj.createdAt);
              if (commissionDate.isBetween(startDate, endDate, "days", '[]')) {
                  // Access the staffDetails array from the current commission details object
                  const staffDetailsArray = commissionDetailsObj.staffDetails || [];
  
                  // Filter staffDetails for the current staff member
                  const staffCommissionDetails = staffDetailsArray.filter(details => details.staffId === staffMember._id);
  
                  // Sum up the staffTotal from filtered staffDetails
                  const staffTotalSum = staffCommissionDetails.reduce((total, details) => total + details.staffTotal, 0);
  
                  // Add the staffTotalSum to totalCommission
                  totalCommission += staffTotalSum;
              }
          });
  
          // Return the staff object with the totalCommission added
          return { ...staffMember, totalCommission };
      });
  
      return result;
  }
  




  // You can define additional functions to update the staffCommissionDetail array if needed
  // Provide the state and any additional functions through the context
  const contextValue = {
    staffCommissionDetailsArray,
    setStaffCommissionDetailsArray,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    handleStartDateChange,
    handleEndDateChange, 
    calTotalCommissionForStaff,
    generate,
    handleGenerate,
    staffCommissionSummary
    // Add any additional functions here
  };

  return (
    <StaffCommissionDetailContext.Provider value={contextValue}>
      {children}
    </StaffCommissionDetailContext.Provider>
  );
};

export { StaffCommissionDetailContext, StaffCommissionDetailProvider };
