/* eslint-disable */
import React, { useState, useEffect } from "react";
import Staff from "./Staff";
import EditStaff from "./EditStaff";
import AddStaff from "./AddStaff";
import { getAllStaff, getStaffByUser } from "../../actions/staffActions";
import { getStaffCommissionDetails } from "../../actions/staffCommissionDetailActions";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import NoData from "../svgIcons/NoData";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Spinner from "../Spinner/Spinner";
import StaffCommission from "./StaffCommission";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const StaffList = () => {
  const history = useHistory();
  const location = useLocation();
  const flag = location.pathname.includes("commissionSummary");
  const [open, setOpen] = useState(false);
  const [openService, setOpenService] = useState(false);
  const [currentId, setCurrentId] = useState(null);
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("profile"));
  const staff = useSelector((state) => state.staff.staff);
  const staffCommissionDetails = useSelector(
    (state) => state.staffCommissionDetails.staffCommissionDetails
  );
  console.log("Staff", staff);

  const isLoading = useSelector((state) => state.staff.isLoading);
  // const staff = []

  // useEffect(() => {
  // }, [currentId, dispatch]);

  //     useEffect(() => {
  //         dispatch(getStaff(1));
  //         // dispatch(getStaffByUser({userId : user?.result?._id}));
  //         // dispatch(getStaffByUser({ search :user?.result?._id, tags: tags.join(',') }));
  //     },[location]
  // )

  useEffect(() => {
    // dispatch(getAllStaff());\
    dispatch(getAllStaff());
    dispatch(getStaffCommissionDetails());
    // dispatch(getStaffByUser({ search: user?.result?._id || user.result.googleId }));
  }, [location, dispatch]);

  if (!user) {
    history.push("/login");
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
        }}
      >
        <Spinner />
      </div>
    );
  }

  if (staff.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          paddingTop: "20px",
          margin: "80px",
        }}
      >
        <AddStaff open={open} setOpen={setOpen} />
        <IconButton
          color="primary"
          aria-label="add to shopping cart"
          onClick={() => {
            setOpen(true);
          }}
        >
          <AddIcon />
        </IconButton>
        <p style={{ padding: "40px", color: "gray", textAlign: "center" }}>
          No Staff yet. Click the plus icon to add Staff
        </p>
      </div>
    );
  }

  return (
    <div>
      {flag ? (
        <StaffCommission staff={staff} staffCommissionDetails={staffCommissionDetails} />
      ) : (
        <Staff staff={staff} />
      )}
    </div>
  );
};

export default StaffList;
