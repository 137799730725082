import React, { useEffect ,useState} from "react";
import styles from "./Home.module.css";

import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  const [isBillPreviewOpen, setIsBillPreviewOpen] = useState(false);

  const handleBillPreviewClick = () => {
    setIsBillPreviewOpen(true);
  };

  const handleCloseBillPreview = () => {
    setIsBillPreviewOpen(false);
  };
  return (
    // <div className={styles.pageContainer}>
    <>
      <section className={styles.hero}>
        <h1>Massage Parlour</h1>
        <div className="wrap">
          <button
            onClick={() => history.push("/login")}
            className={styles.login}
          >
            {" "}
            Get started
          </button>
          
        </div>
      </section>
      
    </>
    // </div>
  );
};

export default Home;
