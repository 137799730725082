/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'; // Styles for the phone input

import { useDispatch, useSelector } from 'react-redux';
import { createClient, updateClient } from '../../actions/clientActions';
import { useSnackbar } from 'react-simple-snackbar';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#1976D2',
    marginLeft: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: 'white',
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddClient = ({ setOpen, open, currentId, setCurrentId }) => {
  const location = useLocation();
  const [clientData, setClientData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    gstNo: '',
    contactPerson: '',
    userId: '',
    dueDate: ''
  });
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const dispatch = useDispatch();
  const client = useSelector((state) => (currentId ? state.clients.clients.find((c) => c._id === currentId) : null));
  // eslint-disable-next-line
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const [errors, setErrors] = useState({}); // State variable for error messages

  const handleChange = (e) => {
    const { name, value } = e.target;

    // const maskedValue = value.startsWith('') ? value : '' + value.slice(3);
    // if(name==='phone') {
    // setClientData((prevData) => ({
    //   ...prevData,
    //   [name]: maskedValue,
    // }));}
    // else{
      setClientData((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    // }
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '', // Clear the error for the specific field
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    if (clientData.name.trim() === '') {
      newErrors.name = 'Name is required';
      isValid = false;
    }
    if (clientData.phone.trim() === '') {
      newErrors.phone = 'Phone is required';
      isValid = false;
    }else if(clientData.phone.length<10||clientData.phone.length>13){
      newErrors.phone = 'Please enter a valid phone number.';
      isValid = false;}
    // if (clientData.email.trim() === '') {
    //   newErrors.email = 'Email is required';
    //   isValid = false;
    // }
  
    // if (clientData.address.trim() === '') {
    //   newErrors.address = 'Address is required';
    //   isValid = false;
    // }
    // if (clientData.gstNo.trim() === '') {
    //   newErrors.gstNo = 'GST No is required';
    //   isValid = false;
    // }
    // if (clientData.contactPerson.trim() === '') {
    //   newErrors.contactPerson = 'Contact Person is required';
    //   isValid = false;
    // }
    // if (clientData.dueDate === '') {
    //   newErrors.dueDate = 'Payment Term Days are required';
    //   isValid = false;
    // }
    setErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    if (client) {
      setClientData(client);
    }
  }, [client]);

  useEffect(() => {
    setUser(JSON.parse(localStorage.getItem('profile')));
    // setClientData({...clientData, userId: user?.result?._id})
  }, [location]);

  useEffect(() => {
    var checkId = user?.result?._id;
    if (checkId !== undefined) {
      setClientData({ ...clientData, userId: [checkId] });
    } else {
      setClientData({ ...clientData, userId: [user?.result?.googleId] });
    }
  }, [location]);

  const handleSubmitClient = (e) => {
    e.preventDefault();
    console.log("Validate Form",validateForm())
    if (validateForm()) { // Check if the form is valid before submitting
      if (currentId) {
        dispatch(updateClient(currentId, clientData, openSnackbar));
      } else {
        dispatch(createClient(clientData, openSnackbar));
      }
      clear();
      handleClose();
    }
  };

  const clear = () => {
    setCurrentId(null);
    setClientData({
      name: '',
      email: '',
      phone: '',
      address: '',
      gstNo: '',
      contactPerson: '',
      userId: [],
      dueDate: ''
    });
    setErrors({}); // Clear the error messages
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <form>
        <Dialog
          onClose={(e, reason) => {
            if (reason !== 'backdropClick') {
              handleClose();
            }
          }}
          aria-labelledby="customized-dialog-title"
          open={open}
          fullWidth
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose} style={{ paddingLeft: '20px',backgroundColor: "#ef7f1a",color: "white" }}>
            {currentId ? 'Edit Party' : 'Add new Party'}
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Name"
                  fullWidth
                  name="name"
                  value={clientData.name}
                  onChange={handleChange}
                  error={!!errors.name} // Check if error exists
                  helperText={errors.name} // Display error message
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Contact Person"
                  fullWidth
                  name="contactPerson"
                  value={clientData.contactPerson}
                  onChange={handleChange}
                  error={!!errors.contactPerson} // Check if error exists
                  helperText={errors.contactPerson} // Display error message
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Email"
                  fullWidth
                  name="email"
                  value={clientData.email}
                  onChange={handleChange}
                  error={!!errors.email} // Check if error exists
                  helperText={errors.email} // Display error message
                />
              </Grid> */}
              <Grid item xs={12}>
                {/* <TextField
                  variant="outlined"
                  label="Phone"
                  fullWidth
                  name="phone"
                  value={clientData.phone}
                  onChange={handleChange}
                  error={!!errors.phone} // Check if error exists
                  helperText={errors.phone} // Display error message
                /> */}
                <PhoneInput
  placeholder="Enter phone number"
  value={clientData.phone}
  onChange={(value) => handleChange({ target: { name: 'phone', value } })}
  error={!!errors.phone}
  country="TH" // Default country (India in this case)
  inputProps={{
    name: 'phone',
    required: true,
  }}
/>

              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Address"
                  fullWidth
                  name="address"
                  value={clientData.address}
                  onChange={handleChange}
                  error={!!errors.address} // Check if error exists
                  helperText={errors.address} // Display error message
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="GST No"
                  fullWidth
                  name="gstNo"
                  value={clientData.gstNo}
                  onChange={handleChange}
                  error={!!errors.gstNo} // Check if error exists
                  helperText={errors.gstNo} // Display error message
                />
              </Grid>
             
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Payment Term Days"
                  fullWidth
                  type="text"
                  name="dueDate"
                  value={clientData.dueDate} //
                  onChange={handleChange}
                  error={!!errors.dueDate} // Check if error exists
                  helperText={errors.dueDate} // Display error message
                 
                />
              </Grid> */}
          
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleSubmitClient} variant="contained" style={{ marginRight: '25px',backgroundColor: "#ef7f1a",color: "white"
           }}>
              Update Customer
            </Button>
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
};

export default AddClient;
