import * as api from "../api/index";

import {
  ADD_NEW,
  UPDATE,
  DELETE,
  GET_PURCHASE,
  FETCH_PURCHASE_BY_USER,
  FETCH_ALL,
  START_LOADING,
  END_LOADING,
} from "./constants";

export const getPurchases = () => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const { data } = await api.fetchPurchases();
    console.log(data);
    dispatch({ type: FETCH_ALL, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const getPurchasesByUser = (searchQuery) => async (dispatch) => {
  try {
    dispatch({ type: START_LOADING });
    const {
      data: { data },
    } = await api.fetchPurchasesByUser(searchQuery);
    dispatch({ type: FETCH_PURCHASE_BY_USER, payload: data });
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error.response);
  }
};

export const getPurchase = (id) => async (dispatch) => {
  const user = JSON.parse(localStorage.getItem("profile"));

  try {
    const { data } = await api.fetchPurchase(id);
    // console.log("getPurchasedata",data)
    const businessDetails = await api.fetchProfilesByUser(user.result._id);
    // console.log("Business Details: " ,businessDetails)
    const PurchaseData = { ...data, businessDetails };

    // console.log("purchase Data",PurchaseData)
    dispatch({ type: GET_PURCHASE, payload: PurchaseData });
  } catch (error) {
    console.log(error.response);
  }
};

export const createPurchase = (purchase, history) => async (dispatch) => {
  try {
    // console.log("Create Purchase Called");
    dispatch({ type: START_LOADING });
    const { data } = await api.addPurchase(purchase);
    // console.log("Created Purchase Data", data);
    dispatch({ type: ADD_NEW, payload: data });
    history.push(`/purchase/${data._id}`);
    dispatch({ type: END_LOADING });
  } catch (error) {
    console.log(error);
  }
};

export const updatePurchase = (id, purchase) => async (dispatch) => {
  try {
    const { data } = await api.updatePurchase(id, purchase);
    dispatch({ type: UPDATE, payload: data });
  } catch (error) {
    console.log(error);
  }
};

export const deletePurchase = (id, openSnackbar) => async (dispatch) => {
  try {
    await api.deletePurchase(id);

    dispatch({ type: DELETE, payload: id });
    openSnackbar("Order deleted successfully");
  } catch (error) {
    console.log(error.response);
  }
};
